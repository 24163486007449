import * as types from "./types";
import { core } from "./core";
import moment from "moment";
import { isWeekStartWithMonday } from "@/utils/dateTimeFunctions";

export const getHours = (params) => {
  let data = {
    week:
      params && params.week ? params.week : moment().clone().day(1).isoWeek(),
    month:
      params && params.month
        ? params.month
        : moment().clone().day(1).month() + 1,
    year:
      params && params.year
        ? params.year
        : moment().clone().day(1).isoWeekYear(),
    user_id: params && params.user_id ? params.user_id : null,
  };

  return core.API(
    "GET",
    `${
      params && params.limit && params.offset !== undefined
        ? `/hours?limit=${params.limit}&offset=${params.offset}&week=${
            data.week
          }&month=${data.month}&year=${data.year}${
            !isWeekStartWithMonday()
              ? "&week_start=Sunday"
              : "&week_start=Monday"
          }${data.user_id ? `&user_id=${data.user_id}` : ""}`
        : `/hours?week=${data.week}&month=${data.month}&year=${data.year}${
            !isWeekStartWithMonday()
              ? "&week_start=Sunday"
              : "&week_start=Monday"
          }`
    }`,
    types.GET_HOURS,
    types.GET_HOURS_SUCCESS,
    types.GET_HOURS_FAILURE
  );
};

export const getHourSummary = (params) => {
  let data = {
    week:
      params && params.week ? params.week : moment().clone().day(1).isoWeek(),
    month:
      params && params.month
        ? params.month
        : moment().clone().day(1).month() + 1,
    year:
      params && params.year
        ? params.year
        : moment().clone().day(1).isoWeekYear(),
  };
  return core.API(
    "GET",
    `/user/${params.id}/hours-summary?week=${data.week}&month=${
      data.month
    }&year=${data.year}${
      !isWeekStartWithMonday() ? "&week_start=Sunday" : "&week_start=Monday"
    }`,
    types.GET_HOURS_SUMMARY,
    types.GET_HOURS_SUMMARY_SUCCESS,
    types.GET_HOURS_SUMMARY_FAILURE
  );
};

export const getAddonSummary = (params) => {
  let data = {
    week:
      params && params.week ? params.week : moment().clone().day(1).isoWeek(),
    month:
      params && params.month
        ? params.month
        : moment().clone().day(1).month() + 1,
    year:
      params && params.year
        ? params.year
        : moment().clone().day(1).isoWeekYear(),
  };
  return core.API(
    "GET",
    `/user/${params.id}/addons-summary?week=${data.week}&month=${
      data.month
    }&year=${data.year}${
      !isWeekStartWithMonday() ? "&week_start=Sunday" : "&week_start=Monday"
    }`,
    types.GET_ADDONS_SUMMARY,
    types.GET_ADDONS_SUMMARY_SUCCESS,
    types.GET_ADDONS_SUMMARY_FAILURE
  );
};

export const getApprovalDetails = (id, queryString) => {
  return core.API(
    "GET",
    `/user/${id}/approvals/${queryString ? `?${queryString}` : ""}`,
    types.GET_APPROVAL_DETAILS,
    types.GET_APPROVAL_DETAILS_SUCCESS,
    types.GET_APPROVAL_DETAILS_FAILURE
  );
};

export const getApprovalDetailsSimplified = (id, queryString) => {
  return core.API(
    "GET",
    `/user/${id}/v2/approvals/${queryString ? `?${queryString}` : ""}`,
    types.GET_APPROVAL_DETAILS_SIMPLIFIED,
    types.GET_APPROVAL_DETAILS_SIMPLIFIED_SUCCESS,
    types.GET_APPROVAL_DETAILS_SIMPLIFIED_FAILURE
  );
};

export const getApprovalSummary = (id, queryString) => {
  return core.API(
    "GET",
    `/user/${id}/approval-summary/${queryString ? `?${queryString}` : ""}`,
    types.GET_APPROVAL_SUMMARY,
    types.GET_APPROVAL_SUMMARY_SUCCESS,
    types.GET_APPROVAL_SUMMARY_FAILURE
  );
};

export const getChangeDetails = (id, queryString) => {
  return core.API(
    "GET",
    `/user/${id}/approvals/${queryString ? `?${queryString}` : ""}`,
    types.GET_CHANGE_DETAILS,
    types.GET_CHANGE_DETAILS_SUCCESS,
    types.GET_CHANGE_DETAILS_FAILURE
  );
};

export const getChangeDetailsSimplified = (id, queryString) => {
  return core.API(
    "GET",
    `/user/${id}/v2/approvals/${queryString ? `?${queryString}` : ""}`,
    types.GET_CHANGE_DETAILS_SIMPLIFIED,
    types.GET_CHANGE_DETAILS_SIMPLIFIED_SUCCESS,
    types.GET_CHANGE_DETAILS_SIMPLIFIED_FAILURE
  );
};

export const getChangeSummary = (id, queryString) => {
  return core.API(
    "GET",
    `/user/${id}/approval-summary/${queryString ? `?${queryString}` : ""}`,
    types.GET_CHANGE_SUMMARY,
    types.GET_CHANGE_SUMMARY_SUCCESS,
    types.GET_CHANGE_SUMMARY_FAILURE
  );
};

export const getApprovalUsers = (queryString) => {
  return core.API(
    "GET",
    `/approval-users/${queryString ? `?${queryString}` : ""}`,
    types.GET_APPROVAL_USERS,
    types.GET_APPROVAL_USERS_SUCCESS,
    types.GET_APPROVAL_USERS_FAILURE
  );
};

export const getChangeApprovalUsers = (queryString) => {
  return core.API(
    "GET",
    `/approval-users/${queryString ? `?${queryString}` : ""}`,
    types.GET_CHANGE_APPROVAL_USERS,
    types.GET_CHANGE_APPROVAL_USERS_SUCCESS,
    types.GET_CHANGE_APPROVAL_USERS_FAILURE
  );
};

export const approveHours = (data) => {
  return core.API(
    "POST",
    `/hour/approve`,
    types.APPROVE_HOURS,
    types.APPROVE_HOURS_SUCCESS,
    types.APPROVE_HOURS_FAILURE,
    data
  );
};

export const rejectHours = (data) => {
  return core.API(
    "POST",
    `/hour/approval-reject`,
    types.REJECT_HOURS,
    types.REJECT_HOURS_SUCCESS,
    types.REJECT_HOURS_FAILURE,
    data
  );
};

export const rejectChangeHours = (data) => {
  return core.API(
    "POST",
    `/hour/reject`,
    types.REJECT_HOURS,
    types.REJECT_HOURS_SUCCESS,
    types.REJECT_HOURS_FAILURE,
    data
  );
};

export const approveAll = (userId, data) => {
  return core.API(
    "PUT",
    `/user/${userId}/approve`,
    types.APPROVE_ALL,
    types.APPROVE_ALL_SUCCESS,
    types.APPROVE_ALL_FAILURE,
    data
  );
};

export const rejectAll = (userId, data) => {
  return core.API(
    "PUT",
    `/user/${userId}/reject`,
    types.REJECT_ALL,
    types.REJECT_ALL_SUCCESS,
    types.REJECT_ALL_FAILURE,
    data
  );
};

export const approveChangeAll = (userId, data) => {
  return core.API(
    "PUT",
    `/user/${userId}/change`,
    types.APPROVE_CHANGE_ALL,
    types.APPROVE_CHANGE_ALL_SUCCESS,
    types.APPROVE_CHANGE_ALL_FAILURE,
    data
  );
};

export const changeHours = (data) => {
  return core.API(
    "POST",
    `/hour/change`,
    types.CHANGE_HOURS,
    types.CHANGE_HOURS_SUCCESS,
    types.CHANGE_HOURS_FAILURE,
    data
  );
};

export const approveAddons = (data) => {
  return core.API(
    "POST",
    `/addon/addon/approve`,
    types.APPROVE_ADDONS,
    types.APPROVE_ADDONS_SUCCESS,
    types.APPROVE_ADDONS_FAILURE,
    data
  );
};

export const rejectAddons = (data) => {
  return core.API(
    "POST",
    `/addon/addon/approval-reject`,
    types.REJECT_ADDONS,
    types.REJECT_ADDONS_SUCCESS,
    types.REJECT_ADDONS_FAILURE,
    data
  );
};

export const rejectChangeAddons = (data) => {
  return core.API(
    "POST",
    `/addon/addon/reject`,
    types.REJECT_ADDONS,
    types.REJECT_ADDONS_SUCCESS,
    types.REJECT_ADDONS_FAILURE,
    data
  );
};

export const changeAddons = (data) => {
  return core.API(
    "POST",
    `/addon/addon/change`,
    types.CHANGE_ADDONS,
    types.CHANGE_ADDONS_SUCCESS,
    types.CHANGE_ADDONS_FAILURE,
    data
  );
};

export const getAddons = (params) => {
  let data = {
    week:
      params && params.week ? params.week : moment().clone().day(1).isoWeek(),
    month:
      params && params.month
        ? params.month
        : moment().clone().day(1).month() + 1,
    year:
      params && params.year
        ? params.year
        : moment().clone().day(1).isoWeekYear(),
    user_id: params && params.user_id ? params.user_id : null,
  };

  return core.API(
    "GET",
    `${
      params && params.limit && params.offset !== undefined
        ? `/addon/addons?limit=${params.limit}&offset=${params.offset}&week=${
            data.week
          }&month=${data.month}&year=${data.year}${
            !isWeekStartWithMonday()
              ? "&week_start=Sunday"
              : "&week_start=Monday"
          }${data.user_id ? `&user_id=${data.user_id}` : ""}`
        : `/addon/addons?week=${data.week}&month=${data.month}&year=${
            data.year
          }${
            !isWeekStartWithMonday()
              ? "&week_start=Sunday"
              : "&week_start=Monday"
          }`
    }`,
    types.GET_ADDONS,
    types.GET_ADDONS_SUCCESS,
    types.GET_ADDONS_FAILURE
  );
};

export const getFixedAddons = (params) => {
  let data = {
    week:
      params && params.week ? params.week : moment().clone().day(1).isoWeek(),
    month:
      params && params.month
        ? params.month
        : moment().clone().day(1).month() + 1,
    year:
      params && params.year
        ? params.year
        : moment().clone().day(1).isoWeekYear(),
    user_id: params && params.user_id ? params.user_id : null,
  };
  return core.API(
    "GET",
    `${
      params && params.limit && params.offset !== undefined
        ? `/addon/fixed-addons?limit=${params.limit}&offset=${params.offset}&week=${
            data.week
          }&month=${data.month}&year=${data.year}${
            !isWeekStartWithMonday()
              ? "&week_start=Sunday"
              : "&week_start=Monday"
          }${data.user_id ? `&user_id=${data.user_id}` : ""}`
        : `/addon/addons?week=${data.week}&month=${data.month}&year=${
            data.year
          }${
            !isWeekStartWithMonday()
              ? "&week_start=Sunday"
              : "&week_start=Monday"
          }`
    }`,
    types.GET_FIXED_ADDONS,
    types.GET_FIXED_ADDONS_SUCCESS,
    types.GET_FIXED_ADDONS_FAILURE
  );
};

export const applyBatchRules = (id) => {
  return core.API(
    "GET",
    `/batch/${id}/apply-rules`,
    types.GET_BATCH_RULES,
    types.GET_BATCH_RULES_SUCCESS,
    types.GET_BATCH_RULES_FAILURE
  );
};

export const approveBatch = (id) => {
  return core.API(
    "PUT",
    `/batch/${id}/approve`,
    types.APPROVE_BATCH,
    types.APPROVE_BATCH_SUCCESS,
    types.APPROVE_BATCH_FAILURE
  );
};

export const rejectBatch = (id) => {
  return core.API(
    "PUT",
    `/batch/${id}/approval-reject`,
    types.REJECT_BATCH,
    types.REJECT_BATCH_SUCCESS,
    types.REJECT_BATCH_FAILURE
  );
};

export const rejectChangeBatch = (id) => {
  return core.API(
    "PUT",
    `/batch/${id}/reject`,
    types.REJECT_BATCH,
    types.REJECT_BATCH_SUCCESS,
    types.REJECT_BATCH_FAILURE
  );
};

export const changeBatch = (id) => {
  return core.API(
    "PUT",
    `/batch/${id}/change`,
    types.CHANGE_BATCH,
    types.CHANGE_BATCH_SUCCESS,
    types.CHANGE_BATCH_FAILURE
  );
};

export const approveGroup = (id) => {
  return core.API(
    "PUT",
    `/batch-group/${id}/approve`,
    types.APPROVE_BATCH_GROUP,
    types.APPROVE_BATCH_GROUP_SUCCESS,
    types.APPROVE_BATCH_GROUP_FAILURE
  );
};

export const rejectGroup = (id) => {
  return core.API(
    "PUT",
    `/batch-group/${id}/approval-reject`,
    types.REJECT_BATCH_GROUP,
    types.REJECT_BATCH_GROUP_SUCCESS,
    types.REJECT_BATCH_GROUP_FAILURE
  );
};

export const rejectChangeGroup = (id) => {
  return core.API(
    "PUT",
    `/batch-group/${id}/reject`,
    types.REJECT_BATCH_GROUP,
    types.REJECT_BATCH_GROUP_SUCCESS,
    types.REJECT_BATCH_GROUP_FAILURE
  );
};

export const changeGroup = (id) => {
  return core.API(
    "PUT",
    `/batch-group/${id}/change`,
    types.CHANGE_BATCH_GROUP,
    types.CHANGE_BATCH_GROUP_SUCCESS,
    types.CHANGE_BATCH_GROUP_FAILURE
  );
};

export const createCheckInHour = (data) => {
  return core.API2(
    "POST",
    `/v2/check-in`,
    types.CREATE_CHECKIN_HOUR,
    types.CREATE_CHECKIN_HOUR_SUCCESS,
    types.CREATE_CHECKIN_HOUR_FAILURE,
    data
  );
};

export const getCheckInHour = (queryString) => {
  return core.API(
    "GET",
    `/check-in-outs${queryString ? `?${queryString}` : ``}`,
    types.GET_CHECKIN_HOUR,
    types.GET_CHECKIN_HOUR_SUCCESS,
    types.GET_CHECKIN_HOUR_FAILURE
  );
};

export const getCheckInHourDetails = (id) => {
  return core.API(
    "GET",
    `/check-in-out/${id}`,
    types.GET_CHECKIN_HOUR,
    types.GET_CHECKIN_HOUR_SUCCESS,
    types.GET_CHECKIN_HOUR_FAILURE
  );
};

export const checkOutHour = (id, data) => {
  return core.API(
    "PUT",
    `/check-in-out/${id}`,
    types.UPDATE_CHECKIN_HOUR,
    types.UPDATE_CHECKIN_HOUR_SUCCESS,
    types.UPDATE_CHECKIN_HOUR_FAILURE,
    data
  );
};

export const createCheckoutHour = (data) => {
  return core.API2(
    "PUT",
    `/v2/check-out`,
    types.CREATE_CHECKOUT_HOUR,
    types.CREATE_CHECKOUT_HOUR_SUCCESS,
    types.CREATE_CHECKOUT_HOUR_FAILURE,
    data
  );
};

export const deleteCheckInHour = (id) => {
  return core.API(
    "DELETE",
    `/check-in-out/${id}`,
    types.DELETE_CHECKIN_HOUR,
    types.DELETE_CHECKIN_HOUR_SUCCESS,
    types.DELETE_CHECKIN_HOUR_FAILURE
  );
};

export const getUserAttendance = (queryString) => {
  return core.API(
    "GET",
    `/attendance/v2/user-attendances${queryString ? `?${queryString}` : ``}`,
    types.GET_USER_ATTENDANCE,
    types.GET_USER_ATTENDANCE_SUCCESS,
    types.GET_USER_ATTENDANCE_FAILURE
  );
};

export const getGuestAttendance = (queryString) => {
  return core.API(
    "GET",
    `/attendance/v2/user-attendances${queryString ? `?${queryString}` : ``}`,
    types.GET_GUEST_ATTENDANCE,
    types.GET_GUEST_ATTENDANCE_SUCCESS,
    types.GET_GUEST_ATTENDANCE_FAILURE
  );
};

export const updateUserAttendance = (id, data) => {
  return core.API(
    "PUT",
    `/attendance/user-attendance/${id}`,
    types.UPDATE_USER_ATTENDANCE,
    types.UPDATE_USER_ATTENDANCE_SUCCESS,
    types.UPDATE_USER_ATTENDANCE_FAILURE,
    data
  );
};

export const postUserAttendance = (data) => {
  return core.API(
    "POST",
    `/attendance/user-attendance`,
    types.CREATE_USER_ATTENDANCE,
    types.CREATE_USER_ATTENDANCE_SUCCESS,
    types.CREATE_USER_ATTENDANCE_FAILURE,
    data
  );
};

export const createMultiProjectHours = (data) => {
  return core.API(
    "POST",
    `/hours/multiple-create`,
    types.CREATE_MULTI_PROJECT_HOURS,
    types.CREATE_MULTI_PROJECT_HOURS_SUCCESS,
    types.CREATE_MULTI_PROJECT_HOURS_FAILURE,
    data
  );
};

// update multiProjectHours
export const updateBatchGroup = (id, data) => {
  return core.API(
    "PUT",
    `/batch-group/${id}`,
    types.UPDATE_BATCH_GROUP,
    types.UPDATE_BATCH_GROUP_SUCCESS,
    types.UPDATE_BATCH_GROUP_FAILURE,
    data
  );
};

export const deleteBatchGroup = (id) => {
  return core.API(
    "DELETE",
    `/batch-group/${id}`,
    types.DELETE_BATCH_GROUP,
    types.DELETE_BATCH_GROUP_SUCCESS,
    types.DELETE_BATCH_GROUP_FAILURE
  );
};

export const getLastUserAttendance = () => {
  return core.API(
    "GET",
    `/attendance/user-last-attendance`,
    types.GET_USER_ATTENDANCE_LAST,
    types.GET_USER_ATTENDANCE_LAST_SUCCESS,
    types.GET_USER_ATTENDANCE_LAST_FAILURE
  );
};

export const previewMultiProjectHours = (data) => {
  return core.API(
    "POST",
    `/hours/preview`,
    types.PREVIEW_MULTI_PROJECT_HOURS,
    types.PREVIEW_MULTI_PROJECT_HOURS_SUCCESS,
    types.PREVIEW_MULTI_PROJECT_HOURS_FAILURE,
    data
  );
};

import React, { useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  postUserAttendance,
  getLastUserAttendance,
  updateUserAttendance,
} from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";

import moment from "moment";
import i18next from "i18next";

import { getItem } from "@/utils/localStorage";

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    width: "100%",
    marginTop: 30,
    padding: 20,
    textAlign: "center",
  },
  errorText: {
    color: theme.palette.error.main,
    padding: "0px 5px 10px 15px",
  },
  button: {
    margin: "10px 10px 0px 10px",
  },
}));

const CheckInForAttendace = (props) => {
  const {
    isCheckedIn,
    onCheckInForAttendanceDone,
    onCheckOutForAttendanceDone,
    isGeoFenceForAttendace,
    isGeoFenceDisabled,
    coords,
  } = props;

  const classes = useStyles();
  const [attendanceError, setattendanceError] = useState("");

  const logUserAttendanceIn = async () => {
    setattendanceError("");
    try {
      const attendaceObj = {
        start_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
        attendanceable_id: getItem("userId")
          ? parseInt(getItem("userId"), 10)
          : null,
        attendanceable_type: "User",
        is_utc: true,
      };
      // geo location
      if (isGeoFenceForAttendace && coords) {
        if (isGeoFenceDisabled) {
          attendaceObj["tablet"] = true;
        } else {
          attendaceObj["latitude"] = coords.latitude;
          attendaceObj["longitude"] = coords.longitude;
        }
      }
      const response = await postUserAttendance(attendaceObj);
      if (response.data) {
        //console.log("check-in", response.data.message);
        onCheckInForAttendanceDone();
      }
    } catch (error) {
      const { message } = getErrorObject(error);
      setattendanceError(message);
      //console.log(message);
    }
  };

  const logUserAttendanceOut = async () => {
    try {
      const user_id = getItem("userId")
        ? parseInt(getItem("userId"), 10)
        : null;

      const response = await getLastUserAttendance();
      if (response && response.data && response.data.entry) {
        if (response.data.entry.id) {
          const attendaceId = response.data.entry.id;
          const attendaceObj = {
            end_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
            attendanceable_id: user_id,
            attendanceable_type: "User",
          };
          const result = await updateUserAttendance(attendaceId, attendaceObj);
          if (result.data) {
            //console.log("check-out", result.data.message);
            onCheckOutForAttendanceDone();
          }
        } else {
          setattendanceError(
            i18next.t("Error: You have not checked-in for attendance yet.")
          );
        }
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      setattendanceError(message);
      //console.log(message);
    }
  };

  return (
    <Paper className={classes.container}>
      {attendanceError && (
        <Typography variant="body1" className={classes.errorText}>
          {`${i18next.t("Attendance")}: ${attendanceError}`}
        </Typography>
      )}
      <Typography style={{ paddingBottom: 10 }}>
        {isCheckedIn
          ? i18next.t(
              "If you check-out here it's just for attendance, not for hours"
            )
          : i18next.t(
              "If you check-in here it's just for attendance, not for hours"
            )}
      </Typography>
      {!isCheckedIn ? (
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={async (event) => {
            event.preventDefault();
            logUserAttendanceIn();
          }}
        >
          {i18next.t("Check-in for attendance")}
        </Button>
      ) : (
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={async (event) => {
            event.preventDefault();
            logUserAttendanceOut();
          }}
        >
          {i18next.t("Check-out for attendance")}
        </Button>
      )}
    </Paper>
  );
};

export default CheckInForAttendace;

import React, { Component, Fragment } from "react";
import { withStyles } from "@mui/styles";
import {
  Typography,
  SvgIcon,
  Paper,
  Tooltip,
  IconButton,
  Stack,
  Alert,
} from "@mui/material";
import { People } from "@mui/icons-material";
import moment from "moment";
import i18next from "i18next";
import FolderIcon from "@mui/icons-material/Folder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";

const styles = (theme) => ({
  root: {
    overflowX: "auto",
    cursor: "pointer",
    margin: "0 8px 16px 8px",
    borderRadius: 8,
    boxShadow: theme.customShadows.hourCard,
    background: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  contentRoot: {
    padding: theme.spacing(1),
  },
  nameBlock: {
    display: "flex",
    alignItems: "center",
    marginBottom: 4,
  },
  folderIcon: {
    fontSize: 24,
    marginRight: 8,
  },
  projectName: {
    fontSize: 12,
  },
  checkIcon: {
    fontSize: 18,
    marginRight: 12,
    color: theme.palette.text.secondary,
  },
  peopleIcon: {
    marginLeft: 8,
  },
  tooltipContainer: {
    marginLeft: 16,
    cursor: "pointer",
    display: "flex",
  },
  tooltip: {
    fontSize: 12,
  },
  coloredBorder: {
    height: 4,
  },
});

class PlanCard extends Component {
  render() {
    const {
      plan,
      selectPlan,
      classes,
      getPlanUsers,
      isItemCertificationRequiered,
      isDependencyRequiered,
      isAllowToSeeOtherUsersPlans,
    } = this.props;

    let hasDependencyPermission = true;
    if (isDependencyRequiered) {
      const depIndex = plan?.planning_planned_hours_dependencies?.findIndex(
        (dep) =>
          (dep.dependency_type === "task"
            ? dep.task_finish
            : dep.project_finish) === false
      );
      hasDependencyPermission = depIndex === -1 ? true : false;
    }

    return (
      <Fragment>
        <Paper elevation={1} className={classes.root}>
          <Typography
            component="div"
            className={classes.contentRoot}
            onClick={() => {
              selectPlan(plan.id);
            }}
          >
            <Typography component="div" className={classes.nameBlock}>
              <SvgIcon className={classes.folderIcon}>
                <FolderIcon
                  style={{
                    color: plan.project_color ? plan.project_color : "#18B24B",
                  }}
                />
              </SvgIcon>
              <Typography className={classes.projectName}>
                {plan.project_name ? plan.project_name : ""}
              </Typography>
            </Typography>
            <Typography component="div" className={classes.nameBlock}>
              <SvgIcon className={classes.checkIcon}>
                <CheckCircleIcon color="primary" />
              </SvgIcon>
              <Typography className={classes.projectName}>
                {plan.task_name ? plan.task_name : ""}
              </Typography>
            </Typography>
            <Typography component="div" className={classes.nameBlock}>
              <SvgIcon className={classes.checkIcon}>
                <path
                  d={
                    "M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M16.2,16.2L11,13V7H12.5V12.2L17,14.9L16.2,16.2Z"
                  }
                />
              </SvgIcon>
              <Typography noWrap className={classes.projectName}>
                {moment(plan.start_time).utc().format(`HH:mm`)} -{" "}
                {moment(plan.end_time).utc().format(`HH:mm`)}
              </Typography>
              {plan.comment && plan.comment.length > 0 && (
                <Typography
                  component="div"
                  className={classes.tooltipContainer}
                >
                  <Tooltip
                    title={plan.comment}
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <SvgIcon className={classes.checkIcon}>
                      <path
                        d={
                          "M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M5,5V7H19V5H5M5,9V11H13V9H5M5,13V15H15V13H5Z"
                        }
                      />
                    </SvgIcon>
                  </Tooltip>
                </Typography>
              )}
              {isAllowToSeeOtherUsersPlans ? (
                <IconButton
                  size="small"
                  className={classes.peopleIcon}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    getPlanUsers(plan.task_id, event.currentTarget);
                  }}
                >
                  <People />
                </IconButton>
              ) : null}
            </Typography>

            {(isItemCertificationRequiered || isDependencyRequiered) && (
              <Typography component="div">
                <Stack className={classes.alertBox}>
                  {(isItemCertificationRequiered ? plan?.allow_item : true) &&
                  hasDependencyPermission ? (
                    <Alert
                      icon={<CheckIcon fontSize="inherit" />}
                      severity="success"
                      sx={{ py: 0 }}
                    >
                      {i18next.t("Required item or dependency")}
                    </Alert>
                  ) : (
                    <Alert severity="warning" sx={{ py: 0 }}>
                      {i18next.t("Required item or dependency")}
                    </Alert>
                  )}
                </Stack>
              </Typography>
            )}
          </Typography>

          <Typography
            component="div"
            className={classes.coloredBorder}
            style={{
              backgroundColor: plan.project_color
                ? `${plan.project_color}`
                : null,
            }}
          ></Typography>
        </Paper>
      </Fragment>
    );
  }
}

export default withStyles(styles)(PlanCard);

import React from "react";
import i18next from "i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { getItem, removeItem } from "@/utils/localStorage";

const styles = (theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  header: {
    marginBottom: 4,
    padding: theme.spacing(2),
  },
  contentView: {
    marginBottom: 4,
    padding: theme.spacing(2),
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
});

class ContinuesCheckInModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCancel = async () => {
    const { checkBatchGroupContinuesCheckIn, submitCheckoutHourModal } =
      this.props;

    if (checkBatchGroupContinuesCheckIn) {
      // allow continueos check-in for current session
      await this.props.checkBatchGroupContinuesCheckIn();
    }

    this.props.changeState({
      openContinuesCheckInModal: false,
      continuesCheckInStartTime: null,
    });

    // for planned hours page
    if (getItem("checkInStartTime")) {
      removeItem("checkInStartTime");
    }

    submitCheckoutHourModal();
  };

  render() {
    const { isOpen, handleContinuesCheckIn, classes } = this.props;

    return (
      <Dialog
        open={isOpen}
        onClose={this.handleCancel}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle className={classes.header}>
          {i18next.t("Next project")}
        </DialogTitle>
        <DialogContent className={classes.contentView}>
          <DialogContentText>
            {i18next.t("continuous-check-in-msg")}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={this.handleCancel} className={classes.btnCancel}>
            {i18next.t("skip-as-im-finished")}
          </Button>
          <Button onClick={handleContinuesCheckIn} className={classes.button}>
            {i18next.t("Next task")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
export default withStyles(styles)(ContinuesCheckInModal);

import React, { Fragment } from "react";
import { MaterialSelect } from "@/common/selectComponents/MaterialSelect";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  FormControlLabel,
  Popover,
  Box,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PlanCard from "./PlanCard";

import { getTaskByIdApi } from "@/services/employee";

const styles = (theme) => ({
  btnCancel: {
    padding: "16px 26px",
    fontSize: 16,
    fontWeight: 500,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  mb: {
    marginBottom: "2rem",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  btnGreen: {
    backgroundColor: "#19B248",
    color: "#fff",
  },
  header: {
    padding: theme.spacing(3),
  },
  textField: {
    padding: "0px",
    height: "50px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  selectInput: {
    display: "flex",
    padding: 12,
    height: "40px",
  },
  /*selectPaper: {
    position: "absolute",
    marginTop: theme.spacing(1),
    zIndex: 1001,
    left: 0,
    right: 0,
    boxShadow: theme.customShadows.card,
  },*/
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    "& input": {
      color: `${theme.palette.text.primary} !important`,
    },
  },
  singleValue: {
    maxWidth: "calc(100% - 54px)",
    overflow: "hidden",
    whiteSpace: "nowrap",
    marginLeft: 2,
    position: "absolute",
    textOverflow: "ellipsis",
    color: theme.palette.text.primary,
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 40px 24px 40px !important",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  checkInButton: {
    fontSize: 16,
    fontWeight: 700,
    padding: "16px 60px",
  },
  checkBox: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  planHeading: {
    marginBottom: 24,
  },
  planContainer: {
    maxHeight: 410,
    overflowY: "auto",
  },
  noPlanBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.secondary,
    border: `2px dashed ${theme.palette.divider}`,
    borderRadius: 8,
    height: 300,
    margin: "0 16px 16px 0",
  },
  poperBody: {
    padding: "8px 20px 16px 20px",
    maxHeight: 220,
    minWidth: 150,
    overflowY: "auto",
  },
  poperHeader: {
    fontSize: 14,
    fontWeight: 500,
    padding: "5px 0px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  poperItem: {
    fontSize: 14,
    fontWeight: 400,
    padding: "8px 0px",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  closePopoverButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    padding: 2,
  },
  closePopoverIcon: {
    fontSize: 18,
  },
  poperMsg: {
    padding: 10,
  },
  alertBox: {
    display: "flex",
    marginTop: 16,
    marginBottom: 16,
  },
});

class FormCheckInModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectRequired: false,
      taskRequired: false,
      startDate: new Date(),
      selectedDate: new Date(),
      newTaskCount: 0,
      customerRequired: false,
      newProject: false,
      openPopover: false,
      anchorEl: null,
      isHasItemCertificationPermission: false,
      isHasDependencyPermission: false,
    };
  }

  handleCancel = () => {
    this.setState({
      projectRequired: false,
      taskRequired: false,
      newProject: false,
      customerRequired: false,
    });
    this.props.changeState({ continuesCheckInStartTime: null });
    this.props.handleCancel();
  };

  handlePopoverCancel = () => {
    this.setState({
      openPopover: false,
      anchorEl: null,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.state.openCheckInModal &&
      prevProps.state.openCheckInModal !== this.props.state.openCheckInModal
    ) {
      if (this.props.state.selectedTask?.value) {
        this.getDefaultTask(this.props.state.selectedTask?.value);
      } else {
        this.setState({
          isHasItemCertificationPermission: true,
          isHasDependencyPermission: true,
        });
      }
    }
  };

  getDefaultTask = async (taskId) => {
    try {
      const queryString = `${taskId}?hour_summary=true`;
      const response = await getTaskByIdApi(queryString);
      this.validateRecord(response.data, "task");
    } catch (error) {
      console.log(error);
    }
  };

  loadTaskOptions = async (loadedOptions, search) => {
    let limit = 20;
    let offset =
      loadedOptions && loadedOptions.length ? loadedOptions.length : 0;

    const result = await this.props.getTaskOptions(limit, offset, search);
    return result;
  };

  loadDefaultCustomersList = async (search, loadedOptions) => {
    let limit = 20;
    let offset =
      loadedOptions && loadedOptions.length ? loadedOptions.length : 0;

    const result = await this.props.getCustomersListOptions(
      limit,
      offset,
      search
    );
    return result;
  };

  selectPlan = (planId) => {
    const { plannedHours } = this.props.state;

    const plannedHour = plannedHours.find((plan) => {
      return plan.id === planId;
    });

    if (plannedHour) {
      this.props.changeState({
        selectedProject: parseInt(plannedHour.project_id, 10),
        selectedProjectOption: {
          value: plannedHour.project_id,
          label: `${plannedHour.project_name} - ${plannedHour.customer_name}`,
        },
        selectedTask: {
          value: plannedHour.task_id,
          label: plannedHour.task_name,
        },
      });
      this.validateRecord(plannedHour, "plan");
    }
  };

  getPlanUsers = (taskId, ref) => {
    this.props.getSpecificPlanUsers(taskId);
    this.setState({
      openPopover: true,
      anchorEl: ref,
    });
  };

  validateRecord = (record, recordType) => {
    const { isItemCertificationRequiered, isDependencyRequiered } =
      this.props.state;

    if (isItemCertificationRequiered) {
      this.setState({ isHasItemCertificationPermission: record?.allow_item });
    } else {
      this.setState({ isHasItemCertificationPermission: true });
    }

    if (isDependencyRequiered) {
      let dependencyIndex = null;
      if (recordType && recordType === "task") {
        dependencyIndex = record?.main_task_dependencies?.findIndex(
          (dep) =>
            (dep.dependency_type === "task"
              ? dep.task_finish
              : dep.project_finish) === false
        );
      } else if (recordType && recordType === "plan") {
        dependencyIndex =
          record?.planning_planned_hours_dependencies?.findIndex(
            (dep) =>
              (dep.dependency_type === "task"
                ? dep.task_finish
                : dep.project_finish) === false
          );
      }

      this.setState({
        isHasDependencyPermission:
          dependencyIndex && (dependencyIndex === -1 ? true : false),
      });
    } else {
      this.setState({ isHasDependencyPermission: true });
    }
  };

  validateItemAndDependency = (selected) => {
    const { taskReducer, state } = this.props;
    const {
      isItemCertificationRequiered,
      isDependencyRequiered,
      selectedProject,
    } = state;

    if (isItemCertificationRequiered || isDependencyRequiered) {
      if (selectedProject) {
        const selectedTaskData = taskReducer?.taskList[
          selectedProject
        ]?.entries?.find((el) => el.id === selected.value * 1);

        if (isItemCertificationRequiered) {
          this.setState({
            isHasItemCertificationPermission: selectedTaskData?.allow_item,
          });
        } else {
          this.setState({ isHasItemCertificationPermission: true });
        }

        if (isDependencyRequiered) {
          const dependencyData = selectedTaskData?.main_task_dependencies;
          const dependencyIndex = dependencyData?.findIndex(
            (dep) =>
              (dep.dependency_type === "task"
                ? dep.task_finish
                : dep.project_finish) === false
          );

          this.setState({
            isHasDependencyPermission: dependencyIndex === -1 ? true : false,
          });
        } else {
          this.setState({ isHasDependencyPermission: true });
        }
      } else {
        const selectedTaskData = taskReducer.allTasks?.entries?.find(
          (el) => el.id === selected.value * 1
        );

        if (isItemCertificationRequiered) {
          this.setState({
            isHasItemCertificationPermission: selectedTaskData?.allow_item,
          });
        } else {
          this.setState({ isHasItemCertificationPermission: true });
        }

        if (isDependencyRequiered) {
          const dependencyData = selectedTaskData?.main_task_dependencies;
          const dependencyIndex = dependencyData?.findIndex(
            (dep) =>
              (dep.dependency_type === "task"
                ? dep.task_finish
                : dep.project_finish) === false
          );

          this.setState({
            isHasDependencyPermission: dependencyIndex === -1 ? true : false,
          });
        } else {
          this.setState({ isHasDependencyPermission: true });
        }
      }
    }
  };

  render() {
    const {
      handleCheckInStart,
      state,
      classes,
      specificPlanUsersLoading,
      specificPlanUsersList,
    } = this.props;

    const userList = [];
    if (specificPlanUsersList.entries && specificPlanUsersList.entries.length) {
      for (let i = 0; i < specificPlanUsersList.entries.length; i++) {
        if (
          state.loginUserId !== specificPlanUsersList.entries[i].user_id &&
          !userList.includes(specificPlanUsersList.entries[i].user_name)
        ) {
          userList.push(specificPlanUsersList.entries[i].user_name);
        }
      }
    }

    return (
      <Typography component="div">
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={state.openCheckInModal}
          onClose={this.handleCancel}
        >
          <DialogTitle id="check-in-dialog-title">
            {i18next.t("Check in")}
            <IconButton
              aria-label={i18next.t("Close")}
              className={classes.closeButton}
              onClick={this.handleCancel}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!(!state.selectedProject || !state.selectedTask)) {
                  this.setState({
                    newProject: false,
                    customerRequired: false,
                  });

                  handleCheckInStart();
                }
              }}
              className={classes.root}
            >
              <Fragment>
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <Typography
                      variant="body2"
                      className={classes.planHeading}
                      id="check-in-dialog-planned-hours-block-title"
                    >
                      {i18next.t("Selected task")}
                    </Typography>

                    <Grid container spacing={2} className={classes.mb}>
                      <Grid
                        item
                        xs={12}
                        id="check-in-dialog-project-input-container"
                      >
                        <MaterialAsyncPaginate
                          classes={classes}
                          textFieldProps={{
                            label: i18next.t("Project"),
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          error={this.state.projectRequired}
                          defaultOptions={false}
                          loadOptions={async (search, loadedOptions) => {
                            return this.props.loadProjectList(
                              search,
                              loadedOptions
                            );
                          }}
                          //options={state.projectOptions}
                          value={state.selectedProjectOption}
                          onChange={async (selected) => {
                            if (selected.__isNew__) {
                              this.setState({ newProject: true });
                              await this.props.changeState({
                                selectedProjectOption: {
                                  ...selected,
                                  value: selected.value,
                                  label: selected.value,
                                  color: selected.color,
                                },
                                selectedTask: null,
                              });
                            } else {
                              await this.props.changeState({
                                selectedProject: parseInt(selected.value, 10),
                                selectedProjectOption: selected,
                                selectedTask: null,
                              });
                              if (this.state.newProject) {
                                this.setState({ newProject: false });
                                this.props.changeState({
                                  selectedCustomerOption: null,
                                });
                              }
                            }
                          }}
                          isCreatable={state.allowCreateProject ? true : false}
                          id="check-in-dialog-project-input"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    {this.state.newProject ? (
                      <Grid container spacing={2} className={classes.mb}>
                        <Grid
                          item
                          xs={12}
                          id="check-in-dialog-customer-input-container"
                        >
                          <MaterialAsyncPaginate
                            classes={classes}
                            textFieldProps={{
                              label: i18next.t("Customer"),
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            error={this.state.customerRequired}
                            loadOptions={this.loadDefaultCustomersList}
                            value={state.selectedCustomerOption}
                            onBlur={() => {
                              if (!state.selectedCustomerOption) {
                                this.setState({ customerRequired: true });
                              } else {
                                this.setState({ customerRequired: false });
                              }
                            }}
                            onChange={async (selected) => {
                              if (selected.__isNew__) {
                                await this.props.changeState({
                                  selectedCustomerOption: {
                                    ...selected,
                                    value: selected.value,
                                    label: selected.value,
                                  },
                                });
                                await this.props.addCustomer(selected.value);
                              } else {
                                await this.props.changeState({
                                  selectedCustomerOption: selected,
                                });
                                await this.props.addProject(
                                  state.selectedProjectOption.__isNew__
                                    ? state.selectedProjectOption.value
                                    : state.selectedProjectOption.label,
                                  selected.value
                                );
                              }
                            }}
                            isCreatable={
                              state.allowCreateProject ? true : false
                            }
                            id="check-in-dialog-customer-input"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    ) : null}

                    <Grid
                      container
                      spacing={2}
                      style={{ marginBottom: "1rem" }}
                    >
                      <Grid
                        item
                        xs={12}
                        id="check-in-dialog-task-input-container"
                      >
                        <MaterialAsyncPaginate
                          //defaultOptions
                          classes={classes}
                          //classes={{ selectInput: classes.selectInput }}
                          textFieldProps={{
                            label: i18next.t("Task"),
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          error={this.state.taskRequired}
                          onBlur={() => {
                            if (!state.selectedTask) {
                              this.setState({ taskRequired: true });
                            } else {
                              this.setState({ taskRequired: false });
                            }
                          }}
                          loadOptions={async (search, loadedOptions) => {
                            return this.loadTaskOptions(loadedOptions, search);
                          }}
                          value={state.selectedTask}
                          onChange={async (selected) => {
                            if (selected.__isNew__) {
                              this.props.changeState({
                                selectedTask: {
                                  ...selected,
                                  value: selected.value,
                                  label: selected.value,
                                },
                              });
                              if (!state.selectedProjectOption) {
                                this.setState({ projectRequired: true });
                              } else {
                                await this.props.addTask(selected.value);
                                this.setState({
                                  projectRequired: false,
                                  newTaskCount: this.state.newTaskCount + 1,
                                });
                              }
                            } else if (!state.selectedProjectOption) {
                              this.props.changeState({
                                selectedTask: selected,
                              });
                              this.props.changeState({
                                selectedProjectOption: {
                                  value: selected.project_id,
                                  label: `${selected.project_name} - ${selected.customer_name}`,
                                },
                                selectedProject: parseInt(
                                  selected.project_id,
                                  10
                                ),
                              });
                            } else {
                              this.props.changeState({
                                selectedTask: selected,
                              });
                            }
                            this.validateItemAndDependency(selected);
                          }}
                          isCreatable={state.allowCreateTask ? true : false}
                          cacheUniqs={[
                            state.selectedProject,
                            this.state.newTaskCount,
                          ]}
                          id="check-in-dialog-task-input"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    {(!this.state.isHasItemCertificationPermission ||
                      !this.state.isHasDependencyPermission) && (
                      <Box className={classes.alertBox}>
                        <Alert severity="warning" sx={{ width: "100%" }}>
                          {i18next.t(
                            "You don't have required item or dependency"
                          )}
                        </Alert>
                      </Box>
                    )}

                    {state.rulesModuleAssigned &&
                      state.isHourRulesException && (
                        <Grid
                          container
                          spacing={2}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              component="div"
                              id="check-in-dialog-add-outside-rules-block"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={state.isTypeSelected}
                                    onChange={() => {
                                      this.props.changeState({
                                        isTypeSelected: !state.isTypeSelected,
                                        selectedHourType: null,
                                      });
                                    }}
                                    classes={{
                                      root: classes.checkBox,
                                      checked: classes.checked,
                                    }}
                                  />
                                }
                                label={i18next.t("Add outside of the rules")}
                                style={{ marginBottom: 2 }}
                                id="check-in-dialog-add-outside-rules-input"
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    {(!state.rulesModuleAssigned ||
                      (state.isTypeSelected &&
                        state.rulesModuleAssigned &&
                        state.isHourRulesException)) && (
                      <Grid container spacing={2} className={classes.mb}>
                        <Grid
                          item
                          xs={12}
                          id="check-in-dialog-hour-type-container"
                        >
                          <MaterialSelect
                            classes={classes}
                            //classes={{ selectInput: classes.selectInput }}
                            textFieldProps={{
                              label: i18next.t("Hour type"),
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            options={state.hourTypeOptions}
                            value={state.selectedHourType}
                            onChange={async (selected) => {
                              await this.props.changeState({
                                selectedHourType: selected,
                              });
                            }}
                            id="check-in-dialog-hour-type-input"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        id="check-in-dialog-project-input-container"
                      >
                        {state.planningModuleAssigned && state.plannedHours && (
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              id="check-in-dialog-planned-hours-block"
                            >
                              <Typography
                                variant="body2"
                                className={classes.planHeading}
                                id="check-in-dialog-planned-hours-block-title"
                              >
                                {i18next.t("Planned tasks")}
                              </Typography>
                              <div
                                className={classes.planContainer}
                                id="check-in-dialog-planned-hours-card-container"
                              >
                                {state.plannedHours.map((plan) => {
                                  return (
                                    <PlanCard
                                      key={plan.id}
                                      plan={plan}
                                      selectPlan={this.selectPlan}
                                      getPlanUsers={this.getPlanUsers}
                                      isItemCertificationRequiered={
                                        state.isItemCertificationRequiered
                                      }
                                      isDependencyRequiered={
                                        state.isDependencyRequiered
                                      }
                                      isAllowToSeeOtherUsersPlans={
                                        state.isAllowToSeeOtherUsersPlans
                                      }
                                    />
                                  );
                                })}
                                {state.plannedHours.length === 0 && (
                                  <Typography
                                    component="div"
                                    className={classes.noPlanBlock}
                                  >
                                    <Typography variant="h6">
                                      {i18next.t("Nothing planned for today")}
                                    </Typography>
                                  </Typography>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
              <button type="submit" style={{ display: "none" }} />
            </form>
          </DialogContent>

          <DialogActions
            className={classes.actionButtons}
            style={
              state.editId
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                  }
                : {}
            }
          >
            <Button
              className={classes.btnCancel}
              onClick={this.handleCancel}
              size="large"
              id="check-in-dialog-cancel-button"
            >
              {i18next.t("Cancel")}
            </Button>
            <Button
              disabled={
                !this.state.isHasItemCertificationPermission ||
                !this.state.isHasDependencyPermission ||
                !state.selectedProject ||
                !state.selectedTask
              }
              type="submit"
              className={classes.checkInButton}
              variant="contained"
              size="large"
              onClick={() => {
                this.setState({
                  newProject: false,
                  customerRequired: false,
                });

                handleCheckInStart();
              }}
              id="check-in-dialog-start-button"
            >
              {i18next.t("Check in")}
            </Button>
            <Typography component="div" style={{ minWidth: 100 }}>
              {""}
            </Typography>
          </DialogActions>
        </Dialog>

        <Popover
          open={this.state.openPopover}
          anchorEl={this.state.anchorEl}
          onClose={() => {
            this.setState({ openPopover: false });
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Typography
            component="div"
            className={classes.poperBody}
            id="check-in-other-users-popup"
          >
            <Typography component="div" className={classes.poperHeader}>
              {i18next.t("Other users")}
              <IconButton
                aria-label={i18next.t("Close")}
                className={classes.closePopoverButton}
                onClick={this.handlePopoverCancel}
                size="large"
              >
                <CloseIcon className={classes.closePopoverIcon} />
              </IconButton>
            </Typography>
            {specificPlanUsersLoading ? (
              <Typography variant="body2" className={classes.poperMsg}>
                {i18next.t("Loading...")}
              </Typography>
            ) : (
              <>
                {userList.length === 0 ? (
                  <Typography variant="body2" className={classes.poperMsg}>
                    {i18next.t("No users found")}
                  </Typography>
                ) : (
                  <div>
                    {userList.map((userName, index) => {
                      return (
                        <div className={classes.poperItem} key={index}>
                          {`${index + 1}. ${userName}`}
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </Typography>
        </Popover>
      </Typography>
    );
  }
}

export default withStyles(styles)(FormCheckInModal);

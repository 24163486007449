import React from "react";

import { MaterialSelect } from "@/common/selectComponents/MaterialSelect";
import { MaterialAsyncPaginate } from "@/common/selectComponents/MaterialAsyncPaginate";
import i18next from "i18next";
import { withStyles } from "@mui/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Switch,
  FormControlLabel,
  Checkbox,
  Box,
  Divider,
  RadioGroup,
  Radio,
  TextField,
  InputAdornment,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const styles = (theme) => ({
  button: {
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  btnCancel: {
    textTransform: "uppercase",
    color: theme.palette.text.disabled,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  formControl: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    width: "100%",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  borderBox: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    padding: "8px 16px 8px 16px",
    marginTop: "8px",
  },
  dividerStyle: {
    marginTop: theme.spacing(1),
  },
});

class SelectProjectTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectRequired: false,
      taskRequired: false,
      newTaskCount: 0,
      customerRequired: false,
      newProject: false,
      hourTypeInputError: false,
      addonTypeInputError: false,
      inputError: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.state.openProjectTaskModal !==
      this.props.state.openProjectTaskModal
    ) {
      if (this.props.state.openProjectTaskModal) {
        this.checkInputs();
      }
    }
  };

  resetInputStates = () => {
    this.props.changeState({
      selectedType: "hour_type",
      isTypeSelected: false,
      selectedProjectOption: null,
      selectedProject: null,
      selectedTask: null,
      selectedAddonType: null,
      selectedHourType: null,
      isTaskFinished: false,
      selectedCustomerOption: null,
      selectedFixedAddonType: null,
      fixedAddonValue: "",
    });
  };

  handleCancel = () => {
    this.setState({
      projectRequired: false,
      taskRequired: false,
      newProject: false,
      customerRequired: false,
      hourTypeInputError: false,
      addonTypeInputError: false,
      inputError: false,
    });
    this.props.changeState({
      openProjectTaskModal: false,
      multipleProjectNodeEditIndex: null,
      isEditMultipleProjectNode: false,
      isMultiProjectHourTypeRequired: false,
    });
    this.resetInputStates();
  };

  loadTaskOptions = async (search, loadedOptions) => {
    let limit = 20;
    let offset =
      loadedOptions && loadedOptions.length ? loadedOptions.length : 0;

    const result = await this.props.getTaskOptions(limit, offset, search);
    return result;
  };

  loadDefaultCustomersList = async (search, loadedOptions) => {
    let limit = 20;
    let offset =
      loadedOptions && loadedOptions.length ? loadedOptions.length : 0;

    const result = await this.props.getCustomersListOptions(
      limit,
      offset,
      search
    );
    return result;
  };

  onTypeChange = async (e) => {
    await this.props.changeState({
      selectedType: e.target.value,
    });
    this.checkInputs();
  };

  assignDefualtAddonTimeSlots = (node) => {
    const { state } = this.props;
    const min = moment.duration(state.startTime).asMinutes();
    let max = moment.duration(state.endTime).asMinutes();
    if (state.endTime < state.startTime) {
      max = max + 1440;
    }

    if (node["type_amount"]) {
      node["start_time"] = min;

      const amountAsMinutes = 60 * parseInt(node["type_amount"]);
      if (max - min >= amountAsMinutes) {
        node["end_time"] = min + amountAsMinutes;
      } else {
        node["end_time"] = max;
      }
    }

    return node;
  };

  assignAddonSlotForEditMode = (node) => {
    const { state } = this.props;
    let max = moment.duration(state.endTime).asMinutes();
    if (state.endTime < state.startTime) {
      max = max + 1440;
    }

    node["start_time"] = max;
    node["end_time"] = max;

    return node;
  };

  assignAddonTimeSlots = (nodes) => {
    const { state } = this.props;
    const min = moment.duration(state.startTime).asMinutes();
    let max = moment.duration(state.endTime).asMinutes();
    if (state.endTime < state.startTime) {
      max = max + 1440;
    }

    const totalNodes = nodes.length;

    let totalSlotNodes = 0;
    for (let i = 0; i < totalNodes; i++) {
      if (!nodes[i]["type_amount"]) {
        totalSlotNodes++;
      }
    }

    if (totalNodes === 1) {
      nodes[0]["start_time"] = min;
      nodes[0]["end_time"] = max;
    } else if (totalSlotNodes > 0) {
      const slotSize = (max - min) / totalSlotNodes;
      let slotCounter = 0;
      for (let i = 0; i < totalNodes; i++) {
        if (nodes[i]["disabled"]) continue;

        if (nodes[i]["type_amount"]) continue;

        if (i === 0) {
          nodes[i]["start_time"] = min;
          nodes[i]["end_time"] = min + slotSize;
        } else if (i === totalNodes - 1) {
          nodes[i]["start_time"] = min + slotSize * (totalSlotNodes - 1);
          nodes[i]["end_time"] = max;
        } else {
          nodes[i]["start_time"] = min + slotSize * slotCounter;
          nodes[i]["end_time"] = min + slotSize * (slotCounter + 1);
        }

        slotCounter++;
      }
    }

    return nodes;
  };

  assignTimeSlots = (nodes) => {
    const { state } = this.props;
    const min = moment.duration(state.startTime).asMinutes();
    let max = moment.duration(state.endTime).asMinutes();
    if (state.endTime < state.startTime) {
      max = max + 1440;
    }

    const totalNodes = nodes.length;

    if (totalNodes === 1) {
      nodes[0]["start_time"] = min;
      nodes[0]["end_time"] = max;
    } else {
      const slotSize = (max - min) / totalNodes;
      for (let i = 0; i < totalNodes; i++) {
        if (i === 0) {
          nodes[i]["start_time"] = min;
          nodes[i]["end_time"] = min + slotSize;
        } else if (i === totalNodes - 1) {
          nodes[i]["start_time"] = min + slotSize * (totalNodes - 1);
          nodes[i]["end_time"] = max;
        } else {
          nodes[i]["start_time"] = min + slotSize * i;
          nodes[i]["end_time"] = min + slotSize * (i + 1);
        }
      }
    }

    return nodes;
  };

  onSubmit = () => {
    if (this.props.state.isEditMultipleProjectNode) {
      this.editTaskNode();
    } else {
      this.addTaskNode();
    }

    this.handleCancel();
  };

  addTaskNode = () => {
    const { state } = this.props;

    const projectObj = this.props.projectList.find((project) => {
      return project.id === parseInt(state.selectedProjectOption.value, 10);
    });

    let taskObj = {
      project_id: state.selectedProjectOption.value,
      project_name: projectObj
        ? projectObj.name
        : state.selectedProjectOption.label.props
          ? state.selectedProjectOption.label.props.primary
          : state.selectedProjectOption.label,
      project_color: projectObj ? projectObj.color : null,
      task_id: parseInt(state.selectedTask.value, 10),
      task_name: state.selectedTask.label.props
        ? state.selectedTask.label.props.primary
        : state.selectedTask.label,
    };

    if (state.selectedType === "time_addon" && state.selectedAddonType) {
      taskObj["start_time"] = 0;
      taskObj["end_time"] = 0;

      taskObj["type_id"] = parseInt(state.selectedAddonType.value, 10);
      taskObj["type_name"] = state.selectedAddonType.label;

      if (state.selectedAddonType.amount) {
        taskObj["type_amount"] = state.selectedAddonType.amount;
      }

      if (state.allowFinishTask && state.isTaskFinished) {
        taskObj["task_finish"] = true;
      }

      let selectedProjects = state.multiProjectAddonTasks;

      if (state.selectedAddonType.amount) {
        taskObj = this.assignDefualtAddonTimeSlots(taskObj);
        selectedProjects.push(taskObj);
      }
      // Edit mode and if nodes present, add new node with start/end time same as end time of the hour
      else if (state.groupId && selectedProjects.length > 0) {
        taskObj = this.assignAddonSlotForEditMode(taskObj);
        selectedProjects.push(taskObj);
      } else {
        // add node and assign time slot as per main start/end time
        selectedProjects.push(taskObj);
        selectedProjects = this.assignAddonTimeSlots(selectedProjects);
      }

      this.props.changeState({
        multiProjectAddonTasks: selectedProjects,
        hourModalDataChanged: true,
      });
    } else if (
      state.selectedType === "fixed_addon" &&
      state.selectedFixedAddonType
    ) {
      taskObj["type_id"] = state.selectedFixedAddonType.value;
      taskObj["type_name"] = state.selectedFixedAddonType.label;
      taskObj["type_color"] = state.selectedFixedAddonType?.color;
      taskObj["type_measurement"] = state.selectedFixedAddonType?.measurement;
      taskObj["type_value"] = state.fixedAddonValue;

      let currentFixedAddonTasks = state.fixedAddonTasks;
      currentFixedAddonTasks.push(taskObj);

      this.props.changeState({
        fixedAddonTasks: currentFixedAddonTasks,
        hourModalDataChanged: true,
      });
    } else {
      taskObj["start_time"] = 0;
      taskObj["end_time"] = 0;

      if (state.selectedHourType) {
        taskObj["type_id"] = parseInt(state.selectedHourType.value, 10);
        taskObj["type_name"] = state.selectedHourType.label;
      }

      if (state.allowFinishTask && state.isTaskFinished) {
        taskObj["task_finish"] = true;
      }

      if (state.isCheckInEntry) {
        taskObj["checkin_add"] = true;
      }

      let selectedProjects = state.multiProjectTasks;

      // Edit mode and if nodes present, add new node with start/end time same as end time of last node
      if (state.groupId && selectedProjects.length > 0) {
        const lastNode = selectedProjects[selectedProjects.length - 1];
        taskObj["start_time"] = lastNode["end_time"];
        taskObj["end_time"] = lastNode["end_time"];

        selectedProjects.push(taskObj);
      } else {
        // add node and assign time slot as per main start/end time
        selectedProjects.push(taskObj);
        selectedProjects = this.assignTimeSlots(selectedProjects);
      }

      this.props.changeState({
        multiProjectTasks: selectedProjects,
        hourModalDataChanged: true,
      });
    }
  };

  editTaskNode = () => {
    const { state } = this.props;

    const projectObj = this.props.projectList.find((project) => {
      return project.id === parseInt(state.selectedProjectOption.value, 10);
    });

    const index = state.multipleProjectNodeEditIndex;
    if (state.selectedType === "time_addon") {
      if (state.multiProjectAddonTasks[index]) {
        const multiProjectAddonTasks = state.multiProjectAddonTasks;
        const taskNode = state.multiProjectAddonTasks[index];

        taskNode["project_id"] = state.selectedProjectOption.value;
        taskNode["project_name"] = projectObj
          ? projectObj.name
          : state.selectedProjectOption.label.props
            ? state.selectedProjectOption.label.props.primary
            : state.selectedProjectOption.label;
        taskNode["task_id"] = parseInt(state.selectedTask.value, 10);
        taskNode["task_name"] = state.selectedTask.label.props
          ? state.selectedTask.label.props.primary
          : state.selectedTask.label;

        if (state.selectedAddonType && state.selectedAddonType.value) {
          taskNode["type_id"] = parseInt(state.selectedAddonType.value, 10);
          taskNode["type_name"] = state.selectedAddonType.label;
        } else {
          taskNode["type_id"] = null;
        }

        multiProjectAddonTasks[index] = taskNode;
        this.props.changeState({
          multiProjectAddonTasks: multiProjectAddonTasks,
          hourModalDataChanged: true,
        });
      }
    } else if (state.selectedType === "fixed_addon") {
      if (state.fixedAddonTasks[index]) {
        const fixedAddonTasks = state.fixedAddonTasks;
        const taskNode = state.fixedAddonTasks[index];

        taskNode["project_id"] = state.selectedProjectOption.value;
        taskNode["project_name"] = projectObj
          ? projectObj.name
          : state.selectedProjectOption.label.props
            ? state.selectedProjectOption.label.props.primary
            : state.selectedProjectOption.label;
        taskNode["task_id"] = parseInt(state.selectedTask.value, 10);
        taskNode["task_name"] = state.selectedTask.label.props
          ? state.selectedTask.label.props.primary
          : state.selectedTask.label;

        if (state.selectedFixedAddonType?.value) {
          taskNode["type_id"] = state.selectedFixedAddonType.value;
          taskNode["type_name"] = state.selectedFixedAddonType.label;
        } else {
          taskNode["type_id"] = null;
        }

        taskNode["type_measurement"] =
          state.selectedFixedAddonType?.measurement;
        taskNode["type_color"] = state.selectedFixedAddonType?.color;
        taskNode["type_value"] = state?.fixedAddonValue;

        if (state.fixedAddonValue) fixedAddonTasks[index] = taskNode;
        this.props.changeState({
          fixedAddonTasks: fixedAddonTasks,
          hourModalDataChanged: true,
        });
      }
    } else {
      if (state.multiProjectTasks[index]) {
        const multiProjectTasks = state.multiProjectTasks;
        const taskNode = state.multiProjectTasks[index];

        taskNode["project_id"] = state.selectedProjectOption.value;
        taskNode["project_name"] = projectObj
          ? projectObj.name
          : state.selectedProjectOption.label.props
            ? state.selectedProjectOption.label.props.primary
            : state.selectedProjectOption.label;
        taskNode["task_id"] = parseInt(state.selectedTask.value, 10);
        taskNode["task_name"] = state.selectedTask.label.props
          ? state.selectedTask.label.props.primary
          : state.selectedTask.label;

        if (state.selectedHourType && state.selectedHourType.value) {
          taskNode["type_id"] = parseInt(state.selectedHourType.value, 10);
          taskNode["type_name"] = state.selectedHourType.label;
        } else {
          taskNode["type_id"] = null;
        }

        multiProjectTasks[index] = taskNode;
        this.props.changeState({
          multiProjectTasks: multiProjectTasks,
          hourModalDataChanged: true,
        });
      }
    }
  };

  checkInputs = () => {
    const { state } = this.props;

    let inputError = false;
    if (!state.selectedProject || !state.selectedTask) {
      inputError = true;
    }

    if (state.isMultiProjectHourTypeRequired) {
      if (state.selectedType === "time_addon") {
        if (!state.selectedAddonType || !state.selectedAddonType.value) {
          inputError = true;
        }
      } else if (state.selectedType === "hour_type") {
        if (!state.selectedHourType || !state.selectedHourType.value) {
          inputError = true;
        }
      }
    }

    if (state.selectedType === "fixed_addon") {
      if (!state.selectedFixedAddonType || !state.fixedAddonValue) {
        inputError = true;
      }
    }

    if (this.state.inputError !== inputError) {
      this.setState({ inputError: inputError });
    }

    if (state.selectedProjectOption && this.state.projectRequired) {
      this.setState({ projectRequired: false });
    }
  };

  render() {
    const { state, classes } = this.props;

    let isAddOutSideRules = false;

    // check if we need to show the tickbox for Add Out Sider Rules (Override rules)
    if (
      state.rulesModuleAssigned &&
      (state.isHourRulesException || state.isAddonRulesException)
    ) {
      isAddOutSideRules = true;

      // In Edit mode, if hour is open to edit, hour rules exception MUST be enabled, same for addon.
      if (state.isEditMultipleProjectNode) {
        if (state.selectedType === "hour_type" && !state.isHourRulesException) {
          isAddOutSideRules = false;
        } else if (
          (state.selectedType === "time_addon" ||
            state.selectedType === "fixed_addon") &&
          !state.isAddonRulesException
        ) {
          isAddOutSideRules = false;
        }
      }
    }

    // Allow Override switch change only if the restriction setting is OFF
    let allowOverrideSwitchChange = !state.isMultiProjectHourTypeRequired;

    // In case of Fixed addon edit, do not allow Override switch change as we need fixed addon type for Fixed addon.
    if (
      state.isEditMultipleProjectNode &&
      state.selectedType === "fixed_addon"
    ) {
      allowOverrideSwitchChange = false;
    }

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={state.openProjectTaskModal}
        onClose={this.handleCancel}
        id="select-project-task-modal"
      >
        <DialogTitle
          onClose={this.handleCancel}
          style={{ paddingBottom: "16px" }}
          id="project-task-dialog-title"
        >
          {state.isEditMultipleProjectNode
            ? i18next.t("Edit task")
            : i18next.t("Add task")}
          <IconButton
            aria-label={i18next.t("Close")}
            className={classes.closeButton}
            onClick={this.handleCancel}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (state.selectedProject && state.selectedTask) {
              this.onSubmit();
            }
          }}
        >
          <DialogContent style={{ padding: "0 34px 0 34px" }}>
            <FormControl
              className={classes.formControl}
              id="project-task-modal-project-container"
            >
              <MaterialAsyncPaginate
                variant="outlined"
                //classes={classes}
                textFieldProps={{
                  label: i18next.t("Project"),
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                error={this.state.projectRequired}
                loadOptions={async (search, loadedOptions) => {
                  return this.props.loadProjectList(search, loadedOptions);
                }}
                value={state.selectedProjectOption}
                onChange={async (selected) => {
                  if (selected.__isNew__) {
                    this.setState({ newProject: true });
                    await this.props.changeState({
                      selectedProjectOption: {
                        ...selected,
                        value: selected.value,
                        label: selected.value,
                      },
                      selectedTask: null,
                    });
                  } else {
                    await this.props.changeState({
                      selectedProject: parseInt(selected.value, 10),
                      selectedProjectOption: selected,
                      selectedTask: null,
                    });
                    if (this.state.newProject) {
                      this.setState({ newProject: false });
                      this.props.changeState({
                        selectedCustomerOption: null,
                      });
                    }
                  }
                  this.checkInputs();
                }}
                isCreatable={state.allowCreateProject ? true : false}
                id="project-task-modal-project-input"
              />
            </FormControl>
            {this.state.newProject ? (
              <FormControl
                className={classes.formControl}
                id="project-task-modal-customer-container"
              >
                <MaterialAsyncPaginate
                  variant="outlined"
                  classes={classes}
                  textFieldProps={{
                    label: i18next.t("Customer"),
                    InputLabelProps: {
                      shrink: true,
                    },
                  }}
                  error={this.state.customerRequired}
                  loadOptions={this.loadDefaultCustomersList}
                  value={state.selectedCustomerOption}
                  onBlur={() => {
                    if (!state.selectedCustomerOption) {
                      this.setState({ customerRequired: true });
                    } else {
                      this.setState({ customerRequired: false });
                    }
                  }}
                  onChange={async (selected) => {
                    if (selected.__isNew__) {
                      await this.props.changeState({
                        selectedCustomerOption: {
                          ...selected,
                          value: selected.value,
                          label: selected.value,
                        },
                      });
                      await this.props.addCustomer(selected.value);
                    } else {
                      await this.props.changeState({
                        selectedCustomerOption: selected,
                      });
                      await this.props.addProject(
                        state.selectedProjectOption.__isNew__
                          ? state.selectedProjectOption.value
                          : state.selectedProjectOption.label.props
                            ? state.selectedProjectOption.label.props.primary
                            : state.selectedProjectOption.label,
                        selected.value
                      );
                    }
                  }}
                  isCreatable={state.allowCreateProject ? true : false}
                  id="project-task-modal-customer-input"
                />
              </FormControl>
            ) : null}
            <FormControl
              className={classes.formControl}
              id="project-task-modal-task-container"
            >
              <MaterialAsyncPaginate
                variant="outlined"
                classes={classes}
                textFieldProps={{
                  label: i18next.t("Task"),
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                error={this.state.taskRequired}
                onBlur={() => {
                  if (!state.selectedTask) {
                    this.setState({ taskRequired: true });
                  } else {
                    this.setState({ taskRequired: false });
                  }
                }}
                loadOptions={this.loadTaskOptions}
                value={state.selectedTask}
                onChange={async (selected) => {
                  if (selected.__isNew__) {
                    await this.props.changeState({
                      selectedTask: {
                        ...selected,
                        value: selected.value,
                        label: selected.value,
                      },
                    });
                    if (!state.selectedProjectOption) {
                      this.setState({ projectRequired: true });
                    } else {
                      await this.props.addTask(selected.value);
                      this.setState({
                        projectRequired: false,
                        newTaskCount: this.state.newTaskCount + 1,
                      });
                    }
                  } else if (!state.selectedProjectOption) {
                    await this.props.changeState({
                      selectedTask: selected,
                    });
                    this.props.changeState({
                      selectedProjectOption: {
                        value: selected.project_id,
                        label: `${selected.project_name} - ${selected.customer_name}`,
                      },
                      selectedProject: parseInt(selected.project_id, 10),
                    });
                  } else {
                    await this.props.changeState({
                      selectedTask: selected,
                    });
                  }
                  this.checkInputs();
                }}
                isCreatable={state.allowCreateTask ? true : false}
                cacheUniqs={[state.selectedProject, this.state.newTaskCount]}
                id="project-task-modal-task-input"
              />
            </FormControl>
            {state.allowFinishTask && !state.isEditMultipleProjectNode && (
              <FormControl className={classes.formControl}>
                <div id="project-task-modal-task-finished-block">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.isTaskFinished}
                        onChange={() => {
                          this.props.changeState({
                            isTaskFinished: !state.isTaskFinished,
                          });
                        }}
                        classes={{
                          root: classes.checkBox,
                          checked: classes.checked,
                        }}
                        id="project-task-modal-task-finished-tickbox"
                      />
                    }
                    label={i18next.t("Task finished")}
                    style={{ marginBottom: 0 }}
                    id="project-task-modal-task-finished-input"
                  />
                </div>
              </FormControl>
            )}

            <Box className={isAddOutSideRules ? classes.borderBox : null}>
              {isAddOutSideRules && (
                <>
                  <div id="project-task-modal-outside-rules-block">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={state.isTypeSelected}
                          onChange={() => {
                            if (allowOverrideSwitchChange) {
                              this.props.changeState({
                                isTypeSelected: !state.isTypeSelected,
                                selectedHourType: null,
                                selectedAddonType: null,
                                selectedFixedAddonType: null,
                                fixedAddonValue: "",
                              });
                            }
                          }}
                          id="project-task-modal-outside-rules-tickbox"
                        />
                      }
                      label={i18next.t("Override rules")}
                      id="project-task-modal-outside-rules-input"
                    />
                  </div>

                  {state.isTypeSelected && (
                    <Divider className={classes.dividerStyle} />
                  )}
                </>
              )}

              {
                // show radio boxe option only if Override rule OR rule module not assigned but addon module assigned so that we can show hour type and fixed addon option. Hide in case of Edit modal
                ((!state.rulesModuleAssigned && state.addonModuleAssigned) ||
                  state.isTypeSelected) &&
                  !state.isEditMultipleProjectNode && (
                    <FormControl className={classes.formControl}>
                      <div id="type-radio-group">
                        <RadioGroup
                          aria-label="type-options"
                          name="typeOptions"
                          value={state.selectedType}
                          defaultValue="hour_type"
                          onChange={this.onTypeChange}
                        >
                          <FormControlLabel
                            value="hour_type"
                            control={<Radio />}
                            label={i18next.t("Add hour type")}
                          />
                          {state.rulesModuleAssigned &&
                            state.addonModuleAssigned &&
                            state.isAddonRulesException && (
                              <FormControlLabel
                                value="time_addon"
                                control={<Radio />}
                                label={i18next.t("Add time addon")}
                              />
                            )}
                          {state.addonModuleAssigned && (
                            <FormControlLabel
                              value="fixed_addon"
                              control={<Radio />}
                              label={i18next.t("Add fixed addon")}
                            />
                          )}
                        </RadioGroup>
                      </div>
                      <Divider className={classes.dividerStyle} />
                    </FormControl>
                  )
              }

              {
                // if Rules module not assigned then we just need to show Hour type option
                (!state.rulesModuleAssigned || state.isTypeSelected) &&
                  (!state.rulesModuleAssigned ||
                    (state.rulesModuleAssigned &&
                      state.isHourRulesException)) &&
                  state.selectedType === "hour_type" && (
                    <FormControl
                      className={classes.formControl}
                      id="project-task-modal-hour-type-container"
                    >
                      <MaterialSelect
                        variant="outlined"
                        classes={classes}
                        textFieldProps={{
                          label: i18next.t("Hour type"),
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        error={this.state.hourTypeInputError}
                        options={state.hourTypeOptions}
                        value={state.selectedHourType}
                        onChange={async (selected) => {
                          await this.props.changeState({
                            selectedHourType: selected,
                          });
                          this.checkInputs();
                        }}
                        onBlur={() => {
                          if (
                            (!state.selectedHourType ||
                              !state.selectedHourType.value) &&
                            state.isMultiProjectHourTypeRequired
                          ) {
                            this.setState({ hourTypeInputError: true });
                          } else if (this.state.hourTypeInputError) {
                            this.setState({
                              hourTypeInputError: false,
                            });
                          }
                        }}
                        id="project-task-modal-hour-type-input"
                      />
                    </FormControl>
                  )
              }
              {state.rulesModuleAssigned &&
                state.addonModuleAssigned &&
                state.selectedType === "time_addon" &&
                state.isAddonRulesException && (
                  <FormControl
                    className={classes.formControl}
                    id="project-task-modal-addon-type-container"
                  >
                    <MaterialSelect
                      variant="outlined"
                      classes={classes}
                      textFieldProps={{
                        label: i18next.t("Time addon"),
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      error={this.state.addonTypeInputError}
                      options={state.addonTypeOptions}
                      value={state.selectedAddonType}
                      onChange={async (selected) => {
                        await this.props.changeState({
                          selectedAddonType: selected,
                        });
                        this.checkInputs();
                      }}
                      onBlur={() => {
                        if (
                          (!state.selectedAddonType ||
                            !state.selectedAddonType.value) &&
                          state.isMultiProjectHourTypeRequired
                        ) {
                          this.setState({
                            addonTypeInputError: true,
                          });
                        } else if (this.state.addonTypeInputError) {
                          this.setState({
                            addonTypeInputError: false,
                          });
                        }
                      }}
                      id="project-task-modal-addon-type-input"
                    />
                  </FormControl>
                )}

              {state.selectedType === "fixed_addon" &&
                state.addonModuleAssigned && (
                  <>
                    <FormControl
                      className={classes.formControl}
                      id="project-task-modal-addon-type-container"
                    >
                      <MaterialSelect
                        variant="outlined"
                        classes={classes}
                        textFieldProps={{
                          label: i18next.t("Fixed addon type"),
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        options={state.fixedAddonTypeOptions}
                        value={state.selectedFixedAddonType}
                        onChange={async (selected) => {
                          await this.props.changeState({
                            selectedFixedAddonType: selected,
                          });
                          this.checkInputs();
                        }}
                        id="project-task-modal-addon-type-input"
                      />
                    </FormControl>
                    <FormControl
                      className={classes.formControl}
                      id="projet-task-modal-addon-type-container"
                    >
                      <TextField
                        label={i18next.t("Amount")}
                        value={state.fixedAddonValue}
                        onChange={async (e) => {
                          await this.props.changeState({
                            fixedAddonValue: e.target.value,
                          });
                          this.checkInputs();
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {state.selectedFixedAddonType?.measurement || ""}
                            </InputAdornment>
                          ),
                        }}
                        id="add-task-modal-fixed-addon-value"
                      />
                    </FormControl>
                  </>
                )}
            </Box>
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                className={classes.btnCancel}
                onClick={this.handleCancel}
                id="project-task-modal-cancel-button"
              >
                {i18next.t("Cancel")}
              </Button>{" "}
              <Button
                disabled={this.state.inputError}
                type="submit"
                className={classes.button}
                id={`project-task-modal-${
                  state.isEditMultipleProjectNode ? "submit" : "add"
                }-button`}
              >
                {state.isEditMultipleProjectNode
                  ? i18next.t("Submit")
                  : i18next.t("Add")}
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SelectProjectTaskModal);

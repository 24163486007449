import React from "react";
import { ActionCreators } from "@/redux/actions";
import { actions as filterActions } from "@/redux/reducers/filters";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import OverlayIndicator from "@/common/OverlayIndicator";
import HoursFilters from "./components/HourFilters";
import Table from "./components/table";
import { withSnackbar } from "notistack";
import { geolocated } from "react-geolocated";
import AlertModal from "@/common/AlertModal";
import { Redirect } from "react-router-dom";
import { actions as authActions } from "@/utils/auth";

import { getItem, setItem } from "@/utils/localStorage";

class PlannedHours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hourlist: [],
      checkError: false,
      limit: 140, // standard pagination doesn't work with card view so use higher limit to prevent pagination
      //activePage: 1,
      checkNewData: true,
      comment: "",
      amount: "",
      hours: "",
      minutes: "",
      startTime: "00:00",
      endTime: "00:00",
      havePermission: true,
      userTotalHours: 0,
      weekDayTotalHours: 0,
      amountError: false,
      isGeoLockRequired: false,
      openAlertModal: true,
      userId: null,
      checkinStep: null,
      checkinPlan: null,
      timeDuration: 0,
      timerId: null,
      lateComment: "",
      checkoutData: null,
      checkCheckInError: false,
      checkAttendanceError: false,
      attendaceModuleAssigned: false,
      checkoutLogObj: null,
      checkAttendanceLog: false,
      isGeoFenceForAttendace: false,
      isGeoFenceDisabled: false,
      isHourCheckin: false,
      reportWebAttendance: true,
      checkAssignStatus: false,
      userName: "",
      userCompany: "",
      userAvatar: "",
      weekdays: [],
    };
  }

  componentDidMount = async () => {
    await this.resetDefaultDates();
    if (getItem("userId")) {
      await this.setState({
        userId: parseInt(getItem("userId"), 10),
        userName: getItem("userName") ? getItem("userName") : "",
        userCompany: getItem("userCompany") ? getItem("userCompany") : "",
        userAvatar: getItem("avatarUrl") ? getItem("avatarUrl") : "",
      });
      this.getPlans();
    }
    this.setState({ checkNewData: true });
    this.props.actions.getCompanyModules();

    this.lookForCheckinPlan();
    this.prepareWeedDays();
  };

  lookForCheckinPlan = () => {
    const userId = getItem("userId") ? parseInt(getItem("userId"), 10) : null;

    if (userId) {
      const queryString = `user_id=${userId}`;
      this.props.actions.getCheckInHour(queryString);
    }
  };

  componentWillUnmount = () => {
    if (this.state.timerId) {
      clearInterval(this.state.timerId);
    }
  };

  resetDefaultDates = () => {
    this.props.filterActions.changeFilter({
      startDate: moment().clone().startOf("week"),
      endDate: moment().clone().endOf("week"),
    });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.companyReducer !== this.props.companyReducer) {
      if (
        this.props.companyReducer.companyModules &&
        this.props.companyReducer.companyModules.length > 0
      ) {
        if (
          !this.props.companyReducer.companyModules.find((data) => {
            return data.name === "Planning";
          })
        ) {
          this.setState({
            havePermission: false,
          });
        }
        if (
          this.props.companyReducer.companyModules.find((data) => {
            return data.name === "Attendance";
          })
        ) {
          this.setState({
            attendaceModuleAssigned: true,
          });
        }
      }
    }
    if (prevProps.companyReducer !== this.props.companyReducer) {
      if (
        this.props.companyReducer.companySettings &&
        this.props.companyReducer.companySettings.entries &&
        this.props.companyReducer.companySettings.entries.length > 0
      ) {
        let entries = this.props.companyReducer.companySettings.entries;
        if (entries && entries.length > 0) {
          for (let i = 0; i < entries.length; i++) {
            if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Geofence restriction"
            ) {
              if (
                entries[i].default_user_value === "TRUE" &&
                getItem("userRole") !== "admin"
              ) {
                this.setState({ isGeoLockRequired: true });
              } else if (
                entries[i].company_settings_value &&
                entries[i].company_settings_value.values &&
                entries[i].company_settings_value.values.user_ids
              ) {
                let user_ids =
                  entries[i].company_settings_value.values.user_ids;
                if (Array.isArray(user_ids) && user_ids.length > 0) {
                  if (user_ids.indexOf(this.state.userId) !== -1) {
                    this.setState({ isGeoLockRequired: true });
                  }
                }
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Geofence restriction for attendance"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  isGeoFenceForAttendace: true,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Disable geofence on Tablet app"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  isGeoFenceDisabled: true,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Separate hours from attendance"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  reportWebAttendance: false,
                });
              }
            }
          }
        }
      }
    }

    if (
      prevProps.startDate !== this.props.startDate &&
      prevProps.endDate !== this.props.endDate &&
      getItem("userId")
    ) {
      this.getPlans();
    }

    if (
      this.props.hourReducer &&
      this.props.hourReducer.checkInHour &&
      this.props.hourReducer.checkInHour.entries &&
      this.props.hourReducer.checkInHour.entries !==
        prevProps.hourReducer.checkInHour.entries
    ) {
      const entries = this.props.hourReducer.checkInHour.entries;

      let data = null;
      for (let i = 0; i < entries.length; i++) {
        // skip entries where end_time value exists
        if (entries[i].end_time) {
          continue;
        }

        if (entries[i].planning_planned_hour_id) {
          data = entries[i];
          this.setState({ isHourCheckin: false });
          break;
        } else {
          this.setState({ isHourCheckin: true });
          break;
        }
      }

      if (data) {
        const currentTime = moment(data.start_time)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSS");

        this.setState({
          checkInData: data,
          checkinStep: "checkin",
          checkinPlan: parseInt(data.planning_planned_hour_id),
        });

        let timerId = setInterval(async () => {
          if (this.state.checkinPlan) {
            const timeDeference = moment().diff(moment(currentTime));
            const timeDuration = moment.duration(timeDeference);
            this.setState({
              timeDuration: timeDuration,
            });
          } else {
            clearInterval(this.state.timerId);
            this.setState({
              timeDuration: 0,
            });
          }
        }, 2000);

        this.setState({
          timerId: timerId,
        });
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkCheckInError
    ) {
      if (
        this.props.hourReducer.checkInError &&
        this.props.hourReducer.checkInError.response &&
        this.props.hourReducer.checkInError.response.message
      ) {
        this.setState({
          checkCheckInError: false,
          checkinStep: null,
          checkinPlan: null,
          checkInData: null,
        });
        this.props.enqueueSnackbar(
          this.props.hourReducer.checkInError.response.message,
          { variant: "error" }
        );
      }
      if (
        this.props.hourReducer.createCheckInSuccess &&
        this.props.hourReducer.createCheckInSuccess.id
      ) {
        let checkInDataObj = this.state.checkInData;
        checkInDataObj["id"] = this.props.hourReducer.createCheckInSuccess.id;

        this.setState({
          checkCheckInError: false,
          checkInData: checkInDataObj,
        });
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkAttendanceError
    ) {
      if (
        this.props.hourReducer.attendanceError &&
        this.props.hourReducer.attendanceError.response &&
        this.props.hourReducer.attendanceError.response.message
      ) {
        this.setState({ checkAttendanceError: false });
        this.props.enqueueSnackbar(
          this.props.hourReducer.attendanceError.response.message,
          { variant: "error" }
        );
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkAttendanceLog
    ) {
      if (
        this.props.hourReducer.lastUserAttendance &&
        this.props.hourReducer.lastUserAttendance.entry
      ) {
        this.setState({ checkAttendanceLog: false });
        if (this.props.hourReducer.lastUserAttendance.entry.id) {
          const attendaceId =
            this.props.hourReducer.lastUserAttendance.entry.id;
          if (
            attendaceId &&
            this.state.checkoutLogObj &&
            this.state.reportWebAttendance
          ) {
            this.props.actions.updateUserAttendance(
              attendaceId,
              this.state.checkoutLogObj
            );
            this.setState({
              checkoutLogObj: null,
              checkAttendanceError: true,
            });
          }
        }
      }
    }

    if (
      this.props.planningReducer.planList &&
      prevProps.planningReducer.planList !==
        this.props.planningReducer.planList &&
      this.props.planningReducer.planList.entries
    ) {
      let plans = this.props.planningReducer.planList.entries;
      let userHours = [];

      for (let ID in plans) {
        userHours[ID] = 0;
        for (let i = 0; i < plans[ID].length; i++) {
          userHours[ID] += parseFloat(plans[ID][i].amount);

          // Add formated start date key for comparison in the PlanCard component
          plans[ID][i]["start_date_str"] = moment(plans[ID][i].start_time)
            .utc()
            .format("DD,MM,YY");
        }
      }

      this.setState({
        hourlist: plans,
        userTotalHours: userHours,
      });
    }

    if (
      this.state.checkAssignStatus &&
      this.props.planningReducer.succMsg &&
      this.props.planningReducer.succMsg.message
    ) {
      this.setState({ checkAssignStatus: false });
      this.getPlans();
      this.props.enqueueSnackbar(this.props.planningReducer.succMsg.message, {
        variant: "success",
      });
    } else if (
      this.state.checkAssignStatus &&
      this.props.planningReducer.errMsg &&
      this.props.planningReducer.errMsg.response
    ) {
      this.setState({ checkAssignStatus: false });
      this.props.enqueueSnackbar(
        this.props.planningReducer.errMsg.response.message,
        {
          variant: "error",
        }
      );
    }

    if (
      prevProps.startDate !== this.props.startDate &&
      prevProps.endDate !== this.props.endDate &&
      getItem("userId")
    ) {
      this.prepareWeedDays();
    }
  };

  prepareWeedDays = () => {
    let weekdays = [];
    const start = moment(moment(this.props.startDate).format("YYYY-MM-DD"));
    const end = moment(
      moment(this.props.endDate).add(1, "days").format("YYYY-MM-DD")
    );

    let iCounter = 0;
    let name = null;
    let index = null;
    let indexDate = "";
    let today = moment().locale("en").format("dddd");
    let todayDate = moment().format("DD,MM,YY");

    while (!start.isSame(end)) {
      name = start.locale("en").format("dddd");
      index = iCounter.toString();
      indexDate = moment(this.props.startDate)
        .clone()
        .add(index, "days")
        .format("DD,MM,YY");

      weekdays.push({
        name: name,
        index: index,
        dateStr: indexDate,
        isToday: name === today && indexDate === todayDate ? true : false,
      });
      start.add(1, "days");
      iCounter++;
    }

    this.setState({
      weekdays: weekdays,
    });
  };

  getPlans = async () => {
    if (this.state.userId) {
      let queryString = `limit=500&group_by=user&user_ids=${this.state.userId}&`; // once pagination is removed, remove the limit

      queryString += `start_time=${moment(this.props.startDate).format(
        "YYYY-MM-DD"
      )}&end_time=${moment(this.props.endDate).format("YYYY-MM-DD")}&`;

      queryString = queryString.slice(0, -1);

      await this.props.actions.getPlans(queryString);
    }
  };

  checkInStatus = async (planData) => {
    const hourId = planData.id;
    if (hourId && this.state.checkinPlan) {
      if (
        this.state.checkinPlan === hourId &&
        this.state.checkinStep &&
        this.state.checkinStep === "checkin"
      ) {
        setItem("planCheckInData", JSON.stringify(this.state.checkInData));
        this.props.history.push("/hours?checkout=1");
      }
    } else {
      if (hourId && !this.state.checkinPlan) {
        const currentTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");

        const postObj = {
          date: moment().format("YYYY-MM-DD"),
          start_time: currentTime + "Z",
          //user_id: parseInt(getItem("userId"), 10), // not required in backend now
          task_id: planData.task_id,
          planning_planned_hour_id: hourId,
        };

        // geo location
        if (this.state.isGeoLockRequired && this.props.coords) {
          if (this.state.isGeoFenceDisabled) {
            postObj["tablet"] = true;
          } else {
            postObj["latitude"] = this.props.coords.latitude;
            postObj["longitude"] = this.props.coords.longitude;
          }
        }

        this.props.actions.createCheckInHour(postObj);

        this.setState({
          checkinStep: "checkin",
          checkinPlan: hourId,
          checkInData: {
            start_time: currentTime + "Z",
            planning_planned_hour_id: hourId,
            project_name: planData.project_name,
            task_name: planData.task_name,
          },
          checkCheckInError: true,
        });

        // log user attendace
        if (
          this.state.attendaceModuleAssigned &&
          this.state.reportWebAttendance
        ) {
          const attendanceTime = moment()
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS");
          const attendaceObj = {
            start_time: attendanceTime + "Z",
            attendanceable_id: parseInt(getItem("userId"), 10),
            attendanceable_type: "User",
            is_utc: true,
          };

          // pass task_id if present
          if (planData.task_id) {
            attendaceObj["task_id"] = planData.task_id;
          }

          // geo location
          if (this.state.isGeoFenceForAttendace && this.props.coords) {
            if (this.state.isGeoFenceDisabled) {
              attendaceObj["tablet"] = true;
            } else {
              attendaceObj["latitude"] = this.props.coords.latitude;
              attendaceObj["longitude"] = this.props.coords.longitude;
            }
          }

          this.props.actions.postUserAttendance(attendaceObj);
          this.setState({
            checkAttendanceError: true,
          });
        }

        let timerId = setInterval(async () => {
          if (this.state.checkinPlan) {
            const timeDeference = moment().diff(moment(currentTime));
            const timeDuration = moment.duration(timeDeference);
            this.setState({
              timeDuration: timeDuration,
            });
          } else {
            clearInterval(this.state.timerId);
            this.setState({
              timeDuration: 0,
            });
          }
        }, 2000);

        this.setState({ timerId: timerId });
      }
    }
  };

  changeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.setState({ [key]: data[key] });
      }
    }
  };

  requestAssignPlan = async (planId) => {
    await this.props.actions.assignPlan(planId);
    this.setState({
      checkAssignStatus: true,
    });
  };

  render = () => {
    if (!this.state.havePermission || getItem("userRole") === "admin") {
      return <Redirect to="/" />;
    } else {
      return !authActions.getAccessToken() ? (
        <Redirect to={`/token/${authActions.getCompanyToken()}`} />
      ) : (
        <React.Fragment>
          <HoursFilters {...this.props} />
          <Table
            state={this.state}
            getPlans={this.getPlans}
            checkInStatus={this.checkInStatus}
            weekdays={this.state.weekdays}
            requestAssignPlan={this.requestAssignPlan}
            {...this.props}
          />
          {!this.props.isGeolocationAvailable &&
          (this.state.isGeoLockRequired || this.state.isGeoFenceForAttendace) &&
          !this.state.isGeoFenceDisabled ? (
            <AlertModal
              isOpen={this.state.openAlertModal}
              handleClose={() => {
                this.setState({ openAlertModal: false });
              }}
              title={"Geo Location!"}
              message={
                "Your browser does not support Geolocation. Please use another browser. The Geolocation feature is required while creating new Hours."
              }
            />
          ) : !this.props.isGeolocationEnabled &&
            (this.state.isGeoLockRequired ||
              this.state.isGeoFenceForAttendace) &&
            !this.state.isGeoFenceDisabled ? (
            <AlertModal
              isOpen={this.state.openAlertModal}
              handleClose={() => {
                this.setState({ openAlertModal: false });
              }}
              title={"Geo Location!"}
              message={
                "Please allow the app to determine your location. This is required while creating new Hours."
              }
            />
          ) : null}
          <OverlayIndicator
            isLoading={
              this.props.planningReducer.planLoading ||
              (this.state.groupBy === "user" &&
                this.props.planningReducer.userLoading &&
                !this.state.userDropdownCall)
            }
          />
        </React.Fragment>
      );
    }
  };
}

const mapStateToProps = (state) => {
  return {
    hourReducer: state.hourReducer,
    planningReducer: state.planningReducer,
    filters: state.filters,
    startDate: state.filters.startDate,
    endDate: state.filters.endDate,
    companyReducer: state.companyReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
};

const geoLockSettings = {
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(geolocated(geoLockSettings)(PlannedHours)));

import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GuestSearch from "./components/GuestSearch";
import BackToHomePage from "@/common/BackToHomePage";

import { updateGuestAttendance } from "@/services/guest";
import { getErrorObject } from "@/utils/api.config";

import moment from "moment";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
    marginTop: "-90px",
  },
  formContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const GuestCheckOut = (props) => {
  const { history } = props;

  const classes = useStyles();

  const [currentGuest, addCurrentGuest] = useState(null);
  const [checkOutError, setCheckOutError] = useState(null);
  const [checkOutDone, setCheckOutDone] = useState(false);

  const addGuestDetails = (guestDetails) => {
    addCurrentGuest(guestDetails);
  };

  const handleCheckOut = async () => {
    if (currentGuest && currentGuest.attendaceId) {
      try {
        const attendaceId = currentGuest.attendaceId;
        const attendaceObj = {
          end_time: moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
          attendanceable_id: parseInt(currentGuest.value, 10),
          attendanceable_type: "Guest",
        };

        const result = await updateGuestAttendance(attendaceId, attendaceObj);
        if (result.data) {
          setCheckOutDone(true);
          //console.log("check-out", result.data.message);
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        setCheckOutError(message);
        //console.log(message);
      }
    } else {
      setCheckOutError(i18next.t("Please select guest."));
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  if (checkOutDone) {
    return (
      <BackToHomePage
        history={history}
        isGuest={true}
        title={i18next.t("You are successfully checked-out.")}
        subHeading={i18next.t("You will be redirected to the home page")}
      />
    );
  } else {
    return (
      <div className={classes.content}>
        <div className={classes.contentHeader}>
          <IconButton onClick={handleBack} size="large">
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className={classes.contentBody}>
          <Grid container className={classes.formContainer}>
            <Grid item xl={4} lg={6} md={6} sm={10} xs={10}>
              <GuestSearch
                onGuestSelection={addGuestDetails}
                onCheckOut={handleCheckOut}
                checkOutError={checkOutError}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
};

export default GuestCheckOut;

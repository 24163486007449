import React, { useState, useEffect, Fragment, useContext } from "react";
import * as Sentry from "@sentry/browser";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Hidden, Typography } from "@mui/material";
import CheckinHour from "./components/CheckinHour";
import SelectPlannedHour from "./components/SelectPlannedHour";
import BackToHomePage from "@/common/BackToHomePage";
import AlertModal from "@/common/AlertModal";
import CheckInForAttendance from "./components/CheckInForAttendace";
import HourTypeSummary from "./components/HourTypeSummary";
import CheckInButton from "./components/CheckInButton";
import RecentCheckinTable from "./components/RecentCheckinTable";
import AutoLogoutModal from "../Hours/components/AutoLogoutModal";
import { geolocated } from "react-geolocated";
import { bindActionCreators } from "redux";
import { ActionCreators } from "@/redux/actions";
import { actions as filterActions } from "@/redux/reducers/filters";
import { connect } from "react-redux";
import AttendanceCheckInModal from "../Hours/components/AttendanceCheckInModal";
import FormCheckInModal from "../Hours/components/formCheckInModal";
import Alert from "@mui/material/Alert";

import { Context } from "@/state/Store";

import {
  postHourCheckinApi,
  postUserAttendance,
  getHourCheckinApi,
  getCompanySettings,
  getLastUserAttendance,
  getCompanyModules,
  getUserProfileData,
  getProjects,
  getTasks,
  getPlannedHours,
  getHourTypes,
  getUserSpecificDefaultData,
} from "@/services/employee";
import { getErrorObject } from "@/utils/api.config";

import moment from "moment";
import i18next from "i18next";
import { withSnackbar } from "notistack";

import { Redirect } from "react-router-dom";
import { actions as authActions } from "@/utils/auth";

import { getItem, setItem, removeItem } from "@/utils/localStorage";

import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    // alignItems: "flex-start",
    marginTop: 16,
    marginBottom: 60,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  pageContainer: {
    width: "100%",
    marginRight: 0,
    marginLeft: 0,
    display: "flex",
    justifyContent: "center",
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  verticalDivider: {
    display: "inline-block",
    border: `1px dashed ${theme.palette.divider}`,
    margin: "0px 0px 20px 0px",
  },
  infoBox: {
    paddingTop: 8,
    paddingBottom: 28,
  },
}));

const Dashboard = (props) => {
  const {
    history,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
    coords,
  } = props;

  const [state, setState] = useState({
    checkInHour: null,
    openCheckInModal: false,
    openCheckInForAttendanceModal: false,
    attendaceModuleAssigned: false,
    isCheckInForAttendance: false,
    isGeoLockRequired: false,
    selectedProject: null,
    selectedProjectOption: null,
    isLoadProjectResult: false,
    selectedTask: null,
    planningModuleAssigned: false,
    plannedHours: [],
    specificPlanUsersList: null,
    rulesModuleAssigned: false,
    isHourRulesException: false,
    isTypeSelected: false,
    hourTypeOptions: [],
    selectedHourType: null,
    continuesCheckInStartTime: null,
    isMultiProject: true,
    allowCheckIn: true,
    userId: null,
    isItemCertificationRequiered: false,
    isDependencyRequiered: false,
    isAllowToSeeOtherUsersPlans: false,
  });

  const changeState = (data) => {
    let tempObj = {};
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        tempObj = { ...tempObj, [key]: data[key] };
      }
    }
    setState((prevState) => ({ ...prevState, ...tempObj }));
  };

  const classes = useStyles();

  const [, dispatch] = useContext(Context);

  const [isLoading, setIsLoading] = useState({
    checkInHour: true,
    companySetting: true,
    companyModule: true,
  });
  const [checkInError, setCheckInError] = useState("");
  const [checkInDone, setCheckInDone] = useState(false);
  const [checkInForAttendanceOnly, setCheckInForAttendanceOnly] =
    useState(false);
  const [checkOutForAttendanceDone, setCheckOutForAttendanceDone] =
    useState(false);
  const [isGeoFenceForAttendace, setIsGeoFenceForAttendace] = useState(false);
  const [isGeoFenceDisabled, setIsGeoFenceDisabled] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(true);
  const [attendanceError, setattendanceError] = useState("");
  const [reportWebAttendance, setReportWebAttendance] = useState(true);
  const [isAttendanceReported, setIsAttendanceReported] = useState(false);
  const [isAddon, setIsAddon] = useState(false);
  const [autoLogoutModalOpen, setAutoLogoutModalOpen] = useState(false);
  const [isDeviceProjectTask, setIsDeviceProjectTask] = useState(false);
  const [deviceProjectList, setDeviceProjectList] = useState(null);
  const [deviceTaskList, setDeviceTaskList] = useState(null);
  const [noteTablet, setNoteTablet] = useState("");
  const [hideHourTypeSection, setHideHourTypeSection] = useState(false);

  const [loadedTask, setLoadedTask] = useState({});

  const onPlanCheckin = (planId, taskId) => {
    const postObj = {
      date: moment().format("YYYY-MM-DD"),
      start_time: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
      //user_id: state.userId, // not required in backend now
      task_id: taskId,
      planning_planned_hour_id: planId,
    };

    // geo location
    if (state.isGeoLockRequired && coords) {
      if (isGeoFenceDisabled) {
        postObj["tablet"] = true;
      } else {
        postObj["latitude"] = coords.latitude;
        postObj["longitude"] = coords.longitude;
      }
    }

    const attendanceTime =
      moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

    postHourCheckin(postObj, state.userId, attendanceTime);
  };

  const postHourCheckin = async (postObj, userId, attendanceTime) => {
    try {
      const response = await postHourCheckinApi(postObj);
      if (response.data) {
        if (reportWebAttendance) {
          logUserAttendance(postObj, userId, attendanceTime);
        }

        setCheckInDone(true);

        // auto logout
        setAutoLogoutModalOpen(true);
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      setCheckInError(message);
      props.enqueueSnackbar(message, { variant: "error" });
      //console.log(message);
    }
  };

  const logUserAttendance = async (postObj, userId, attendanceTime) => {
    setattendanceError("");
    let currentTime = null;
    if (state.continuesCheckInStartTime && state.isContinuesCheckIn) {
      currentTime = state.continuesCheckInStartTime;
    } else {
      currentTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
    }

    try {
      const attendaceObj = {
        start_time: attendanceTime ? attendanceTime : `${currentTime + "Z"}`,
        attendanceable_id: userId,
        attendanceable_type: "User",
        is_utc: true,
      };

      if (postObj.task_id) {
        attendaceObj["task_id"] = postObj.task_id;
      }

      // geo location
      if (isGeoFenceForAttendace && coords) {
        if (isGeoFenceDisabled) {
          attendaceObj["tablet"] = true;
        } else {
          attendaceObj["latitude"] = coords.latitude;
          attendaceObj["longitude"] = coords.longitude;
        }
      }

      const response = await postUserAttendance(attendaceObj);
      if (response.data) {
        //console.log("check-in", response.data.message);
        return true;
      }
    } catch (error) {
      let { message } = getErrorObject(error);
      setattendanceError(message);
      //console.log(message);
      props.enqueueSnackbar(message, { variant: "error" });
      return false;
    }
  };

  useEffect(() => {
    async function checkIfAttendanceReported() {
      const response = await getLastUserAttendance();
      if (response && response.data && response.data.entry) {
        if (response.data.entry.id) {
          if (response.data.status === "check-in") {
            setIsAttendanceReported(true);
            //console.log("not checked out yet");
          }

          if (response.data.status !== "not-check-in") {
            if (!checkInForAttendanceOnly) {
              changeState({ isCheckInForAttendance: true });
              setItem("checkInForAttendanceDone", true);
            }
          }
        }
      }
    }

    if (!isLoading.checkInHour && !isLoading.companySetting) {
      checkIfAttendanceReported();
    }
  }, [state.userId, checkInForAttendanceOnly, isLoading]);

  useEffect(() => {
    async function getCheckInDetails(queryString) {
      try {
        const response = await getHourCheckinApi(queryString);
        if (response.data && response.data.entries) {
          if (response.data.entries.length > 0) {
            const entries = response.data.entries;
            let data = null;
            for (let i = 0; i < entries.length; i++) {
              // skip entries where end_time value exists
              if (entries[i].end_time) {
                continue;
              }

              // show check-in hour from planning as well
              data = entries[i];
              break;
            }
            changeState({ checkInHour: data });
          } else {
            changeState({ checkInHour: null });
          }

          setIsLoading((isLoading) => ({
            ...isLoading,
            checkInHour: false,
          }));
        }
      } catch (error) {
        let { message } = getErrorObject(error);
        setIsLoading((isLoading) => ({
          ...isLoading,
          checkInHour: false,
        }));
        console.log(message);
      }
    }

    const queryString = `user_id=${state.userId}`;

    getCheckInDetails(queryString);
  }, [checkInDone, state.userId]);

  useEffect(() => {
    const checkAssignedModules = async () => {
      try {
        const response = await getCompanyModules();
        if (response.data && response.data.length > 0) {
          if (
            response.data.find((data) => {
              return data.name === "Addon";
            })
          ) {
            setIsAddon(true);
          }
          if (
            response.data.find((data) => {
              return data.name === "Attendance";
            })
          ) {
            changeState({ attendaceModuleAssigned: true });
          }
          if (
            response.data.find((data) => {
              return data.name === "Planning";
            })
          ) {
            changeState({ planningModuleAssigned: true });
          }
          if (
            response.data.find((data) => {
              return data.name === "Rules";
            })
          ) {
            changeState({ rulesModuleAssigned: true });
          }

          dispatch({ type: "SET_COMPANY_MODULES", payload: response.data });
        }
        setIsLoading((isLoading) => ({
          ...isLoading,
          companyModule: false,
        }));
      } catch (error) {
        let { message } = getErrorObject(error);
        setIsLoading((isLoading) => ({
          ...isLoading,
          companyModule: false,
        }));
        console.log(message);
      }
    };

    checkAssignedModules();
  }, [dispatch]);

  useEffect(() => {
    const setCompanySettingAccess = (setting, stateKey) => {
      let isSet = false;
      if (
        setting &&
        setting.default_user_value === "FALSE" &&
        setting.default_admin_value === "FALSE"
      ) {
        if (
          setting.company_settings_value &&
          setting.company_settings_value.values &&
          setting.company_settings_value.values.user_ids
        ) {
          let user_ids = setting.company_settings_value.values.user_ids;
          if (Array.isArray(user_ids) && user_ids.length > 0) {
            if (user_ids.indexOf(state.userId) !== -1) {
              isSet = true;
            }
          }
        }
      } else {
        if (
          setting &&
          setting.default_user_value === "TRUE" &&
          getItem("userRole") !== "admin"
        ) {
          isSet = true;
        }
        if (
          setting &&
          setting.default_admin_value === "TRUE" &&
          getItem("userRole") === "admin"
        ) {
          isSet = true;
        }
      }

      if (isSet) {
        if (stateKey === "isHourRuleExcetion") {
          changeState({ isHourRulesException: true });
        }
        if (stateKey === "isGeoLockRequired") {
          changeState({ isGeoLockRequired: true });
        }
      }
    };

    async function checkCompanySetting() {
      try {
        const settings = await getCompanySettings();
        if (settings.data && settings.data.entries) {
          if (settings.data.entries.length > 0) {
            let entries = settings.data.entries;
            if (entries && entries.length > 0) {
              for (let i = 0; i < entries.length; i++) {
                if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Use Ipad only for attendance"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setCheckInForAttendanceOnly(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name === "Hour Rules Exception"
                ) {
                  setCompanySettingAccess(entries[i], "isHourRuleExcetion");
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Enable Continuous Check-in"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    changeState({ isContinuesCheckIn: true });
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Geofence restriction for attendance"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setIsGeoFenceForAttendace(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name === "Geofence restriction"
                ) {
                  setCompanySettingAccess(entries[i], "isGeoLockRequired");
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Disable geofence on Tablet app"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setIsGeoFenceDisabled(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Separate hours from attendance"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setReportWebAttendance(false);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "On Tablet, hide 'Hour types and addons' section on the Dashboard"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    setHideHourTypeSection(true);
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Allow item requirement for check-in/add hours"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    changeState({ isItemCertificationRequiered: true });
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name === "Allow dependencies"
                ) {
                  if (entries[i].default_admin_value === "TRUE") {
                    changeState({ isDependencyRequiered: true });
                  }
                } else if (
                  entries[i].system_setting_name &&
                  entries[i].system_setting_name ===
                    "Allow users to see other users planned hours"
                ) {
                  if (getItem("userRole") === "admin") {
                    if (entries[i].default_admin_value === "TRUE") {
                      changeState({ isAllowToSeeOtherUsersPlans: true });
                    }
                  } else {
                    if (entries[i].default_user_value === "TRUE") {
                      changeState({ isAllowToSeeOtherUsersPlans: true });
                    }
                  }
                }
              }
            }
          }
        }
        setIsLoading((isLoading) => ({
          ...isLoading,
          companySetting: false,
        }));
      } catch (error) {
        let { message } = getErrorObject(error);
        setIsLoading((isLoading) => ({
          ...isLoading,
          companySetting: false,
        }));
        console.log(message);
      }
    }

    checkCompanySetting();
  }, [state.userId]);

  useEffect(() => {
    async function getInfoNoteForDashboard() {
      try {
        const response = await getUserSpecificDefaultData(state.userId);
        if (response.data) {
          if (response.data.note_tablet) {
            setNoteTablet(response.data.note_tablet);
          }
        }
      } catch (error) {
        //let { message } = getErrorObject(error);
        //console.log(message);
      }
    }

    if (state.userId) {
      getInfoNoteForDashboard();
    }
  }, [state.userId]);

  const onCheckInForAttendanceDone = () => {
    changeState({ isCheckInForAttendance: true });
  };

  const onCheckOutForAttendanceDone = () => {
    setCheckOutForAttendanceDone(true);
  };

  const onCheckInButtonClick = () => {
    if (
      state.attendaceModuleAssigned &&
      !reportWebAttendance &&
      !state.isCheckInForAttendance
    ) {
      changeState({ openCheckInForAttendanceModal: true });
    } else {
      checkInModalOpen(null);
    }
  };

  const onCheckInModalClose = () => {
    changeState({
      openCheckInModal: false,
      selectedProject: null,
      selectedTask: null,
      isTypeSelected: false,
      loadedTask: {},
    });
  };

  useEffect(() => {
    // Continuouse check-in
    const parsed = queryString.parse(props.location.search);
    if (parsed && parsed.continue && getItem("checkInStartTime")) {
      changeState({
        continuesCheckInStartTime: getItem("checkInStartTime"),
      });
      changeState({ openCheckInModal: true });
      removeItem("checkInStartTime");
    }
  }, [props.location.search]);

  const handleCheckInForAttendaceAction = () => {
    handleCancelCheckInForAttendanceModal();

    // if checked, report attendace
    if (state.isCheckInForAttendance) {
      const attendanceTime = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS");
      const postObj = {
        attendance_time: attendanceTime + "Z",
      };

      if (logUserAttendance(postObj, state.userId)) {
        changeState({ isCheckInForAttendance: true });
        setItem("checkInForAttendanceDone", true);
      }
    }

    changeState({ openCheckInModal: true });
    checkInModalOpen(state.userId);
  };

  const handleCancelCheckInForAttendanceModal = () => {
    changeState({ openCheckInForAttendanceModal: false });
  };

  useEffect(() => {
    async function getCurrentUserData() {
      const projectTaskList = localStorage.getItem("defualt_project_task_list")
        ? JSON.parse(localStorage.getItem("defualt_project_task_list"))
        : [];

      if (projectTaskList && projectTaskList.length) {
        const projectIds = [];
        const projects = [];
        const tasks = [];
        projectTaskList.forEach((node) => {
          if (projectIds.indexOf(node.project.value) === -1) {
            projectIds.push(node.project.value);
            projects.push(node.project);
            tasks[node.project.value] = [];
            tasks[node.project.value].push(node.task);
          } else {
            tasks[node.project.value].push(node.task);
          }
        });

        setIsDeviceProjectTask(true);
        setDeviceProjectList(projects);
        setDeviceTaskList(tasks);
      }
      const userId = getItem("userId") ? parseInt(getItem("userId"), 10) : null;

      changeState({ userId: userId });

      const userData = {
        id: userId,
        username: getItem("userName"),
        email: getItem("userEmail"),
        userPosition: getItem("userPosition"),
        userRole: getItem("userRole") === "admin" ? "admin" : "user",
        language: window.localStorage.getItem("language"),
        companyId: parseInt(sessionStorage.getItem("companyId"), 10),
        companyName: sessionStorage.getItem("companyName"),
      };

      // set userData to the sentry after login
      Sentry.setUser(userData);
    }

    getCurrentUserData();
  }, []);

  const checkInStatus = async (planData) => {
    setItem("dashCheckInData", JSON.stringify(planData));
    props.history.push("/hours?checkout=1");
  };

  const onModalClose = () => {
    setAutoLogoutModalOpen(false);
  };

  const checkInModalOpen = async () => {
    const response = await getUserProfileData();

    let selectedProject = null,
      selectedTask = null,
      selectedProjectOption = null;

    if (
      localStorage.getItem("default_project") &&
      JSON.parse(localStorage.getItem("default_project")) !== null
    ) {
      selectedProject = JSON.parse(
        localStorage.getItem("default_project")
      ).value.toString();
      selectedProjectOption = JSON.parse(
        localStorage.getItem("default_project")
      );
    } else {
      if (response && response.data && response.data.id) {
        selectedProject = response.data.project_id;
        selectedProjectOption = {
          value: response.data.project_id
            ? response.data.project_id.toString()
            : "",
          label: response.data.project_name ? response.data.project_name : "",
        };
      }
    }

    if (
      localStorage.getItem("default_project") &&
      JSON.parse(localStorage.getItem("default_project")) !== null
    ) {
      if (localStorage.getItem("default_task"))
        selectedTask = JSON.parse(localStorage.getItem("default_task"));
    } else {
      if (response && response.data && response.data.id) {
        selectedTask = {
          value: response.data.task_id ? response.data.task_id.toString() : "",
          label: response.data.task_name ? response.data.task_name : "",
        };
      }
    }

    changeState({
      selectedProject,
      selectedProjectOption,
      selectedTask,
      selectedHourType: null,
      plannedHours: [],
    });

    if (state.planningModuleAssigned) {
      getPlannedHoursList();
    }

    if (state.isHourRulesException) {
      getHourTypesList();
    }

    changeState({ openCheckInModal: true });
  };

  const loadProjectList = async (searchVal, options) => {
    if (isDeviceProjectTask) {
      return {
        options: deviceProjectList,
        hasMore: false,
      };
    } else {
      let offset = options && options.length ? options.length : 0;
      let queryString = `more_details=false&open=true&limit=20&offset=${offset}`;

      if (searchVal && searchVal !== "") {
        queryString += `&name=${searchVal}`;
      }

      const response = await getProjects(queryString);
      const result = await getProjectListOptions(response);
      return result;
    }
  };

  const getProjectListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        let data = [];
        for (let i = 0; i < response.data.entries.length; i++) {
          const element = response.data.entries[i];
          data.push({
            value: element.id.toString(),
            label: element.name,
          });
        }

        resolve({
          options: data,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const getTaskOptions = async (limit, offset, search) => {
    if (isDeviceProjectTask) {
      if (state.selectedProject) {
        const tasks = deviceTaskList[state.selectedProject];
        return {
          options: tasks || [],
          hasMore: false,
        };
      }
    } else {
      let queryString = `&limit=${limit}&offset=${offset}&hour_summary=false&finish=false`;

      if (state.selectedProject) {
        queryString += `&project_id=${state.selectedProject}`;
      }

      if (search && search !== "") {
        queryString += `&name=${search}`;
      }

      const response = await getTasks(queryString);
      const result = await getTaskListOptions(response);
      return result;
    }
  };

  const getTaskListOptions = (response) => {
    const promise = new Promise((resolve) => {
      if (response && response.data && response.data.entries) {
        setLoadedTask({
          taskList: {
            ...loadedTask,
            [state.selectedProject]: response.data,
          },
        });

        let data = [];
        for (let i = 0; i < response.data.entries.length; i++) {
          const element = response.data.entries[i];
          if (element.enabled) {
            data.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
        }

        resolve({
          options: data,
          hasMore: response.data.pagination.has_more_items,
        });
      }
    });

    return promise;
  };

  const getPlannedHoursList = async () => {
    if (state.userId) {
      const currentDate = moment().format("YYYY-MM-DD");
      const queryString = `group_by=user&status=0&user_ids=${state.userId}&start_time=${currentDate}&end_time=${currentDate}`;

      const response = await getPlannedHours(queryString);
      if (response.data && response.data.entries) {
        if (response.data.entries.length > 0) {
          changeState({ plannedHours: response.data.entries });
        }
      }
    }
  };

  const getSpecificPlanUser = async (taskId) => {
    const queryString = `task_id=${taskId}`;
    props.actions.getSpecificPlanUsers(queryString);
  };

  const getHourTypesList = async () => {
    if (state.userId) {
      const queryString = `/hour-types?limit=200&offset=0${
        state.userId ? `&user_id=${state.userId}` : ""
      }${
        state.isHourRulesException
          ? `&exception_types=${state.isHourRulesException}`
          : ""
      }`;

      const response = await getHourTypes(queryString);
      if (response.data && response.data.entries) {
        let entries = response.data.entries;
        let data = [];
        if (entries && entries.length > 0) {
          // Add empty
          data.push({ value: "", label: i18next.t("Select...") });

          for (let i = 0; i < entries.length; i++) {
            const element = entries[i];
            data.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
          changeState({ hourTypeOptions: data });
        } else {
          changeState({ hourTypeOptions: [] });
        }
      }
    }
  };

  const handleCheckInStart = async () => {
    let currentTime = null;
    if (state.isContinuesCheckIn && state.continuesCheckInStartTime) {
      currentTime = state.continuesCheckInStartTime;
    } else {
      currentTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
    }

    const postObj = {
      date: moment().format("YYYY-MM-DD"),
      start_time: currentTime + "Z",
      //user_id: parseInt(state.userId, 10), // not required in backend now
      task_id: parseInt(state.selectedTask.value, 10),
    };

    if (state.selectedHourType && state.selectedHourType.value) {
      postObj.hour_type_id = parseInt(state.selectedHourType.value, 10);
    }

    // geo location
    if (state.isGeoLockRequired && coords) {
      if (isGeoFenceDisabled) {
        postObj["tablet"] = true;
      } else {
        postObj["latitude"] = coords.latitude;
        postObj["longitude"] = coords.longitude;
      }
    }

    const attendanceTime =
      moment(currentTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

    postHourCheckin(postObj, state.userId, attendanceTime);
    onCheckInModalClose();
  };

  const getPlanUsers = (taskId) => {
    const queryString = `task_id=${taskId}`;
    props.actions.getSpecificPlanUsers(queryString);
  };

  if (
    checkInForAttendanceOnly &&
    (state.isCheckInForAttendance || checkOutForAttendanceDone)
  ) {
    return !authActions.getAccessToken() ? (
      <Redirect to={`/token/${authActions.getCompanyToken()}`} />
    ) : (
      <BackToHomePage
        history={history}
        isGuest={false}
        title={i18next.t(
          state.isCheckInForAttendance
            ? "You are successfully checked-in."
            : "You are successfully checked-out."
        )}
        subHeading={i18next.t(
          "You will be signed out and redirected to the home page in 3 seconds"
        )}
        dashboardOption={false}
        action={"check-in"}
        errorMsg={
          attendanceError
            ? `${i18next.t("Attendance")}: ${attendanceError}`
            : ""
        }
      />
    );
  } else {
    return !authActions.getAccessToken() ? (
      <Redirect to={`/token/${authActions.getCompanyToken()}`} />
    ) : (
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <Grid container className={classes.pageContainer}>
            <Grid item xl={6} lg={10} md={11} sm={11} xs={11}>
              {isLoading.checkInHour || isLoading.companySetting ? (
                <Typography>Loading ...</Typography>
              ) : (
                <Fragment>
                  {noteTablet && (
                    <Typography component="div" className={classes.infoBox}>
                      <Alert
                        severity="info"
                        sx={{
                          "& .MuiAlert-message": {
                            maxHeight: "200px",
                            overflowY: "auto",
                            whiteSpace: "pre-line",
                            width: "100%",
                          },
                        }}
                      >
                        {noteTablet}
                      </Alert>
                    </Typography>
                  )}
                  {checkInForAttendanceOnly ? (
                    <CheckInForAttendance
                      isCheckedIn={isAttendanceReported}
                      onCheckInForAttendanceDone={onCheckInForAttendanceDone}
                      onCheckOutForAttendanceDone={onCheckOutForAttendanceDone}
                      isGeoFenceForAttendace={isGeoFenceForAttendace}
                      coords={isGeoFenceForAttendace ? coords : null}
                      isGeoFenceDisabled={isGeoFenceDisabled}
                    />
                  ) : (
                    <Fragment>
                      {!state.checkInHour && <div>{checkInError}</div>}
                      {state.checkInHour && (
                        <Grid container spacing={1} className={classes.topRow}>
                          <Grid item sm={12} xs={11}>
                            <CheckinHour
                              history={history}
                              checkInHour={state.checkInHour}
                              checkoutAction={true}
                              checkInStatus={checkInStatus}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container className={classes.topRow}>
                        <Grid item xs={12}>
                          <CheckInButton
                            allowCheckIn={state.checkInHour ? false : true}
                            onButtonClick={onCheckInButtonClick}
                          />
                        </Grid>
                        <Grid item sm={5} xs={11}>
                          <SelectPlannedHour
                            isItemCertificationRequiered={
                              state.isItemCertificationRequiered
                            }
                            isDependencyRequiered={state.isDependencyRequiered}
                            onPlanCheckin={onPlanCheckin}
                            allowCheckIn={state.checkInHour ? false : true}
                            getPlanUsers={getPlanUsers}
                            specificPlanUsersList={
                              props.planningReducer.specificPlanUsersList
                            }
                            specificPlanUsersLoading={
                              props.planningReducer.specificPlanUsersLoading
                            }
                            state={state}
                          />
                        </Grid>
                        <Hidden smDown>
                          <div className={classes.verticalDivider}></div>
                        </Hidden>
                        <Grid item sm={6} xs={11}>
                          {!hideHourTypeSection && (
                            <HourTypeSummary
                              userId={state.userId}
                              isAddonModule={isAddon}
                            />
                          )}
                          <RecentCheckinTable userId={state.userId} />
                        </Grid>
                      </Grid>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Grid>
          </Grid>
        </div>

        <FormCheckInModal
          handleCancel={onCheckInModalClose}
          loadProjectList={loadProjectList}
          getTaskOptions={getTaskOptions}
          getSpecificPlanUsers={getSpecificPlanUser}
          specificPlanUsersList={props.planningReducer.specificPlanUsersList}
          specificPlanUsersLoading={
            props.planningReducer.specificPlanUsersLoading
          }
          handleCheckInStart={handleCheckInStart}
          changeState={changeState}
          fromDashboard={true}
          taskReducer={loadedTask}
          state={state}
        />
        <AttendanceCheckInModal
          isOpen={state.openCheckInForAttendanceModal}
          changeState={changeState}
          isCheckInForAttendance={state.isCheckInForAttendance}
          handleNext={handleCheckInForAttendaceAction}
        />
        {autoLogoutModalOpen && (
          <AutoLogoutModal
            isOpen={autoLogoutModalOpen}
            history={history}
            isCheckOut={false}
            onModalClose={onModalClose}
          />
        )}
        {(!isGeolocationAvailable || positionError) &&
        isGeoFenceForAttendace &&
        !isGeoFenceDisabled ? (
          <AlertModal
            isOpen={openAlertModal}
            handleClose={() => {
              setOpenAlertModal(false);
            }}
            title={"Geo Locatoin!"}
            message={
              "Your browser does not support Geolocation. Please use another browser. The Geolocation feature is required for attendance."
            }
          />
        ) : !isGeolocationEnabled &&
          isGeoFenceForAttendace &&
          !isGeoFenceDisabled ? (
          <AlertModal
            isOpen={openAlertModal}
            handleClose={() => {
              setOpenAlertModal(false);
            }}
            title={"Geo Locatoin!"}
            message={
              "Please allow the app to determine your location. This is required for attendance."
            }
          />
        ) : null}
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    commonReducer: state.commonReducer,
    planningReducer: state.planningReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
};

const geoLockSettings = {
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(geolocated(geoLockSettings)(Dashboard)));

import React, { Fragment } from "react";
import moment from "moment";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import TextTruncate from "react-text-truncate";
import {
  Card,
  Typography,
  Tooltip,
  Button,
  Divider,
  IconButton,
  Collapse,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CheckInCard from "../checkInCard";
import Label from "@/common/Label";
import i18next from "i18next";

import {
  isWeekStartWithMonday,
  getDateAndTimeFormat,
} from "@/utils/dateTimeFunctions";

import DecimalToHoursMin from "@/common/DecimalToHoursMin";
import { getItem } from "@/utils/localStorage";

const styles = (theme) => ({
  card: {
    color: theme.palette.text.primary,
    padding: 12,
    marginTop: 12,
    borderRadius: "0.5rem",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: theme.customShadows.hourCard,
    backgroundColor: theme.palette.background.paper,
  },
  singleHour: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  projectLine: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 14,
    marginBottom: 8,
    lineHeight: 20,
  },
  taskLine: {
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    paddingLeft: "1rem",
    lineHeight: 20,
  },
  cardDivider: {
    marginBottom: 8,
  },
  parentBorder: {
    display: "flex",
    marginBottom: 8,
  },
  borderLine: {
    width: "4px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "1rem",
  },
  taskListClass: {
    display: "block",
    width: "100%",
  },
  lableStyle: {
    fontSize: 14,
    width: "100%",
  },
  taskLabel: {
    fontSize: 14,
    width: "100%",
  },
  lunchTime: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    fontSize: 14,
    color: "#858A8D",
    marginTop: 4,
  },
  mediumText: {
    fontWeight: 500,
  },
  amount: {
    fontSize: 14,
    whiteSpace: "nowrap",
    textAlign: "end",
  },
  boldText: {
    fontWeight: 700,
  },
  cardIcon: {
    fontSize: 16,
    margin: "2px 4px 0px 0px",
  },
  taskIcon: {
    fontSize: 13,
    margin: "2px 4px 0px 10px",
  },
  button: {
    padding: 4,
    fontSize: 12,
    marginTop: 8,
    width: "100%",
  },
  playIcon: {
    marginLeft: 4,
    fontSize: 18,
  },
  hourStatus: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
    marginTop: 8,
    paddingTop: 8,
  },
  tooltip: {
    fontSize: 12,
    whiteSpace: "pre-line",
  },
  statusBar: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: 1,
    minWidth: 22,
    borderRadius: 4,
    cursor: "default",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
    padding: "4px 8px",
    fontSize: 13,
    fontWeight: "bold",
  },
  greenStatus: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.lighter}4d`,
    cursor: "pointer",
  },
  greyStatus: {
    color: theme.palette.text.secondary,
    backgroundColor: `${theme.palette.grey[300]}`,
  },
  devider: {
    marginTop: 6,
    marginBottom: 6,
  },
  startEndTime: {
    paddingLeft: 4,
    display: "flex",
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  approvalBlock: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 12,
  },
  commentLine: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flex: 1,
    alignItems: "flex-start",
    marginTop: 8,
    paddingTop: 8,
  },
  commentIcon: {
    fontSize: 14,
    margin: "6px 4px 0px 0px",
    color: "#858A8D",
  },
  truncateLine: {
    width: `100%`,
    whiteSpace: "nowrap",
    overflow: "visible !important",
  },
});

const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} size="small" edge="end" />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

class GroupViewV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      totalHour: {},
      totalAddon: {},
      totalFixedAddon: 0,
      lunchAmount: {},
      commentPerCard: {},
      expand: {},
      isAdminUser: getItem("userRole") === "admin" ? true : false,
    };
  }

  componentDidMount() {
    this.setState({ projects: this.tasksPerProject() });
  }

  componentDidUpdate(prevProps) {
    const prevState = prevProps?.state;
    const state = this.props?.state;

    if (
      prevState.hourlist.tasks !== state.hourlist.tasks ||
      prevState.addonObjlist.tasks !== state.addonObjlist.tasks ||
      prevState.fixedAddonObjlist.tasks !== state.fixedAddonObjlist.tasks
    ) {
      this.setState({ projects: this.tasksPerProject() });
    }
  }

  tasksPerProject = () => {
    const { state, day } = this.props;
    const currentTasks = {
      ...state?.hourlist?.tasks,
      ...state?.addonObjlist?.tasks,
      ...state?.fixedAddonObjlist?.tasks,
    };
    const totalHour = {};
    const totalAddon = {};
    const totalFixedAddon = {};
    const lunchAmount = {};
    const commentPerCard = {};
    const expand = {};
    const currrentDay = parseInt(day);
    const isMondayFirstDay = isWeekStartWithMonday();

    // count total hour's amount per card
    if (state?.hourlist?.tasks) {
      for (let key in state.hourlist.tasks) {
        let taskNode = state.hourlist.tasks[key];
        let dayMatch = true;
        if (!state.isGroupCrossDay) {
          if (isMondayFirstDay) {
            // week start with Monday, Sunday = 7, match with Weekday logic
            dayMatch =
              moment(taskNode.start_time).utc().isoWeekday() === currrentDay;
          } else {
            // week start with Sunday, Sunday = 0, match with Weekday logic
            dayMatch = moment(taskNode.start_time).utc().day() === currrentDay;
          }
        }

        if (dayMatch) {
          const id = taskNode.batch_group_id;
          if (Object.prototype.hasOwnProperty.call(totalHour, id)) {
            const hourAmount = totalHour[id].hour + taskNode.amount;
            totalHour[id] = { hour: hourAmount };
          } else {
            totalHour[id] = { hour: taskNode.amount };
          }

          if (
            !Object.prototype.hasOwnProperty.call(lunchAmount, id) &&
            taskNode.batch_group_lunch_start_time
          ) {
            lunchAmount[id] = {
              lunch: this.lunchTimeDiff(
                taskNode.batch_group_lunch_start_time,
                taskNode.batch_group_lunch_end_time
              ),
            };
          }

          commentPerCard[id] = {
            comment: taskNode.comment ? taskNode.comment : "",
          };

          expand[id] = { status: false };
        }
      }
      this.setState({ totalHour });
    }

    // count total addon's amount per card
    if (state?.addonObjlist?.tasks) {
      for (let key in state.addonObjlist.tasks) {
        let taskNode = state.addonObjlist.tasks[key];
        let dayMatch = true;
        if (!state.isGroupCrossDay) {
          if (isMondayFirstDay) {
            // week start with Monday, Sunday = 7, match with Weekday logic
            dayMatch =
              moment(taskNode.start_time).utc().isoWeekday() === currrentDay;
          } else {
            // week start with Sunday, Sunday = 0, match with Weekday logic
            dayMatch = moment(taskNode.start_time).utc().day() === currrentDay;
          }
        }

        if (dayMatch) {
          const id = taskNode.batch_group_id;
          if (Object.prototype.hasOwnProperty.call(totalAddon, id)) {
            const hourAmount = totalAddon[id].addon + taskNode.amount;
            totalAddon[id] = { addon: hourAmount };
          } else {
            totalAddon[id] = { addon: taskNode.amount };
          }

          if (
            !Object.prototype.hasOwnProperty.call(lunchAmount, id) &&
            taskNode.batch_group_lunch_start_time
          ) {
            lunchAmount[id] = {
              lunch: this.lunchTimeDiff(
                taskNode.batch_group_lunch_start_time,
                taskNode.batch_group_lunch_end_time
              ),
            };
          }

          if (!commentPerCard[id]) {
            commentPerCard[id] = {
              comment: taskNode.comment ? taskNode.comment : "",
            };
          }

          if (!expand[id]) {
            expand[id] = { status: false };
          }
        }
      }
      this.setState({ totalAddon });
    }

    // count total fixed addons per card
    if (state?.fixedAddonObjlist?.tasks) {
      for (let key in state.fixedAddonObjlist.tasks) {
        const taskNode = state.fixedAddonObjlist.tasks[key];
        let dayMatch = true;
        if (!state.isGroupCrossDay) {
          if (isMondayFirstDay) {
            // week start with Monday, Sunday = 7, match with Weekday logic
            dayMatch =
              moment(taskNode?.start_time).utc().isoWeekday() === currrentDay;
          } else {
            // week start with Sunday, Sunday = 0, match with Weekday logic
            dayMatch = moment(taskNode?.start_time).utc().day() === currrentDay;
          }
        }

        if (dayMatch) {
          const id = taskNode.batch_group_id;

          if (Object.prototype.hasOwnProperty.call(totalFixedAddon, id)) {
            totalFixedAddon[id] = { addon: totalFixedAddon[id].addon + 1 };
          } else {
            totalFixedAddon[id] = { addon: 1 };
          }
        }
      }

      this.setState({ totalFixedAddon });
    }

    this.setState({ lunchAmount, commentPerCard, expand });

    // get list of tasks grouped by project
    const projects =
      Object.values(currentTasks)
        .filter((task) => {
          let start_time = task.start_time;
          if (state.isGroupCrossDay) {
            if (task.batch_group_id && task.batch_group_start_time) {
              start_time = task.batch_group_start_time;
            } else if (
              task.batch_id &&
              state.rulesModuleAssigned &&
              state.isBatchEnabled &&
              task.batch_start_time
            ) {
              start_time = task.batch_start_time;
            }
          }

          let dayMatch = false;
          if (isMondayFirstDay) {
            // week start with Monday, Sunday = 7, match with Weekday logic
            dayMatch = moment(start_time).utc().isoWeekday() === currrentDay;
          } else {
            // week start with Sunday, Sunday = 0, match with Weekday logic
            dayMatch = moment(start_time).utc().day() === currrentDay;
          }

          return dayMatch;
        })
        .reduce((accumulator, currentNode) => {
          let currentValue = { ...currentNode };
          const {
            batch_group_id,
            project_id,
            project_name,
            amount,
            hourId,
            lunch_start_time,
            lunch_end_time,
            project_color,
          } = currentValue;

          const isAddon = currentValue?.addon || currentValue?.fixed_addon;

          let lunchTime = 0;
          if (currentValue?.lunch_start_time) {
            lunchTime = this.lunchTimeDiff(lunch_start_time, lunch_end_time);
          }

          // Check if group or single hour (multi project setting off).
          // In case of single hour, we do not group task by project, we display individual hour card
          const object_id = batch_group_id ? batch_group_id : hourId;
          const group = accumulator[`${project_id}${object_id}`];
          if (group?.tasks?.length) {
            let task = group.tasks.find(
              (_task) => currentValue.task_id === _task.task_id
            );

            // do not group task by project if batch group is not present (single hour)
            if (task && batch_group_id) {
              // only consider hour's amount to display in the task line in the card
              if (!isAddon) {
                task.amount += currentValue.amount;
              }
            } else {
              if (!batch_group_id) {
                currentValue["lunch_time"] = lunchTime;
              }
              // only consider hour's amount to display in the task line in the card, so make addon task amount 0
              if (isAddon) {
                currentValue.amount = 0;
              }
              group.tasks.push({ ...currentValue });
            }

            group.project_amount = group?.tasks?.reduce(
              (amount, value) => amount + value.amount,
              0
            );

            return accumulator;
          } else {
            if (!batch_group_id) {
              currentValue["lunch_time"] = lunchTime;
            }

            // only consider hour's amount to display in the task line in the card, so make addon task amount 0
            if (isAddon) {
              currentValue.amount = 0;
            }

            return {
              ...accumulator,
              [`${project_id}${object_id}`]: {
                group_id: batch_group_id,
                hour_id: object_id,
                project_id,
                project_name,
                project_amount: isAddon ? 0 : amount, // ignore addon amount in the total amount to display in the project line of the card
                project_color,
                is_fixed_addon: currentValue?.fixed_addon,
                tasks: [{ ...currentValue }],
              },
            };
          }
        }, {}) || {};

    return Object.values(projects);
  };

  handleClickCard = (groupId, taskId) => {
    const { onClickCard } = this.props;
    onClickCard(groupId, taskId);
  };

  handleExpandClick = (groupId) => {
    const expanded = this.state.expand;
    expanded[groupId].status = !expanded[groupId].status;
    this.setState({ expand: expanded });
  };

  lunchTimeDiff(startTime, endTime) {
    let diff = 0;
    if (startTime < endTime) {
      const start = moment(startTime, "HH:mm:ss");
      const end = moment(endTime, "HH:mm:ss");
      diff = end - start;
    } else {
      // cross day
      const crossFirstDay =
        moment("23:59:59", "HH:mm:ss") - moment(startTime, "HH:mm:ss");
      const crossSecondDay =
        moment(endTime, "HH:mm:ss") - moment("00:00:00", "HH:mm:ss");
      diff = crossFirstDay + crossSecondDay;
    }

    return diff;
  }

  printLunchTime(value) {
    return moment.duration(value).format(`h[${i18next.t("h")}] mm[m]`);
  }

  lunchTimeDiffPrint(startTime, endTime) {
    const lunchTime = this.lunchTimeDiff(startTime, endTime);
    return moment.duration(lunchTime).format(`h[${i18next.t("h")}] mm[m]`);
  }

  render = () => {
    const { classes, isToday, state, dayName } = this.props;
    const projects = this.state.projects || [];
    const totalHour = this.state.totalHour;
    const totalAddon = this.state.totalAddon;
    const totalFixedAddon = this.state.totalFixedAddon;
    const lunchAmount = this.state.lunchAmount;
    const commentPerCard = this.state.commentPerCard;
    const expand = this.state.expand;
    const dateTimeFormat = getDateAndTimeFormat();

    const hourGroups =
      state.hourlist.columns &&
      state.hourlist.columns[dayName] &&
      state.hourlist.columns[dayName].groupIds
        ? state.hourlist.columns[dayName].groupIds
        : [];

    const addonGroups =
      state.addonObjlist.columns &&
      state.addonObjlist.columns[dayName] &&
      state.addonObjlist.columns[dayName].groupIds
        ? state.addonObjlist.columns[dayName].groupIds
        : [];

    const fixedAddonGroups =
      state.fixedAddonObjlist.columns &&
      state.fixedAddonObjlist.columns[dayName] &&
      state.fixedAddonObjlist.columns[dayName].groupIds
        ? state.fixedAddonObjlist.columns[dayName].groupIds
        : [];

    addonGroups.forEach((group_id) => {
      if (hourGroups.indexOf(group_id) === -1) {
        hourGroups.push(group_id);
      }
    });

    fixedAddonGroups.forEach((group_id) => {
      if (hourGroups.indexOf(group_id) === -1) {
        hourGroups.push(group_id);
      }
    });

    return (
      <Fragment>
        <Typography component="div" style={{ margin: 0 }}>
          {hourGroups.map((groupId) => {
            let cardStatus = null;
            let startTime = 0;
            let endTime = 0;
            let createdAt = null;
            let approvalStatus = null;
            let approvedByName = null;

            return (
              <Card key={`group-${groupId}`} className={classes.card}>
                <Typography
                  component="div"
                  onClick={this.handleClickCard.bind({}, groupId, null)}
                >
                  {projects.map(
                    ({
                      group_id,
                      project_name,
                      project_id,
                      project_amount,
                      tasks,
                      project_color,
                      is_fixed_addon,
                    }) => {
                      if (
                        group_id !== groupId ||
                        (project_amount === 0 && !is_fixed_addon)
                      ) {
                        return null;
                      } else {
                        return (
                          <div key={`p-${project_id}`}>
                            <Typography
                              className={classes.projectLine}
                              component="div"
                              variant="h2"
                            >
                              {state.isTruncateLines ? (
                                <Tooltip title={project_name} placement="top">
                                  <Typography
                                    component="div"
                                    className={`${classes.lableStyle} ${classes.boldText}`}
                                  >
                                    <TextTruncate
                                      line={1}
                                      truncateText="…"
                                      text={project_name}
                                    />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  component="div"
                                  className={`${classes.lableStyle} ${classes.boldText}`}
                                >
                                  {project_name}
                                </Typography>
                              )}

                              <Typography
                                component="div"
                                className={`${classes.amount} ${classes.boldText}`}
                              >
                                <DecimalToHoursMin>
                                  {project_amount}
                                </DecimalToHoursMin>
                              </Typography>
                            </Typography>
                            <div className={classes.parentBorder}>
                              <div
                                className={classes.borderLine}
                                style={{
                                  backgroundColor: project_color
                                    ? `${project_color}`
                                    : null,
                                }}
                              >
                                {""}
                              </div>
                              <div className={classes.taskListClass}>
                                {tasks.map(
                                  ({
                                    task_id,
                                    task_name,
                                    amount,
                                    status,
                                    batch_group_id,
                                    batch_group_end_time,
                                    batch_group_start_time,
                                    start_time,
                                    end_time,
                                    created_at,
                                    approved_by_name,
                                  }) => {
                                    cardStatus =
                                      status === "change"
                                        ? 2
                                        : status === "approved" ||
                                            status === "exported"
                                          ? 1
                                          : null;

                                    approvedByName = approved_by_name
                                      ? approved_by_name
                                      : null;

                                    approvalStatus = status;

                                    if (batch_group_id > 0) {
                                      startTime = batch_group_start_time;
                                      endTime = batch_group_end_time;
                                    } else {
                                      startTime = start_time;
                                      endTime = end_time;
                                    }

                                    if (!createdAt) {
                                      // consider time of very first hour in a group
                                      createdAt = created_at;
                                    }

                                    if (amount === 0 && !is_fixed_addon) {
                                      return null;
                                    } else {
                                      return (
                                        <Typography
                                          component="div"
                                          key={task_id}
                                          className={classes.taskLine}
                                        >
                                          {state.isTruncateLines ? (
                                            <Tooltip
                                              title={task_name}
                                              placement="top"
                                            >
                                              <Typography
                                                component="div"
                                                className={classes.taskLabel}
                                              >
                                                <TextTruncate
                                                  line={1}
                                                  truncateText="…"
                                                  text={task_name}
                                                />
                                              </Typography>
                                            </Tooltip>
                                          ) : (
                                            <Typography
                                              component="div"
                                              className={classes.taskLabel}
                                            >
                                              {task_name}
                                            </Typography>
                                          )}
                                          <Typography
                                            component="div"
                                            className={classes.amount}
                                          >
                                            <DecimalToHoursMin>
                                              {amount}
                                            </DecimalToHoursMin>
                                          </Typography>
                                        </Typography>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                            <Divider className={classes.cardDivider} />
                          </div>
                        );
                      }
                    }
                  )}
                  {lunchAmount?.[groupId]?.lunch > 0 && (
                    <Typography component="div" className={classes.lunchTime}>
                      <LocalDiningIcon className={classes.cardIcon} />
                      <Typography
                        component="div"
                        className={`${classes.lableStyle} ${classes.boldText}`}
                      >
                        {i18next.t("Lunch time")}
                      </Typography>
                      <Typography
                        component="div"
                        className={`${classes.amount} ${classes.boldText}`}
                      >
                        <span>
                          {this.printLunchTime(lunchAmount?.[groupId]?.lunch)}
                        </span>
                      </Typography>
                    </Typography>
                  )}
                  {totalHour?.[groupId]?.hour > 0 && (
                    <Typography
                      component="div"
                      className={classes.lunchTime}
                      style={{ color: "#18B24B" }}
                    >
                      <WatchLaterIcon className={classes.cardIcon} />
                      <Typography
                        component="div"
                        className={`${classes.lableStyle} ${classes.boldText}`}
                      >
                        {i18next.t("Registered hours")}
                      </Typography>
                      <Typography
                        component="div"
                        className={`${classes.amount} ${classes.boldText}`}
                      >
                        <DecimalToHoursMin>
                          {totalHour?.[groupId]?.hour}
                        </DecimalToHoursMin>
                      </Typography>
                    </Typography>
                  )}
                  {totalAddon?.[groupId]?.addon > 0 && (
                    <Typography
                      component="div"
                      className={classes.lunchTime}
                      style={{ color: "#F2C400" }}
                    >
                      <AddToPhotosIcon className={classes.cardIcon} />
                      <Typography
                        component="div"
                        className={`${classes.lableStyle} ${classes.boldText}`}
                      >
                        {i18next.t("Registered addons")}
                      </Typography>
                      <Typography
                        component="div"
                        className={`${classes.amount} ${classes.boldText}`}
                      >
                        <DecimalToHoursMin>
                          {totalAddon?.[groupId]?.addon}
                        </DecimalToHoursMin>
                      </Typography>
                    </Typography>
                  )}
                  {totalFixedAddon?.[groupId]?.addon > 0 && (
                    <Typography
                      component="div"
                      className={classes.lunchTime}
                      style={{ color: "#F2C400" }}
                    >
                      <AddToPhotosIcon className={classes.cardIcon} />
                      <Typography
                        component="div"
                        className={`${classes.lableStyle} ${classes.boldText}`}
                      >
                        {i18next.t("Fixed addons")}
                      </Typography>
                      <Label variant="custom" hexColor="#F2C400" height="small">
                        {totalFixedAddon?.[groupId]?.addon}
                      </Label>
                    </Typography>
                  )}
                  {commentPerCard[groupId]?.comment && (
                    <Typography component="div" className={classes.commentLine}>
                      <ChatBubbleIcon className={classes.commentIcon} />
                      <Typography
                        component="div"
                        sx={{ width: `calc(100% - 36px)` }}
                      >
                        {!expand[groupId].status && (
                          <TextTruncate
                            line={1}
                            truncateText="…"
                            text={commentPerCard[groupId].comment}
                            textTruncateChild={
                              <ExpandMore
                                expand={expand[groupId].status}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleExpandClick(groupId);
                                }}
                                aria-expanded={expand[groupId].status}
                                aria-label="more"
                              >
                                <ExpandCircleDownIcon fontSize="inherit" />
                              </ExpandMore>
                            }
                            containerClassName={classes.truncateLine}
                          />
                        )}
                        <Collapse
                          in={expand[groupId].status}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Typography sx={{ overflowWrap: "break-word" }}>
                            {commentPerCard[groupId].comment}
                          </Typography>
                        </Collapse>
                      </Typography>
                      {expand[groupId].status && (
                        <ExpandMore
                          expand={expand[groupId].status}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleExpandClick(groupId);
                          }}
                          aria-expanded={expand[groupId].status}
                          aria-label="more"
                        >
                          <ExpandCircleDownIcon fontSize="inherit" />
                        </ExpandMore>
                      )}
                    </Typography>
                  )}
                  <>
                    <Typography component="div" className={classes.hourStatus}>
                      {cardStatus === 1 ? (
                        <Tooltip
                          title={
                            approvedByName
                              ? approvedByName
                              : i18next.t("No record found")
                          }
                          classes={{ tooltip: classes.tooltip }}
                          placement="top"
                        >
                          <Typography component="div">
                            <Typography
                              component="span"
                              className={`${classes.statusBar} ${classes.greenStatus}`}
                            >
                              {i18next.t("Approved")}
                            </Typography>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component="span"
                          className={`${classes.statusBar} ${classes.greyStatus}`}
                        >
                          {i18next.t("Pending")}
                        </Typography>
                      )}

                      <div style={{ flexGrow: 1 }} />

                      <Tooltip
                        title={
                          createdAt
                            ? moment(createdAt).format(dateTimeFormat) // createdAt is in server time so, utc() not applicable
                            : ""
                        }
                        placement="top"
                      >
                        <Typography
                          component="span"
                          className={classes.startEndTime}
                        >
                          {`${moment(startTime)
                            .utc()
                            .format("HH:mm")} - ${moment(endTime)
                            .utc()
                            .format("HH:mm")}`}
                        </Typography>
                      </Tooltip>
                    </Typography>
                  </>
                </Typography>

                {this.state.isAdminUser &&
                  !state.hideApproveButton &&
                  (approvalStatus === "undefined" ||
                    approvalStatus === "change") && (
                    <Typography
                      component="div"
                      className={classes.approvalBlock}
                    >
                      <Button
                        fullWidth
                        onClick={() =>
                          this.props.handleBatchGroupApprove(
                            groupId,
                            approvalStatus
                          )
                        }
                        color="primary"
                        variant="outlined"
                      >
                        {i18next.t("Approve")}
                      </Button>
                    </Typography>
                  )}
              </Card>
            );
          })}
        </Typography>

        {/* Card according to Project ... */}
        <Typography component="div" style={{ margin: 0 }}>
          {projects.map(
            (
              {
                group_id,
                hour_id,
                project_id,
                project_name,
                project_amount,
                tasks,
              },
              index
            ) =>
              !group_id ? (
                <Card
                  key={`simple-${project_id}-${index}`}
                  className={classes.card}
                  onClick={this.handleClickCard.bind({}, null, hour_id)}
                >
                  <Typography className={classes.projectLine} component="div">
                    {state.isTruncateLines ? (
                      <Tooltip title={project_name} placement="top">
                        <Typography
                          component="div"
                          className={`${classes.lableStyle} ${classes.boldText}`}
                        >
                          <TextTruncate
                            line={1}
                            truncateText="…"
                            text={project_name}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        component="div"
                        className={`${classes.lableStyle} ${classes.boldText}`}
                      >
                        {project_name}
                      </Typography>
                    )}
                    <Typography
                      component="div"
                      className={`${classes.amount} ${classes.boldText}`}
                    >
                      <DecimalToHoursMin>{project_amount}</DecimalToHoursMin>
                    </Typography>
                  </Typography>

                  {tasks.map(({ hourId, task_name, amount, lunch_time }) => (
                    <Typography
                      component="div"
                      key={hourId}
                      className={classes.singleHour}
                    >
                      <Typography component="div" className={classes.taskLine}>
                        <CheckIcon className={classes.taskIcon} />
                        {state.isTruncateLines ? (
                          <Tooltip title={task_name} placement="top">
                            <Typography
                              component="div"
                              className={classes.lableStyle}
                            >
                              <TextTruncate
                                line={1}
                                truncateText="…"
                                text={task_name}
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            component="div"
                            className={classes.lableStyle}
                          >
                            {task_name}
                          </Typography>
                        )}
                        <Typography component="div" className={classes.amount}>
                          <DecimalToHoursMin>{amount}</DecimalToHoursMin>
                        </Typography>
                      </Typography>

                      {lunch_time > 0 && (
                        <Typography
                          component="div"
                          className={classes.lunchTime}
                        >
                          <LocalDiningIcon className={classes.cardIcon} />
                          <Typography
                            component="div"
                            className={`${classes.lableStyle} ${classes.mediumText}`}
                          >
                            {i18next.t("Lunch time")}
                          </Typography>
                          <Typography
                            component="div"
                            className={`${classes.amount} ${classes.mediumText}`}
                          >
                            <span>{this.printLunchTime(lunch_time)}</span>
                          </Typography>
                        </Typography>
                      )}
                    </Typography>
                  ))}

                  {isToday &&
                    state.batchGroupCheckInID &&
                    group_id === state.batchGroupCheckInID && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.continueCheckInMultiProject();
                        }}
                      >
                        {i18next.t("Continue check-in")}
                        <PlayCircleFilledIcon className={classes.playIcon} />
                      </Button>
                    )}
                </Card>
              ) : null
          )}

          {/* Check in card ... */}
          {this.props.state.checkInData &&
            this.props.isToday &&
            this.props.isLoginUser && (
              <CheckInCard
                data={this.props.state.checkInData}
                handleCheckOut={this.props.handleCheckOut}
                isTruncateLines={state.isTruncateLines}
              />
            )}
        </Typography>
        <div style={{ marginBottom: 10 }}></div>
      </Fragment>
    );
  };
}

export default withStyles(styles)(GroupViewV2);

import React, { Component } from "react";
import i18next from "i18next";
import { ActionCreators } from "@/redux/actions";
import HoursFilters from "./components/HourFilters";
import Table from "./components/Table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as filterActions } from "@/redux/reducers/filters";
import { withSnackbar } from "notistack";
import OverlayIndicator from "@/common/OverlayIndicator";
import moment from "moment";
import FormPlanModal from "./components/formPlanModal";
import DeleteModal from "@/common/deleteModal";
import { geolocated } from "react-geolocated";
import AlertModal from "@/common/AlertModal";
import { Redirect } from "react-router-dom";

import axios from "axios";
import { actions as authActions } from "@/utils/auth";
import { isWeekStartWithMonday } from "@/utils/dateTimeFunctions";

import { getItem, setItem } from "@/utils/localStorage";

import urlQueryString from "query-string";

class Planning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: [],
      //planUserList: [],
      //planProjectList: [],
      userOptions: [],
      planList: [],
      openPlanModal: false,
      title: null,
      projectList: [],
      projectOptions: [],
      customerList: [],
      customerOptions: [],
      selectedUserOption: null,
      selectedUserFilterOption: null,
      selectedProject: null,
      selectedProjectOption: null,
      selectedProjectFilterOption: null,
      selectedCustomerOption: null,
      selectedTask: null,
      departmentList: [],
      departmentOptions: [],
      selectedDepartmentOption: null,
      startTime: "00:00",
      endTime: "00:00",
      editId: null,
      comment: "",
      checkError: false,
      dayNumber: null,
      filterCounter: 0,
      fromDate: null,
      toDate: null,
      //projectId: null,
      //departmentId: null,
      user: null,
      project: null,
      //dateChanged: false,
      //filterDateChanged: false,
      groupBy: "user",
      userTotalHours: [],
      weekDayTotalHours: [],
      openDeleteModal: false,
      weekdays: [],
      planStatus: { value: "active", label: i18next.t("Active") },
      repeat_Dates: [],
      havePermission: true,
      availability: [],
      currentUser: null,
      currentDate: null,
      currentWeek: null,
      checkAvailability: false,
      isGeoLockRequired: false,
      openAlertModal: true,
      isRepeatDate: false,
      userId: null,
      checkinStep: null,
      checkinPlan: null,
      timerId: null,
      promptLunch: false,
      isUserSearch: false,
      isProjectSearch: false,
      searchVal: "",
      userLunchTime: 0,
      adminLunchTime: 0,
      limit: 10,
      offset: 0,
      hasMoreItems: false,
      totalItems: 0,
      checkCheckInError: false,
      isContinuesCheckIn: false,
      isDepartmentFilterSetting: false,
      isDepartmentFilter: false,
      preventUserCall: false,
      preventDuplicateCall: false,
      checkAttendanceError: false,
      attendaceModuleAssigned: false,
      checkoutLogObj: null,
      checkAttendanceLog: false,
      view: "Card",
      userDropdownCall: false,
      projectDropdownCall: false,
      showAdminPlanningView: true,
      isAdminUser: getItem("userRole") === "admin" ? true : false,
      isMultiProject: false,
      isHourCheckin: false,
      isUnassignedPlans: false,
      isGeoFenceForAttendace: false,
      isGeoFenceDisabled: false,
      reportWebAttendance: true,
      isMultipleProjectInput: false,
      multipleProjectNodes: [],
      checkMultiProjectInputError: false,
      checkTaskList: false,
      checkTaskOptions: false,
      isLoadProjectResult: false,
      checkCustomerList: false,
      checkCustomerOptions: false,
      isLoadUserList: false,
      checkAssignStatus: false,
      dateRangeStartsWithToday: false,
      autofillAsHours: false,
      isItemCertificationRequiered: false,
      isDependencyRequiered: false,
      selectedRequiredItems: [],
      selectedDependencies: [],
    };
  }

  componentDidMount = async () => {
    if (getItem("userId")) {
      this.setState({ userId: parseInt(getItem("userId")) });
    }

    this.props.actions.getCompanyModules();
    this.props.actions.getCompanySettings();
    //await this.getCompanyUsers(); // the resetDefaultDates() calls the getCompanyUsers()

    // resetDefaultDates() and resetFilter(), both calls the getCompanyUsers(), so duplicate call
    this.setState({ preventDuplicateCall: true }, () => {
      this.resetDefaultDates();
      this.resetFilter(); // reset the previous filters on page load
    });

    //this.getPlans(); // the getCompanyUsers() calls the getPlans()
    this.prepareWeedDays();
    this.props.actions.getDepartments();
    //this.getProjectList(); // not required, first view is Users view

    this.lookForCheckinPlan();
  };

  lookForCheckinPlan = () => {
    const userId = getItem("userId") ? parseInt(getItem("userId"), 10) : null;

    if (userId) {
      const queryString = `user_id=${userId}`;
      this.props.actions.getCheckInHour(queryString);
    }
  };

  componentWillUnmount = () => {
    if (this.state.timerId) {
      clearInterval(this.state.timerId);
    }
  };

  resetDefaultDates = () => {
    const parsed = urlQueryString.parse(this.props.location.search);
    if (parsed && parsed.start && parsed.end) {
      this.props.filterActions.changeFilter({
        startDate: moment(parsed.start),
        endDate: moment(parsed.end),
      });
    } else {
      if (this.state.dateRangeStartsWithToday) {
        const today = moment().startOf("day");
        this.props.filterActions.changeFilter({
          startDate: today,
          endDate: moment(today).clone().add(6, "days"),
        });
      } else {
        this.props.filterActions.changeFilter({
          startDate: moment().clone().startOf("week"),
          endDate: moment().clone().endOf("week"),
        });
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.companyReducer !== this.props.companyReducer) {
      if (
        this.props.companyReducer.companyModules &&
        this.props.companyReducer.companyModules.length > 0
      ) {
        if (
          !this.props.companyReducer.companyModules.find((data) => {
            return data.name === "Planning";
          })
        ) {
          this.setState({
            havePermission: false,
          });
        }
        if (
          this.props.companyReducer.companyModules.find((data) => {
            return data.name === "Attendance";
          })
        ) {
          this.setState({
            attendaceModuleAssigned: true,
          });
        }
      }
    }
    if (prevProps.companyReducer !== this.props.companyReducer) {
      if (
        this.props.companyReducer.companySettings &&
        this.props.companyReducer.companySettings.entries &&
        this.props.companyReducer.companySettings.entries !==
          prevProps.companyReducer.companySettings.entries &&
        this.props.companyReducer.companySettings.entries.length > 0
      ) {
        let entries = this.props.companyReducer.companySettings.entries;
        if (entries && entries.length > 0) {
          for (let i = 0; i < entries.length; i++) {
            if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Geofence restriction"
            ) {
              if (
                entries[i].default_admin_value === "TRUE" &&
                getItem("userRole") === "admin"
              ) {
                this.setState({ isGeoLockRequired: true });
              } else if (
                entries[i].default_user_value === "TRUE" &&
                getItem("userRole") !== "admin"
              ) {
                this.setState({ isGeoLockRequired: true });
              } else if (
                entries[i].company_settings_value &&
                entries[i].company_settings_value.values &&
                entries[i].company_settings_value.values.user_ids
              ) {
                let user_ids =
                  entries[i].company_settings_value.values.user_ids;
                if (Array.isArray(user_ids) && user_ids.length > 0) {
                  if (user_ids.indexOf(this.state.userId) !== -1) {
                    this.setState({ isGeoLockRequired: true });
                  }
                }
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Standard Lunch Time"
            ) {
              this.setState({
                userLunchTime:
                  parseFloat(entries[i].default_user_value) > 0
                    ? parseFloat(entries[i].default_user_value)
                    : 0,
                adminLunchTime:
                  parseFloat(entries[i].default_admin_value) > 0
                    ? parseFloat(entries[i].default_admin_value)
                    : 0,
              });
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Enable Continuous Check-in"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isContinuesCheckIn: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Only show users that belongs to selected department"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isDepartmentFilterSetting: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Enable multiple projects select"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ isMultiProject: true });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Allow users to see other users planned hours"
            ) {
              if (
                getItem("userRole") === "user" &&
                entries[i].default_user_value !== "TRUE"
              ) {
                this.setState({ showAdminPlanningView: false });
              } else if (
                entries[i].company_settings_value &&
                entries[i].company_settings_value.values &&
                entries[i].company_settings_value.values.user_ids
              ) {
                let user_ids =
                  entries[i].company_settings_value.values.user_ids;
                if (Array.isArray(user_ids) && user_ids.length > 0) {
                  if (user_ids.indexOf(this.state.userId) === -1) {
                    // if current user not in the selected list and it is normal user then, do not show the admin view
                    if (getItem("userRole") === "user") {
                      this.setState({ showAdminPlanningView: false });
                    }
                  }
                }
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Geofence restriction for attendance"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  isGeoFenceForAttendace: true,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Disable geofence on Tablet app"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  isGeoFenceDisabled: true,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Separate hours from attendance"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({
                  reportWebAttendance: false,
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Show date from current date and one week forward"
            ) {
              if (entries[i].default_admin_value === "TRUE") {
                this.setState({ dateRangeStartsWithToday: true }, () => {
                  this.resetDefaultDates();
                });
              }
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name ===
                "Allow item requirement for check-in/add hours" &&
              entries[i].default_admin_value === "TRUE"
            ) {
              this.setState({ isItemCertificationRequiered: true });
            } else if (
              entries[i].system_setting_name &&
              entries[i].system_setting_name === "Allow dependencies" &&
              entries[i].default_admin_value === "TRUE"
            ) {
              this.setState({ isDependencyRequiered: true });
            }
          }
        }
      }
    }
    if (prevProps.planningReducer !== this.props.planningReducer) {
      // for user list dropdown
      if (
        this.state.userDropdownCall &&
        this.props.planningReducer.userList &&
        prevProps.planningReducer.userList !==
          this.props.planningReducer.userList
      ) {
        this.setState({ isLoadUserList: true });
      }

      if (
        this.props.planningReducer.userList &&
        this.props.planningReducer.userList.entries &&
        prevProps.planningReducer.userList.entries !==
          this.props.planningReducer.userList.entries
      ) {
        if (this.state.groupBy === "user" && !this.state.userDropdownCall) {
          let userList =
            this.props.planningReducer.userList.entries.length > 0
              ? this.props.planningReducer.userList.entries
              : [];

          this.setState(
            {
              userList: userList,
              //planUserList: planUserList.length > 0 ? planUserList : userList
            },
            () => {
              this.getPlans();
            }
          );

          if (this.props.planningReducer.userList.pagination) {
            this.setState({
              hasMoreItems:
                this.props.planningReducer.userList.pagination.has_more_items,
              totalItems:
                this.props.planningReducer.userList.pagination.total_items,
              offset: this.props.planningReducer.userList.pagination.offset,
            });
          }
        }
        this.setState({ userDropdownCall: false });
      }

      if (
        this.props.planningReducer.planList &&
        prevProps.planningReducer.planList !==
          this.props.planningReducer.planList &&
        this.props.planningReducer.planList.entries
      ) {
        let plans = this.props.planningReducer.planList.entries;
        let userHours = [];
        let weekDayHours = [];
        let dayDiff = 0;
        let isUnassignedPlansPresent = false;

        const dateToCompare = moment(this.props.startDate).startOf("day");

        for (let ID in plans) {
          userHours[ID] = 0;
          for (let i = 0; i < plans[ID].length; i++) {
            if (!plans[ID][i].user_id) {
              plans[ID][i].user_name = i18next.t("Unassigned");
              isUnassignedPlansPresent = true;
            }

            userHours[ID] += parseFloat(plans[ID][i].amount);

            // Add formated start date key for comparison in the PlanCard component
            plans[ID][i]["start_date_str"] = moment(plans[ID][i].start_time)
              .utc()
              .format("DD,MM,YY");

            let startDateToCompare = moment(plans[ID][i].start_time)
              .utc()
              .startOf("day");

            dayDiff = moment(startDateToCompare).diff(
              moment(dateToCompare),
              "days"
            );

            if (weekDayHours[dayDiff]) {
              weekDayHours[dayDiff] += parseFloat(plans[ID][i].amount);
            } else {
              weekDayHours[dayDiff] = parseFloat(plans[ID][i].amount);
            }
          }
        }

        this.setState({
          planList: plans,
          isUnassignedPlans: isUnassignedPlansPresent,
          userTotalHours: userHours,
          weekDayTotalHours: weekDayHours,
        });
      }

      if (
        this.state.checkError &&
        this.props.planningReducer.succMsg &&
        this.props.planningReducer.succMsg.message
      ) {
        this.setState({ checkError: false });
        //this.getPlans(); // N/A, due to repeate dates call, this will fire multiple times
        this.props.enqueueSnackbar(this.props.planningReducer.succMsg.message, {
          variant: "success",
        });
      } else if (
        this.state.checkError &&
        this.props.planningReducer.errMsg &&
        this.props.planningReducer.errMsg.response
      ) {
        this.setState({ checkError: false });
        this.props.enqueueSnackbar(
          this.props.planningReducer.errMsg.response.message,
          {
            variant: "error",
          }
        );
      }

      if (
        this.state.checkAssignStatus &&
        this.props.planningReducer.succMsg &&
        this.props.planningReducer.succMsg.message
      ) {
        this.setState({ checkAssignStatus: false });
        this.getPlans();
        this.props.enqueueSnackbar(this.props.planningReducer.succMsg.message, {
          variant: "success",
        });
      } else if (
        this.state.checkAssignStatus &&
        this.props.planningReducer.errMsg &&
        this.props.planningReducer.errMsg.response
      ) {
        this.setState({ checkError: false });
        this.props.enqueueSnackbar(
          this.props.planningReducer.errMsg.response.message,
          {
            variant: "error",
          }
        );
      }

      // handle create plans post request with mulitple project input
      if (
        this.state.checkMultiProjectInputError &&
        this.props.planningReducer.errMsg &&
        this.props.planningReducer.errMsg.response &&
        this.props.planningReducer.errMsg.response.errors
      ) {
        this.setState({ checkMultiProjectInputError: false });
        this.props.enqueueSnackbar(
          this.props.planningReducer.errMsg.response.errors,
          { variant: "error" }
        );
      }
      if (
        this.state.checkMultiProjectInputError &&
        this.props.planningReducer.succMsg &&
        this.props.planningReducer.succMsg.ids
      ) {
        this.setState({ checkMultiProjectInputError: false });
        if (
          this.props.planningReducer.succMsg.errors &&
          this.props.planningReducer.succMsg.errors.length > 0
        ) {
          this.props.enqueueSnackbar(
            this.props.planningReducer.succMsg.errors,
            { variant: "error" }
          );
        }
      }

      if (this.state.checkAvailability) {
        if (this.props.planningReducer.availability) {
          const data = this.props.planningReducer.availability;
          let availability = this.state.availability;
          const hourObj = {
            date: this.state.currentDate,
            daily: data.daily ? data.daily : 0,
            weekly: data.weekly ? data.weekly : 0,
          };

          if (availability[this.state.currentUser]) {
            availability[this.state.currentUser].push(hourObj);
          } else {
            availability[this.state.currentUser] = [];
            availability[this.state.currentUser].push(hourObj);
          }

          await this.setState({
            availability: availability,
            checkAvailability: false,
          });
        } else if (
          this.props.planningReducer.error &&
          this.props.planningReducer.error.response
        ) {
          await this.setState({ checkAvailability: false });
          this.props.enqueueSnackbar(
            this.props.planningReducer.error.response.message,
            {
              variant: "error",
            }
          );
        }
      }
    }

    if (
      this.state.checkTaskList &&
      this.props.taskReducer &&
      this.props.taskReducer.taskList &&
      prevProps.taskReducer.taskList !== this.props.taskReducer.taskList
    ) {
      this.setState({
        checkTaskList: false,
        checkTaskOptions: true,
      });
    }

    if (prevProps.taskReducer.newTask !== this.props.taskReducer.newTask) {
      if (
        this.state.selectedProject &&
        this.props.taskReducer &&
        this.props.taskReducer.newTask &&
        this.props.taskReducer.newTask.id
      ) {
        if (this.state.selectedTask && this.state.selectedTask.__isNew__) {
          this.setState({
            selectedTask: {
              value: this.props.taskReducer.newTask.id.toString(),
              label: this.state.selectedTask.label,
            },
          });
        }
      }
    }

    if (
      prevProps.taskReducer.taskError !== this.props.taskReducer.taskError &&
      this.state.checkError
    ) {
      if (
        this.props.taskReducer.taskError &&
        this.props.taskReducer.taskError.response &&
        this.props.taskReducer.taskError.response.message
      ) {
        this.setState({ checkError: false });
        this.props.enqueueSnackbar(
          this.props.taskReducer.taskError.response.message,
          { variant: "error" }
        );
      }
    }

    if (prevProps.projectReducer !== this.props.projectReducer) {
      if (
        !this.state.projectDropdownCall &&
        this.props.projectReducer.projectList &&
        prevProps.projectReducer.projectList !==
          this.props.projectReducer.projectList &&
        this.props.projectReducer.projectList.entries
      ) {
        const projectList =
          this.props.projectReducer.projectList.entries.length > 0
            ? this.props.projectReducer.projectList.entries
            : [];

        if (this.state.groupBy === "project") {
          this.setState(
            {
              projectList: projectList,
            },
            () => {
              this.getPlans();
            }
          );

          if (this.props.projectReducer.projectList.pagination) {
            this.setState({
              hasMoreItems:
                this.props.projectReducer.projectList.pagination.has_more_items,
              totalItems:
                this.props.projectReducer.projectList.pagination.total_items,
              offset: this.props.projectReducer.projectList.pagination.offset,
            });
          }
        } else {
          this.setState({
            projectList: projectList,
          });
        }
      }
    }
    if (prevProps.customerReducer !== this.props.customerReducer) {
      if (
        this.props.customerReducer.customerList &&
        this.props.customerReducer.customerList.entries &&
        this.props.customerReducer.customerList.entries.length > 0
      ) {
        this.setState({
          customerList: this.props.customerReducer.customerList.entries,
        });

        if (
          this.state.selectedCustomerOption &&
          this.state.selectedCustomerOption.__isNew__
        ) {
          let newSelectedOption =
            this.props.customerReducer.customerList.entries.find((entry) => {
              return entry.name === this.state.selectedCustomerOption.label;
            });
          if (
            newSelectedOption &&
            newSelectedOption.id &&
            newSelectedOption.name
          ) {
            this.setState({
              selectedCustomerOption: {
                value: newSelectedOption.id.toString(),
                label: newSelectedOption.name,
              },
            });
            this.addProject(
              this.state.selectedProjectOption.label,
              newSelectedOption.id
            );
          }
        }

        let data = this.state.customerOptions;
        for (
          let i = 0;
          i < this.props.customerReducer.customerList.entries.length;
          i++
        ) {
          const element = this.props.customerReducer.customerList.entries[i];
          if (
            !this.state.customerOptions.find((entry) => {
              return entry.label === element.name;
            })
          ) {
            data.push({
              value: element.id.toString(),
              label: element.name,
            });
          }
        }
        this.setState({ customerOptions: data });
      }

      // In case of creating a new customer, update the id in the listbox
      if (
        (prevProps.customerReducer &&
          !prevProps.customerReducer.newCustomer &&
          this.props.customerReducer &&
          this.props.customerReducer.newCustomer &&
          this.props.customerReducer.newCustomer.id) ||
        (prevProps.customerReducer &&
          prevProps.customerReducer.newCustomer &&
          this.props.customerReducer.newCustomer &&
          this.props.customerReducer.newCustomer.id &&
          prevProps.customerReducer.newCustomer.id !==
            this.props.customerReducer.newCustomer.id)
      ) {
        this.setState({
          selectedCustomerOption: {
            value: this.props.customerReducer.newCustomer.id.toString(),
            label: this.state.selectedCustomerOption.label,
          },
        });

        this.addProject(
          this.state.selectedProjectOption.label,
          this.props.customerReducer.newCustomer.id
        );
      }
    }

    if (prevProps.departmentReducer !== this.props.departmentReducer) {
      if (
        this.props.departmentReducer.departmentList &&
        this.props.departmentReducer.departmentList.entries &&
        this.props.departmentReducer.departmentList.entries.length > 0
      ) {
        this.setState({
          departmentList: this.props.departmentReducer.departmentList.entries,
        });

        this.setState({
          departmentOptions:
            this.props.departmentReducer.departmentList.entries.map((list) => {
              return {
                value: list.id.toString(),
                label: list.name,
              };
            }),
        });
      }
    }

    if (
      this.props.hourReducer &&
      this.props.hourReducer.checkInHour &&
      this.props.hourReducer.checkInHour.entries &&
      this.props.hourReducer.checkInHour.entries !==
        prevProps.hourReducer.checkInHour.entries
    ) {
      const entries = this.props.hourReducer.checkInHour.entries;

      let data = null;
      for (let i = 0; i < entries.length; i++) {
        // skip entries where end_time value exists
        if (entries[i].end_time) {
          continue;
        }

        // prevent check-in of the Planned hours
        if (entries[i].planning_planned_hour_id) {
          data = entries[i];
          this.setState({ isHourCheckin: false });
          break;
        } else {
          this.setState({ isHourCheckin: true });
          break;
        }
      }

      if (data) {
        this.setState({
          checkInData: data,
          checkinStep: "checkin",
          checkinPlan: parseInt(data.planning_planned_hour_id),
        });
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkCheckInError
    ) {
      if (
        this.props.hourReducer.checkInError &&
        this.props.hourReducer.checkInError.response &&
        this.props.hourReducer.checkInError.response.message
      ) {
        this.setState({
          checkCheckInError: false,
          checkinStep: null,
          checkinPlan: null,
          checkInData: null,
        });
        this.props.enqueueSnackbar(
          this.props.hourReducer.checkInError.response.message,
          { variant: "error" }
        );
      }
      if (
        this.props.hourReducer.createCheckInSuccess &&
        this.props.hourReducer.createCheckInSuccess.id
      ) {
        let checkInDataObj = this.state.checkInData;
        checkInDataObj["id"] = this.props.hourReducer.createCheckInSuccess.id;

        this.setState({
          checkCheckInError: false,
          checkInData: checkInDataObj,
        });
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkAttendanceError
    ) {
      this.setState({ checkAttendanceError: false });
      if (
        this.props.hourReducer.attendanceError &&
        this.props.hourReducer.attendanceError.response &&
        this.props.hourReducer.attendanceError.response.message
      ) {
        this.props.enqueueSnackbar(
          this.props.hourReducer.attendanceError.response.message,
          { variant: "error" }
        );
      } else {
        // call the get call to update status on Side menu bar
        this.props.actions.getLastUserAttendance();
      }
    }

    if (
      prevProps.hourReducer !== this.props.hourReducer &&
      this.state.checkAttendanceLog
    ) {
      if (
        this.props.hourReducer.lastUserAttendance &&
        this.props.hourReducer.lastUserAttendance.entry
      ) {
        this.setState({ checkAttendanceLog: false });
        if (this.props.hourReducer.lastUserAttendance.entry.id) {
          const attendaceId =
            this.props.hourReducer.lastUserAttendance.entry.id;
          if (
            attendaceId &&
            this.state.checkoutLogObj &&
            this.state.reportWebAttendance
          ) {
            this.props.actions.updateUserAttendance(
              attendaceId,
              this.state.checkoutLogObj
            );
            this.setState({
              checkoutLogObj: null,
              checkAttendanceError: true,
            });
          }
        }
      }
    }

    if (prevProps.commonReducer.filters !== this.props.commonReducer.filters) {
      this.checkFilter();
    }

    if (
      prevProps.startDate !== this.props.startDate &&
      prevProps.endDate !== this.props.endDate &&
      getItem("userId")
    ) {
      if (this.state.view === "Card") {
        this.prepareWeedDays();
      }

      //this.getPlans();
      if (this.state.groupBy === "user") {
        // with department filter, the checkFilter() calls the getCompanyUsers() so, prevent another call
        if (!this.state.preventUserCall && !this.state.preventDuplicateCall) {
          this.getCompanyUsers();
        }

        if (this.state.preventDuplicateCall) {
          // reset the flag
          this.setState({ preventDuplicateCall: false });
        }
      } else {
        this.getProjectList();
      }
    }

    if (prevState.groupBy !== this.state.groupBy) {
      this.setState({ searchVal: "" });
      /*if (this.state.groupBy === "user") {
        this.getCompanyUsers();
      } else {
        this.getProjectList();
      }*/

      this.resetFilter();

      //this.getPlans(); // the resetFilter action calls the this.getPlans()
    }

    if (
      this.state.projectDropdownCall &&
      this.props.projectReducer.projectList &&
      this.props.projectReducer.projectList !==
        prevProps.projectReducer.projectList &&
      this.props.projectReducer.projectList.entries
    ) {
      this.setState({ isLoadProjectResult: true, projectDropdownCall: false });
    }

    if (
      this.state.checkCustomerList &&
      this.props.customerReducer &&
      this.props.customerReducer.customerList &&
      prevProps.customerReducer.customerList !==
        this.props.customerReducer.customerList
    ) {
      this.setState({
        checkCustomerList: false,
        checkCustomerOptions: true,
      });
    }
  };

  prepareWeedDays = () => {
    let weekdays = [];
    const start = moment(moment(this.props.startDate).format("YYYY-MM-DD"));
    const end = moment(
      moment(this.props.endDate).add(1, "days").format("YYYY-MM-DD")
    );

    let iCounter = 0;
    let name = null;
    let index = null;
    let indexDate = "";
    let today = moment().locale("en").format("dddd");
    let todayDate = moment().format("DD,MM,YY");

    while (!start.isSame(end)) {
      name = start.locale("en").format("dddd");
      index = iCounter.toString();
      indexDate = moment(this.props.startDate)
        .clone()
        .add(index, "days")
        .format("DD,MM,YY");

      weekdays.push({
        name: name,
        index: index,
        dateStr: indexDate,
        isToday: name === today && indexDate === todayDate ? true : false,
      });
      start.add(1, "days");
      iCounter++;
    }

    this.setState({
      weekdays: weekdays,
    });
  };

  checkFilter = () => {
    if (this.props.commonReducer && this.props.commonReducer.filters) {
      let isDepartmentFilterFlag = false;
      if (
        this.props.commonReducer.filters.planning &&
        this.props.commonReducer.filters.planning.departmentIds &&
        this.props.commonReducer.filters.planning.departmentIds !== undefined
      ) {
        // if department filter change, get updated user's list passing the filter, this will call the getPlans() as well.
        if (
          this.state.isDepartmentFilterSetting &&
          this.state.groupBy === "user"
        ) {
          isDepartmentFilterFlag = true;
          this.setState({ isDepartmentFilter: true }, () =>
            this.getCompanyUsers()
          );
        }
      } else {
        this.setState({ isDepartmentFilter: false });
      }

      if (
        this.state.view === "Card" &&
        this.props.commonReducer.filters.planning &&
        this.props.commonReducer.filters.planning.fromDate &&
        this.props.commonReducer.filters.planning.fromDate !== "" &&
        this.props.commonReducer.filters.planning.fromDate !== undefined &&
        this.props.commonReducer.filters.planning.toDate &&
        this.props.commonReducer.filters.planning.toDate !== "" &&
        this.props.commonReducer.filters.planning.toDate !== undefined
      ) {
        // if getCompanyUsers() already called with department filter, prevent another call with date change
        this.setState({
          preventUserCall: isDepartmentFilterFlag ? true : false,
        });

        this.props.filterActions.changeFilter({
          startDate: moment(
            this.props.commonReducer.filters.planning.fromDate
          ).clone(),
          endDate: moment(
            this.props.commonReducer.filters.planning.toDate
          ).clone(),
        });

        // in case of date filter, the startDate/endstartDateDate change calls the getPlans() function
      } else {
        // no startDate/endDate change so, call the getPlans(), only if department filter is not set as it calls the getPlans()
        if (!isDepartmentFilterFlag) {
          if (this.state.groupBy === "user") {
            this.getCompanyUsers();
          } else {
            this.getProjectList();
          }
        }
      }
    }
  };

  getPlans = async () => {
    let queryString = "limit=500&"; // once pagination is removed, remove the limit
    let totalFilters = 0;

    if (this.state.groupBy) {
      queryString += `group_by=${this.state.groupBy}&`;
    }

    let ids = [];
    if (this.state.groupBy === "user") {
      ids = this.state.userList.map((user) => {
        return user.id;
      });
    } else {
      ids = this.state.projectList.map((project) => {
        return project.id;
      });
    }
    if (
      this.props.commonReducer.filters.planning &&
      this.props.commonReducer.filters.planning.departmentIds &&
      this.props.commonReducer.filters.planning.departmentIds !== undefined
    ) {
      let departmentIds =
        this.props.commonReducer.filters.planning.departmentIds;

      if (Array.isArray(departmentIds) && departmentIds.length > 0) {
        queryString += `department_id=${departmentIds.join(",")}&`;
        totalFilters++;
      }
    }
    if (
      this.props.commonReducer.filters.planning &&
      this.props.commonReducer.filters.planning.projectIds &&
      this.props.commonReducer.filters.planning.projectIds !== undefined
    ) {
      let projectIds = this.props.commonReducer.filters.planning.projectIds;

      if (Array.isArray(projectIds) && projectIds.length > 0) {
        queryString += `project_ids=${projectIds.join(",")}&`;
        totalFilters++;
      }
    }
    if (
      this.props.commonReducer.filters.planning &&
      this.props.commonReducer.filters.planning.userId &&
      this.props.commonReducer.filters.planning.userId !== undefined
    ) {
      queryString += `user_ids=${this.props.commonReducer.filters.planning.userId}&`;
      totalFilters++;
    }
    if (
      this.props.commonReducer.filters.planning &&
      this.props.commonReducer.filters.planning.fromDate &&
      this.props.commonReducer.filters.planning.fromDate !== "" &&
      this.props.commonReducer.filters.planning.fromDate !== undefined &&
      this.props.commonReducer.filters.planning.toDate &&
      this.props.commonReducer.filters.planning.toDate !== "" &&
      this.props.commonReducer.filters.planning.toDate !== undefined
    ) {
      queryString += `start_time=${moment(
        this.props.commonReducer.filters.planning.fromDate
      ).format("YYYY-MM-DD")}&end_time=${moment(
        this.props.commonReducer.filters.planning.toDate
      ).format("YYYY-MM-DD")}&`;

      totalFilters++;
    } else {
      queryString += `start_time=${moment(this.props.startDate).format(
        "YYYY-MM-DD"
      )}&end_time=${moment(this.props.endDate).format("YYYY-MM-DD")}&`;
    }

    //if (ids.length > 0) { // get plans even if the user ids are empty, to get updated Unassigned user plans with filter
    if (this.state.groupBy === "user") {
      queryString += `user_ids=${ids.join(",")}`;
    } else {
      queryString += `project_ids=${ids.join(",")}`;
    }

    if (totalFilters) {
      this.setState({ filterCounter: totalFilters });
    }
    await this.props.actions.getPlans(queryString);
    //}
  };

  handleCancel = () => {
    this.setState({
      openPlanModal: false,
      editId: null,
      //startTime: "00:00", // Don't reset, Rune wants to show the last time
      //endTime: "00:00",
      repeat_Dates: [],
      promptLunch: false,
      isMultipleProjectInput: false,
      multipleProjectNodes: [],
      autofillAsHours: false,
      selectedRequiredItems: [],
      selectedDependencies: [],
    });
  };

  formModalOpen = (title, id, dayNumber, user, project, plan) => {
    if (!this.state.isAdminUser) return;
    let data = this.props.profileReducer.userData;
    let selectedProject = null,
      selectedTask = null,
      selectedProjectOption = null;

    if (data.project_id && this.state.groupBy === "user") {
      selectedProject = data.project_id;
      selectedProjectOption = {
        value: data.project_id.toString(),
        label: data.project_name,
      };
    }

    if (data.task_id) {
      selectedTask = {
        value: data.task_id.toString(),
        label: data.task_name,
      };
    }

    this.setState({
      selectedCustomerOption: null,
      selectedUserOption: null,
      comment: "",
      planStatus: { value: "active", label: i18next.t("Active") },
      //startTime: "00:00", // Don't reset, Rune wants to show the last time
      //endTime: "00:00",
      editId: null,
      promptLunch: false,
      autofillAsHours: false,
    });
    if (id) {
      // Prepare inputs for Edit mode
      this.setState({
        selectedProject: plan.project_id,
        selectedProjectOption: {
          value: plan.project_id.toString(),
          label: plan.project_name,
          descriptoin: i18next.t("Loading..."),
        },
        selectedUserOption: plan.user_id && {
          value: plan.user_id.toString(),
          label: plan.user_name,
        },
        selectedTask: {
          value: plan.task_id.toString(),
          label: plan.task_name,
        },
        comment: plan.comment ? plan.comment : "",
        planStatus:
          plan.status === "active"
            ? { value: "active", label: i18next.t("Active") }
            : { value: "inactive", label: i18next.t("Inactive") },
        startTime: moment(plan.start_time).utc().format("HH:mm"),
        endTime: moment(plan.end_time).utc().format("HH:mm"),
        editId: id,
        promptLunch: plan.lunch ? true : false,
        autofillAsHours: plan.autofill_as_hours ? true : false,
      });

      // for Edit modal only, for default selected project
      this.getProjectDescription(plan.project_id);
    }
    if (!id && this.state.groupBy === "project" && project) {
      this.setState({
        selectedProject: project.id ? project.id : null,
        selectedProjectOption: {
          value: project.id.toString(),
          label: project.name,
        },
        selectedTask: null,
      });
    }
    if (!id && this.state.groupBy === "user" && user) {
      this.setState({
        selectedProject,
        selectedProjectOption,
        selectedTask,
        selectedUserOption: {
          value: user.id.toString(),
          label: user.name,
          isDisabled: true,
        },
      });
    }
    // for Unassigned plans, set Start/End time as 07:00/15:00 by default
    if (!id && this.state.groupBy === "user" && !user) {
      this.setState({
        startTime: "07:00",
        endTime: "15:00",
      });
    }
    this.setState({
      openPlanModal: true,
      title,
      dayNumber: parseInt(dayNumber, 10),
      user,
      project,
      availability: [],
    });

    // item/dependency
    if (this.state.isItemCertificationRequiered) {
      const items = plan?.planning_planned_hours_items?.map((item) => ({
        value: item.id,
        label: item.item_type_name ? item.item_type_name : "",
        amount: item.amount ? parseFloat(item.amount) : 0,
        itemId: item.id,
        itemTypeId: item.item_type_id ? item.item_type_id : "",
        createdBy: item.created_by ? item.created_by : "",
        updatedBy: item.updated_by ? item.updated_by : "",
        required: item.required,
      }));

      this.setState({ selectedRequiredItems: items ? items : [] });
    }

    if (this.state.isDependencyRequiered) {
      const dependencyData = plan?.planning_planned_hours_dependencies?.map(
        (el) => ({
          dependencyType: el.dependency_type,
          depedencyName:
            el.dependency_type === "task" ? el.task_name : el.project_name,
          projectId: el.project_id ? el.project_id : null,
          taskId: el.dependency_type === "task" ? el.task_id : null,
        })
      );

      this.setState({
        selectedDependencies: dependencyData ? dependencyData : [],
      });
    }

    const user_id =
      user && user.id ? user.id : id && plan.user_id ? plan.user_id : null;

    this.getAvailability(dayNumber, user_id);
  };

  getProjectDescription = async (project_id) => {
    const apiBaseUrl = import.meta.env.VITE_API_V1_URL;

    const fullUrl = apiBaseUrl + `/project/${project_id}`;

    axios
      .get(fullUrl, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authActions.getAccessToken()}`,
        },
      })
      .then((response) => {
        if (response && response.data) {
          const projDesc = response.data.description
            ? response.data.description
            : "";
          const selectedProjectOption = this.state.selectedProjectOption;
          selectedProjectOption["description"] = projDesc;
          this.setState({
            selectedProjectOption: selectedProjectOption,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAvailability = async (dayNumber, userId) => {
    const day = moment(this.props.startDate)
      .clone()
      .add(dayNumber ? parseInt(dayNumber, 10) : 0, "days")
      .format("YYYY-MM-DDTHH:mm:ss");

    // by default moment consider Monday as first day of week, to adjust it for non-nb, add 1 day
    const week = !isWeekStartWithMonday()
      ? moment(day).clone().add(1, "day").isoWeek()
      : moment(day).clone().isoWeek();

    if (userId) {
      await this.props.actions.getAvailability(userId, day, week);
      await this.setState({
        checkAvailability: true,
        currentDate: moment(day).format("YYYY-MM-DD"),
        currentUser: userId,
      });
    } else {
      this.setState({
        currentDate: moment(day).format("YYYY-MM-DD"),
        currentUser: userId,
      });
    }
  };

  removeDateFromAvailability = async (date) => {
    if (this.state.groupBy !== "project") {
      if (this.state.availability[this.state.currentUser]) {
        let availabilityData = this.state.availability;
        let userData = this.state.availability[this.state.currentUser].filter(
          (entry) => {
            return entry.date !== date;
          }
        );
        availabilityData[this.state.currentUser] = userData;
        this.setState({
          availability: availabilityData,
        });
      }
    } else {
      let availabilityData = this.state.availability;
      this.state.availability.forEach(async (elements, user_id) => {
        if (this.state.availability[user_id]) {
          let userData = this.state.availability[user_id].filter((entry) => {
            return entry.date !== date;
          });
          availabilityData[user_id] = userData;
        }
      });

      this.setState({
        availability: availabilityData,
      });
    }
  };

  addDateInAvailability = async (date) => {
    let rndIncrement = 17;
    const week = !isWeekStartWithMonday()
      ? moment(date).clone().add(1, "day").isoWeek()
      : moment(date).clone().isoWeek();

    if (this.state.groupBy !== "project" || this.state.isRepeatDate) {
      rndIncrement += rndIncrement;
      let timerId = setInterval(async () => {
        if (!this.state.checkAvailability && this.state.currentUser) {
          await this.props.actions.getAvailability(
            this.state.currentUser,
            date,
            week
          );
          await this.setState({
            checkAvailability: true,
            currentDate: date,
          });
          clearInterval(timerId);
        }
      }, 1000 + rndIncrement);
    } else {
      this.state.availability.forEach(async (elements, user_id) => {
        rndIncrement += rndIncrement;
        // add delay in each new request to manage the user entries
        let timerId = setInterval(async () => {
          if (!this.state.checkAvailability && user_id) {
            await this.props.actions.getAvailability(user_id, date, week);
            await this.setState({
              checkAvailability: true,
              currentDate: date,
              currentUser: user_id,
            });
            clearInterval(timerId);
          }
        }, 1000 + rndIncrement);
      });
    }
  };

  setUserInAvailability = async (selectedUsers) => {
    let availability = this.state.availability;
    let newUserFound = false;
    if (selectedUsers) {
      for (let i = 0; i < selectedUsers.length; i++) {
        // get availability for newly added user
        if (selectedUsers[i].value && !availability[selectedUsers[i].value]) {
          newUserFound = true;
          const userId = selectedUsers[i].value;
          const week = !isWeekStartWithMonday()
            ? moment(this.state.currentDate).clone().add(1, "day").isoWeek()
            : moment(this.state.currentDate).clone().isoWeek();
          const day = this.state.currentDate;
          await this.props.actions.getAvailability(userId, day, week);
          await this.setState({
            checkAvailability: true,
            currentUser: userId,
          });

          // if new user selected after selecting the repeat date, add all the repeat data values for availibility for this user
          if (this.state.repeat_Dates.length > 0) {
            await this.setState({ isRepeatDate: true });
            this.state.repeat_Dates.forEach(async (newDate) => {
              await this.addDateInAvailability(newDate);
            });
            await this.setState({ isRepeatDate: false });
          }
        }
      }

      if (!newUserFound) {
        // means, a user removed from the selected list
        let newArray = [];
        availability.forEach((elements, user_id) => {
          for (let i = 0; i < selectedUsers.length; i++) {
            if (parseInt(selectedUsers[i].value, 10) === user_id) {
              newArray[user_id] = availability[user_id];
              break;
            }
          }
        });

        this.setState({
          availability: newArray,
        });
      }
    } else {
      this.setState({
        availability: [],
      });
    }
  };

  getData = (selectedDate) => {
    let data = {};

    if (this.state.isMultipleProjectInput) {
      data = {
        status: this.state.planStatus.value,
        lunch: this.state.promptLunch ? true : false,
        autofill_as_hours: this.state.autofillAsHours ? true : false,
      };

      let multipleProjectNodes = this.state.multipleProjectNodes;

      // check if form is filled by the project inputs (form without Add More action), if yes, add the job to the Project Nodes
      if (
        this.state.selectedTask &&
        (this.state.startTime !== "00:00" || this.state.endTime !== "00:00")
      ) {
        multipleProjectNodes.push({
          task_id: this.state.selectedTask
            ? this.state.selectedTask.value
            : null,
          project_id: this.state.selectedProject,
          start_time: this.state.startTime,
          end_time: this.state.endTime,
          user_ids: this.state.selectedUserOption,
          comment: this.state.comment,
        });

        // prevent adding same project in case of repeat date
        this.setState({
          selectedTask: null,
        });
      }

      const tasks = [];
      for (let i = 0; i < multipleProjectNodes.length; i++) {
        const currentNode = multipleProjectNodes[i];

        let user_ids_node = [];
        if (currentNode.user_ids) {
          if (
            !Array.isArray(currentNode.user_ids) &&
            currentNode.user_ids.value
          ) {
            user_ids_node.push(currentNode.user_ids.value);
          } else {
            if (currentNode.user_ids.length) {
              for (let i = 0; i < currentNode.user_ids.length; i++) {
                user_ids_node.push(currentNode.user_ids[i].value);
              }
            }
          }
        }

        const taskNode = {
          id: currentNode.task_id,
          project_id: currentNode.project_id,
          user_ids: user_ids_node.join(),
          comment: currentNode.comment ? currentNode.comment : "",
        };

        const end = currentNode.end_time.split(":");
        const endTimeHour = end[0];
        const endTimeMin = end[1];

        if (currentNode.end_time < currentNode.start_time) {
          let newSelectedDate = moment(selectedDate).add(1, "days");
          taskNode["end_time"] =
            newSelectedDate
              .set({
                hour: endTimeHour,
                minute: endTimeMin,
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        } else {
          taskNode["end_time"] =
            selectedDate
              .set({
                hour: endTimeHour,
                minute: endTimeMin,
              })
              .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        }

        const start = currentNode.start_time.split(":");
        const startTimeHour = start[0];
        const startTimeMin = start[1];
        taskNode["start_time"] =
          selectedDate
            .clone()
            .set({
              hour: startTimeHour,
              minute: startTimeMin,
            })
            .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

        tasks.push(taskNode);
      }

      data["tasks"] = tasks;
    } else {
      let user_ids = [];

      // User id is optional, if not assigned, consider it as Unassigned plan
      if (this.state.selectedUserOption) {
        if (
          !Array.isArray(this.state.selectedUserOption) &&
          this.state.selectedUserOption.value
        ) {
          user_ids.push(this.state.selectedUserOption.value);
        } else {
          if (this.state.selectedUserOption.length) {
            for (let i = 0; i < this.state.selectedUserOption.length; i++) {
              user_ids.push(this.state.selectedUserOption[i].value);
            }
          }
        }
      }

      data = {
        task_id: this.state.selectedTask ? this.state.selectedTask.value : null,
        project_id: this.state.selectedProject,
        user_ids: user_ids.join(),
        comment: this.state.comment ? this.state.comment : "",
        status: this.state.planStatus.value,
        lunch: this.state.promptLunch ? true : false,
        autofill_as_hours: this.state.autofillAsHours ? true : false,
      };

      const end = this.state.endTime.split(":");
      const endTimeHour = end[0];
      const endTimeMin = end[1];

      if (this.state.endTime < this.state.startTime) {
        let newSelectedDate = moment(selectedDate).add(1, "days");
        data["end_time"] =
          newSelectedDate
            .set({
              hour: endTimeHour,
              minute: endTimeMin,
            })
            .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      } else {
        data["end_time"] =
          selectedDate
            .set({
              hour: endTimeHour,
              minute: endTimeMin,
            })
            .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      }

      const start = this.state.startTime.split(":");
      const startTimeHour = start[0];
      const startTimeMin = start[1];
      data["start_time"] =
        selectedDate
          .clone()
          .set({
            hour: startTimeHour,
            minute: startTimeMin,
          })
          .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    }

    return data;
  };

  handleFormSubmit = async () => {
    const startDate = moment(this.props.startDate).clone();
    const selectedDate = startDate.clone().add(this.state.dayNumber, "days");

    const data = this.getData(selectedDate);
    if (this.state.editId) {
      await this.editPlan(this.state.editId, data);
      this.setState({ editId: null });
    } else {
      await this.createPlan(data);
    }

    for (let i = 0; i < this.state.repeat_Dates.length; i++) {
      const selectedDate = moment(this.state.repeat_Dates[i]);
      const data = this.getData(selectedDate);
      await this.createPlan(data);
    }

    // due to repeate-dates, getPlans call need to fire once, with approx time intervel
    let interval = 1000; //sec
    if (this.state.repeat_Dates.length) {
      interval = interval + this.state.repeat_Dates.length * 1000;
    }
    setTimeout(() => {
      this.getPlans();
    }, interval);

    this.handleCancel();
  };

  createPlan = async (data) => {
    if (this.state.isMultipleProjectInput) {
      await this.props.actions.createPlanMultipleProject(data);
      this.setState({ checkMultiProjectInputError: true });
    } else {
      await this.props.actions.createPlan(data);
      this.setState({ checkError: true });
    }

    //await this.resetFilter();
  };

  editPlan = async (id, data) => {
    if (this.state.isMultipleProjectInput) {
      const editObj = {
        user_ids: data.tasks[0].user_ids,
        comment: data.tasks[0].comment,
        status: data.status,
        lunch: data.lunch,
        task_id: data.tasks[0].id,
        project_id: data.tasks[0].project_id,
        start_time: data.tasks[0].start_time,
        end_time: data.tasks[0].end_time,
      };
      await this.props.actions.editPlan(id, editObj);
      this.setState({ checkError: true });

      if (data.tasks.length > 1) {
        const postObj = {
          status: data.status,
          lunch: data.lunch,
          tasks: data.tasks.filter((task, index) => index !== 0),
        };
        await this.props.actions.createPlanMultipleProject(postObj);
        this.setState({ checkMultiProjectInputError: true });
      }
    } else {
      await this.props.actions.editPlan(id, data);
      await this.setState({ checkError: true });
    }
  };

  searchUser = () => {
    //this.setState({ isUserSearch: true }); // not used
    this.setState({ offset: 0 });
    this.getCompanyUsers();
  };

  searchProject = () => {
    //this.setState({ isProjectSearch: true }); // not used
    this.setState({ offset: 0 });
    this.getProjectList();
  };

  resetFilter = () => {
    //this.props.actions.filterData("planning", null);
    this.setState(
      {
        selectedProjectFilterOption: null,
        selectedDepartmentOption: null,
        selectedUserFilterOption: null,
        fromDate: null,
        toDate: null,
        filterCounter: 0,
        isDepartmentFilter: false,
        preventUserCall: false,
      },
      () => {
        this.props.actions.filterData("planning", null);
      }
    );
  };

  changeState = (data) => {
    for (let key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        this.setState({ [key]: data[key] });
      }
    }
  };

  getCompanyUsers = async () => {
    let queryString = `more_details=false&limit=${this.state.limit}&offset=${this.state.offset}&for_planned_hours=true`;

    if (
      this.props.commonReducer.filters.planning &&
      this.props.commonReducer.filters.planning.fromDate &&
      this.props.commonReducer.filters.planning.fromDate !== "" &&
      this.props.commonReducer.filters.planning.fromDate !== undefined &&
      this.props.commonReducer.filters.planning.toDate &&
      this.props.commonReducer.filters.planning.toDate !== "" &&
      this.props.commonReducer.filters.planning.toDate !== undefined
    ) {
      queryString += `&start_time=${moment(
        this.props.commonReducer.filters.planning.fromDate
      ).format("YYYY-MM-DD")}&end_time=${moment(
        this.props.commonReducer.filters.planning.toDate
      ).format("YYYY-MM-DD")}`;
    } else {
      queryString += `&start_time=${moment(this.props.startDate).format(
        "YYYY-MM-DD"
      )}&end_time=${moment(this.props.endDate).format("YYYY-MM-DD")}`;
    }

    if (
      this.state.isDepartmentFilter &&
      this.props.commonReducer.filters.planning &&
      this.props.commonReducer.filters.planning.departmentIds &&
      this.props.commonReducer.filters.planning.departmentIds !== undefined
    ) {
      let departmentIds =
        this.props.commonReducer.filters.planning.departmentIds;

      if (Array.isArray(departmentIds) && departmentIds.length > 0) {
        queryString += `&department_id=${departmentIds.join(",")}`;
      }
    }

    if (this.state.searchVal && this.state.searchVal !== "") {
      queryString += `&name=${this.state.searchVal}`;
    }

    await this.props.actions.getCompanyUsers(queryString);
  };

  getProjectList = async () => {
    let queryString = `more_details=false&limit=${this.state.limit}&offset=${this.state.offset}&for_planned_hours=true&`;

    if (
      this.props.commonReducer.filters.planning &&
      this.props.commonReducer.filters.planning.fromDate &&
      this.props.commonReducer.filters.planning.fromDate !== "" &&
      this.props.commonReducer.filters.planning.fromDate !== undefined &&
      this.props.commonReducer.filters.planning.toDate &&
      this.props.commonReducer.filters.planning.toDate !== "" &&
      this.props.commonReducer.filters.planning.toDate !== undefined
    ) {
      queryString += `start_time=${moment(
        this.props.commonReducer.filters.planning.fromDate
      ).format("YYYY-MM-DD")}&end_time=${moment(
        this.props.commonReducer.filters.planning.toDate
      ).format("YYYY-MM-DD")}`;
    } else {
      queryString += `start_time=${moment(this.props.startDate).format(
        "YYYY-MM-DD"
      )}&end_time=${moment(this.props.endDate).format("YYYY-MM-DD")}`;
    }

    if (this.state.searchVal && this.state.searchVal !== "") {
      queryString += `&name=${this.state.searchVal}`;
    }

    await this.props.actions.getProjects(queryString);
  };

  loadUserList = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    let queryString = `more_details=false&limit=${this.state.limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    this.setState(
      {
        userDropdownCall: true,
        isLoadUserList: false,
      },
      async () => {
        await this.props.actions.getCompanyUsers(queryString);
      }
    );

    const result = await this.getUserListOptions();
    return result;
  };

  getUserListOptions = () => {
    const promise = new Promise((resolve) => {
      let counter = 0;
      const userListTimer = setInterval(async () => {
        counter++;
        if (counter > 9) {
          this.setState({
            isLoadUserList: true,
          });
        }

        if (this.state.isLoadUserList) {
          clearInterval(userListTimer);

          if (
            this.props.planningReducer &&
            this.props.planningReducer.userList &&
            this.props.planningReducer.userList.entries
          ) {
            let data = [];
            for (
              let i = 0;
              i < this.props.planningReducer.userList.entries.length;
              i++
            ) {
              const element = this.props.planningReducer.userList.entries[i];
              data.push({
                value: element.id.toString(),
                label: `${element.name}${
                  element.position ? ` (${element.position})` : ""
                }`,
              });
            }

            resolve({
              options: data,
              hasMore:
                this.props.planningReducer.userList.pagination.has_more_items,
            });
          } else {
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 600);
    });

    return promise;
  };

  loadProjectList = async (searchVal, options) => {
    let offset = options && options.length ? options.length : 0;
    let queryString = `more_details=false&open=true&limit=${this.state.limit}&offset=${offset}`;

    if (searchVal && searchVal !== "") {
      queryString += `&name=${searchVal}`;
    }

    this.setState({ isLoadProjectResult: false, projectDropdownCall: true });
    await this.props.actions.getProjects(queryString);
    const result = await this.getProjectListOptions();
    return result;
  };

  getProjectListOptions = () => {
    const promise = new Promise((resolve) => {
      let counter = 0;
      const projTimer = setInterval(async () => {
        counter++;
        if (counter > 10) {
          this.setState({
            isLoadProjectResult: true,
          });
        }

        if (this.state.isLoadProjectResult) {
          clearInterval(projTimer);

          if (
            this.props.projectReducer.projectList &&
            this.props.projectReducer.projectList.entries
          ) {
            let data = [];
            for (
              let i = 0;
              i < this.props.projectReducer.projectList.entries.length;
              i++
            ) {
              const element = this.props.projectReducer.projectList.entries[i];
              data.push({
                value: element.id.toString(),
                label: element.name,
                description: element.description
                  ? element.description
                  : i18next.t("Not available"),
              });
            }

            resolve({
              options: data,
              hasMore:
                this.props.projectReducer.projectList.pagination.has_more_items,
            });
          } else {
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 500);
    });

    return promise;
  };

  getTasks = (newLimit, newOffset, newSearch) => {
    if (this.state.selectedProject) {
      this.props.actions.getTasks({
        project_id: this.state.selectedProject,
        offset: newOffset,
        limit: newLimit,
        search: newSearch.length > 0 ? newSearch : "",
        finish: "false",
        hour_summary: false,
      });
    } else {
      this.setState({
        checkTaskList: false,
        checkTaskOptions: true,
      });
    }
  };

  getTaskOptions = (limit, offset, search) => {
    const promise = new Promise((resolve) => {
      this.getTasks(limit, offset, search);
      this.setState({
        checkTaskList: true,
      });
      let counter = 0;

      const timerId = setInterval(() => {
        counter++;
        if (counter > 10) {
          this.setState({
            checkTaskList: false,
            checkTaskOptions: true,
          });
        }

        if (this.state.checkTaskOptions) {
          this.setState({
            checkTaskOptions: false,
          });

          if (
            this.state.selectedProject &&
            this.props.taskReducer.taskList[this.state.selectedProject] &&
            this.props.taskReducer.taskList[this.state.selectedProject].entries
          ) {
            let entries =
              this.props.taskReducer.taskList[this.state.selectedProject]
                .entries;

            if (this.state.selectedTask && this.state.selectedTask.__isNew__) {
              let newSelectedOption = entries.find((entry) => {
                return entry.name === this.state.selectedTask.label;
              });

              if (
                newSelectedOption &&
                newSelectedOption.id &&
                newSelectedOption.name
              ) {
                this.setState({
                  selectedTask: {
                    value: newSelectedOption.id.toString(),
                    label: newSelectedOption.name,
                  },
                });
              }
            }

            let data = [];
            for (let i = 0; i < entries.length; i++) {
              const element = entries[i];
              data.push({
                value: element.id.toString(),
                label: element.name,
              });
            }

            clearInterval(timerId);
            resolve({
              options: data,
              hasMore:
                this.props.taskReducer.taskList[this.state.selectedProject]
                  .pagination.has_more_items,
            });
          } else {
            clearInterval(timerId);
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 750);
    });

    return promise;
  };

  addTask = async (name) => {
    const data = {
      name: name,
      project_id: this.state.selectedProject,
    };
    await this.props.actions.addTask(data);
    await this.setState({ checkError: true });
  };

  addProject = async (name, customer_id) => {
    var data = {
      name: name,
      customer_id: parseInt(customer_id, 10),
    };
    await this.props.actions.addProject(data);
    await this.getProjectList();
  };

  addCustomer = async (name) => {
    var customerData = {
      name: name,
      company_id: getItem("companyId"),
    };
    await this.props.actions.addCutomers(customerData);
    //await this.props.actions.getCutomers();
  };

  getCustomers = async (newLimit, newOffset) => {
    await this.props.actions.getCutomers({
      offset: newOffset,
      limit: newLimit,
    });
  };

  getCustomersListOptions = (limit, offset) => {
    const promise = new Promise((resolve) => {
      this.getCustomers(limit, offset);
      this.setState({
        checkCustomerList: true,
      });
      let counter = 0;

      const timerId = setInterval(() => {
        counter++;
        if (counter > 10) {
          this.setState({
            checkCustomerList: false,
            checkCustomerOptions: true,
          });
        }

        if (this.state.checkCustomerOptions) {
          this.setState({
            checkCustomerOptions: false,
          });

          clearInterval(timerId);

          if (
            this.props.customerReducer.customerList &&
            this.props.customerReducer.customerList.entries
          ) {
            let data = [];
            for (
              let i = 0;
              i < this.props.customerReducer.customerList.entries.length;
              i++
            ) {
              const element =
                this.props.customerReducer.customerList.entries[i];
              data.push({
                value: element.id.toString(),
                label: element.name,
                company_id: element.company_id,
              });
            }

            resolve({
              options: data,
              hasMore:
                this.props.customerReducer.customerList.pagination
                  .has_more_items,
            });
          } else {
            resolve({
              options: [],
              hasMore: false,
            });
          }
        }
      }, 500);
    });

    return promise;
  };

  onDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) {
      // not dropped properlly
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // if dropped at the dame place
      return;
    } else {
      let sourceNodeId = source.droppableId.split("-")[0]; // user id/project id
      let plan = null; // assign source plan
      let sourceIndex = null; // index of source plan in the array
      if (sourceNodeId !== "UN") {
        for (let node in this.state.planList) {
          if (node === sourceNodeId) {
            for (let i = 0; i < this.state.planList[node].length; i++) {
              if (this.state.planList[node][i].id === source.index) {
                plan = this.state.planList[node][i];
                sourceIndex = i;
                break;
              }
            }
            break;
          }
        }
      } else {
        for (let i = 0; i < this.state.planList[""].length; i++) {
          if (this.state.planList[""][i].id === source.index) {
            plan = this.state.planList[""][i];
            sourceIndex = i;
            break;
          }
        }
      }

      let destinationDay = destination.droppableId.split("-")[1]; // dropped into this day
      let destinationNodeId = destination.droppableId.split("-")[0]; // dropped user id/project id

      const tempStartData = moment(this.props.startDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      // get the destination day date
      const selectedDate = moment(tempStartData)
        .clone()
        .add(parseInt(destinationDay, 10) + 1, "days")
        .utc();

      // get hours and minutes from the plan amount, to prepare the end date
      let hrs, minuts;
      let parts = moment
        .duration(parseFloat(plan.amount), "hours")
        .format("h mm")
        .split(" ");
      if (parts.length > 1) {
        hrs = parts[0];
        minuts = parts[1];
      } else if (parts.length === 1) {
        hrs = 0;
        minuts = parts[0];
      }

      // get hour/minutes of the plan start time
      let start_time_hours = moment(plan.start_time)
        .utc()
        .format("H mm")
        .split(" ");

      // prepare the start_time as per the dropped day
      let startDateTime = moment(selectedDate)
        .clone()
        //.utcOffset(0)
        .set({
          hour: start_time_hours[0],
          minute: start_time_hours[1],
          second: 0,
          millisecond: 0,
        })
        .toISOString();

      // prepare the end_time by adding amount in to new start_time
      let endDateTime = moment(startDateTime)
        .add(hrs, "h")
        .add(minuts, "m")
        .toISOString();

      // prepare object for the edit API request
      let data = {
        start_time: startDateTime,
        end_time: endDateTime,
        task_id: plan.task_id,
        status: plan.status,
        comment: plan.comment,
        lunch: plan.lunch,
      };
      if (this.state.groupBy === "user") {
        data["user_ids"] = destinationNodeId === "UN" ? "" : destinationNodeId;
        data["project_id"] = plan.project_id;
      } else {
        data["project_id"] = destinationNodeId;
        data["user_ids"] = plan.user_id ? plan.user_id.toString() : ""; // user_ids parameter is mandatory in the PUT call
      }
      await this.editPlan(source.index, data);

      /* change the current planList array to reflect the drag & drop effect (without flicker) */

      // remove the plan from the source
      let oldPlanList = this.state.planList;
      if (sourceNodeId === "UN") {
        oldPlanList[""].splice(sourceIndex, 1);
      } else {
        oldPlanList[sourceNodeId].splice(sourceIndex, 1);
      }

      // if destination array is empty (no plans yet), then create the node (user/project id)
      if (destinationNodeId === "UN") {
        if (!oldPlanList[""]) {
          oldPlanList[""] = [];
        }
      } else {
        if (!oldPlanList[destinationNodeId]) {
          oldPlanList[destinationNodeId] = [];
        }
      }

      // update the new start_time and end_time in the selected source plan
      plan.start_time = startDateTime;
      plan.end_time = endDateTime;
      plan.start_date_str = moment(startDateTime).utc().format("DD,MM,YY");

      // add the updated plan into destination array
      if (destinationNodeId === "UN") {
        oldPlanList[""].push(plan);
      } else {
        oldPlanList[destinationNodeId].push(plan);
      }

      // update the planList array
      await this.setState({ planList: oldPlanList });

      // refresh the plans
      await this.getPlans();
    }
  };

  checkInStatus = async (planData) => {
    const hourId = planData.id;
    if (hourId && this.state.checkinPlan) {
      if (
        this.state.checkinPlan === hourId &&
        this.state.checkinStep &&
        this.state.checkinStep === "checkin"
      ) {
        if (this.state.isMultiProject) {
          setItem("planCheckInData", JSON.stringify(this.state.checkInData));
          this.props.history.push("/hours?checkout=1");
        }
      }
    } else {
      if (hourId && !this.state.checkinPlan) {
        const currentTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");

        const postObj = {
          date: moment().format("YYYY-MM-DD"),
          start_time: currentTime + "Z",
          //user_id: parseInt(getItem("userId"), 10), // not required in backend now
          task_id: planData.task_id,
          planning_planned_hour_id: hourId,
        };

        // geo location
        if (this.state.isGeoLockRequired && this.props.coords) {
          if (this.state.isGeoFenceDisabled) {
            postObj["tablet"] = true;
          } else {
            postObj["latitude"] = this.props.coords.latitude;
            postObj["longitude"] = this.props.coords.longitude;
          }
        }

        this.props.actions.createCheckInHour(postObj);

        this.setState({
          checkinStep: "checkin",
          checkinPlan: hourId,
          checkInData: {
            start_time: currentTime + "Z",
            planning_planned_hour_id: hourId,
            project_name: planData.project_name,
            task_name: planData.task_name,
          },
          checkCheckInError: true,
        });

        // log user attendace
        if (
          this.state.attendaceModuleAssigned &&
          this.state.reportWebAttendance
        ) {
          const attendanceTime = moment()
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS");
          const attendaceObj = {
            start_time: attendanceTime + "Z",
            attendanceable_id: parseInt(getItem("userId"), 10),
            attendanceable_type: "User",
            is_utc: true,
          };

          // pass task_id if present
          if (planData.task_id) {
            attendaceObj["task_id"] = planData.task_id;
          }

          // geo location
          if (this.state.isGeoFenceForAttendace && this.props.coords) {
            if (this.state.isGeoFenceDisabled) {
              attendaceObj["tablet"] = true;
            } else {
              attendaceObj["latitude"] = this.props.coords.latitude;
              attendaceObj["longitude"] = this.props.coords.longitude;
            }
          }

          this.props.actions.postUserAttendance(attendaceObj);
          this.setState({
            checkAttendanceError: true,
          });
        }
      }
    }
  };

  requestAssignPlan = async (planId) => {
    await this.props.actions.assignPlan(planId);
    this.setState({
      checkAssignStatus: true,
    });
  };

  handleDeleteSubmit = async () => {
    await this.props.actions.deletePlan(this.state.editId);
    await this.getPlans();
    await this.setState({
      editId: null,
      openDeleteModal: false,
      openPlanModal: false,
      checkError: true,
    });
  };

  handleDeleteCancel = () => {
    this.setState({ openDeleteModal: false });
  };

  handlePlanModalDelete = () => {
    this.setState({ openDeleteModal: true });
  };

  markTaskStatus = (status, task_id) => {
    const putObj = { finish: status };
    this.props.actions.putTaskData(task_id, putObj);
  };

  handleAlertClose = () => {
    this.setState({ openAlertModal: false });
  };

  loadPlans = (newOffset) => {
    if (this.state.groupBy === "user") {
      this.setState({ offset: newOffset }, () => {
        this.getCompanyUsers();
      });
    } else {
      this.setState({ offset: newOffset }, () => {
        this.getProjectList();
      });
    }
  };

  handlePrevClick = () => {
    const newOffset = this.state.offset - this.state.limit;
    this.setState({ offset: newOffset }, () => {
      this.getCompanyUsers();
    });
  };

  render() {
    if (!this.state.havePermission || !this.state.showAdminPlanningView) {
      return <Redirect to="/" />;
    } else {
      return !authActions.getAccessToken() ? (
        <Redirect to={`/token/${authActions.getCompanyToken()}`} />
      ) : (
        <React.Fragment>
          <HoursFilters
            state={this.state}
            changeState={this.changeState}
            loadProjectList={this.loadProjectList}
            loadUserList={this.loadUserList}
            resetDefaultDates={this.resetDefaultDates}
            {...this.props}
          />
          <Table
            state={this.state}
            formModalOpen={this.formModalOpen}
            searchUser={this.searchUser}
            searchProject={this.searchProject}
            getPlans={this.getPlans}
            onDragEnd={this.onDragEnd}
            checkInStatus={this.checkInStatus}
            requestAssignPlan={this.requestAssignPlan}
            changeState={this.changeState}
            loadPlans={this.loadPlans}
            handlePrevClick={this.handlePrevClick}
            {...this.props}
          />
          <FormPlanModal
            state={this.state}
            handleCancel={this.handleCancel}
            onSubmit={this.handleFormSubmit}
            handleDelete={this.handlePlanModalDelete}
            changeState={this.changeState}
            getTasks={this.getTasks}
            addTask={this.addTask}
            getTaskOptions={this.getTaskOptions}
            addDateInAvailability={this.addDateInAvailability}
            removeDateFromAvailability={this.removeDateFromAvailability}
            setUserInAvailability={this.setUserInAvailability}
            loadProjectList={this.loadProjectList}
            loadUserList={this.loadUserList}
            {...this.props}
          />
          <DeleteModal
            isOpen={this.state.openDeleteModal}
            handleCancel={this.handleDeleteCancel}
            handleSubmit={this.handleDeleteSubmit}
            reducer={this.props.planningReducer}
          />
          {!this.props.isGeolocationAvailable &&
          (this.state.isGeoLockRequired || this.state.isGeoFenceForAttendace) &&
          !this.state.isGeoFenceDisabled ? (
            <AlertModal
              isOpen={this.state.openAlertModal}
              handleClose={this.handleAlertClose}
              title={"Geo Location!"}
              message={
                "Your browser does not support Geolocation. Please use another browser. The Geolocation feature is required while creating new Hours."
              }
            />
          ) : !this.props.isGeolocationEnabled &&
            (this.state.isGeoLockRequired ||
              this.state.isGeoFenceForAttendace) &&
            !this.state.isGeoFenceDisabled ? (
            <AlertModal
              isOpen={this.state.openAlertModal}
              handleClose={this.handleAlertClose}
              title={"Geo Location!"}
              message={
                "Please allow the app to determine your location. This is required while creating new Hours."
              }
            />
          ) : null}
          <OverlayIndicator
            isLoading={
              this.props.planningReducer.planLoading ||
              this.props.projectReducer.isLoading ||
              (this.state.groupBy === "user" &&
                this.props.planningReducer.userLoading &&
                !this.state.userDropdownCall)
            }
          />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    projectReducer: state.projectReducer,
    customerReducer: state.customer,
    taskReducer: state.task,
    departmentReducer: state.departmentReducer,
    commonReducer: state.commonReducer,
    planningReducer: state.planningReducer,
    profileReducer: state.profile,
    filters: state.filters,
    startDate: state.filters.startDate,
    endDate: state.filters.endDate,
    companyReducer: state.companyReducer,
    hourReducer: state.hourReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch),
  };
};

const geoLockSettings = {
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(geolocated(geoLockSettings)(Planning)));
